import React from 'react';
import styles from './commonComponents.module.scss';

import { useFormContext } from 'react-hook-form';

import FormSearchInput from 'components/FormComponents/FormSearchInput/FormSearchInput';
import FormDatePicker from 'components/FormComponents/FormDatePicker/FormDatePicker';

import { useReportSelector } from 'hooks/ReportList';

export default function AssignmentDetails() {
  const { setValue } = useFormContext();
  const { usersForAssignList } = useReportSelector();

  const handleSelect = (name, value) => setValue(name, value);

  return (
    <div className={styles.assignee}>
      <div className={styles.assignee_field}>
        <span>Assignee</span>
        <FormSearchInput
          name="assignedToUser"
          options={usersForAssignList || []}
          onSelect={handleSelect}
          classes={styles.assignee_field_classes}
          clearable
        />
      </div>
      <div className={styles.assignee_field}>
        <span>Due Date</span>
        <FormDatePicker name="assignedAtUtc" placement="top" />
      </div>
    </div>
  );
}
