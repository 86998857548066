import { createSelector } from 'reselect';

const teamMembersData = (state) => state.teamMembersData;

export const selectTeamMembersData = () =>
  createSelector([teamMembersData], (state) => state.teamMemberList);

export const selectCurrentTeamMemberData = () =>
  createSelector([teamMembersData], (state) => state.currentTeamMember);

export const selectCreationFormData = () =>
  createSelector([teamMembersData], (state) => state.creationFormData);

export const selectTeamMemberFilter = () =>
  createSelector([teamMembersData], (state) => state.teamMemberFilter);
