import React, { useEffect } from 'react';
import styles from './Wrapper.module.scss';

import { useTicketActions, useTicketSelector } from 'hooks/Ticket';
import { useParams } from 'react-router-dom';

export default function TicketDetailsWrapper({ children }) {
  const { id } = useParams();

  const { currentTicket } = useTicketSelector();
  const { getTicketAction, clearStateAction } = useTicketActions();

  useEffect(() => {
    if (id) {
      getTicketAction(id);
    }
  }, [id]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  return (
    <section className={styles.wrapper}>{currentTicket.id === +id && <>{children}</>}</section>
  );
}
