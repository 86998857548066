import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  getShortedReportTemplates,
  getReportTemplate,
  setActiveTemplate,
  getLastSavedGeneralReport
} from 'actions/reportListActions';
import { selectShortedReportTemplatesListData, selectSingleReportTemplate } from '../../selectors';
import { Dialog, DialogActions, DialogTitle, DialogContent, FormControl } from '@material-ui/core';
import SelectInput from 'components/SelectInput/SelectInput';
import { MainButton } from 'components/StyledComponents/';
import styles from './AddGeneralReportPopup.module.scss';
import { GENERAL_REPORT_EDIT_PATH } from 'constants/routeConstants';
import BackButton from 'components/BackButton/BackButton';

function AddGeneralReportPopup({
  isMobile,
  modalOpen,
  setModalOpen,
  reportTemplatesList,
  reportTemplateData,
  getShortedReportTemplatesAction,
  getGeneralTemplateAction,
  setActiveGeneralTemplateAction,
  getLastSavedReportAction
}) {
  const history = useHistory();
  const [values, setValues] = useState({ templateReport: {} });

  useEffect(() => {
    if (!reportTemplatesList.length) {
      getShortedReportTemplatesAction({ filters: { generalReportTypeName: ['General'] } });
    }
  }, []);

  useEffect(() => {
    if (reportTemplateData?.id) {
      setValues({ templateReport: reportTemplateData });
    }
  }, [reportTemplateData]);

  const onSelect = (selectedItem) => {
    getGeneralTemplateAction(selectedItem.value.id);
  };

  const onAgree = () => {
    const query = { generalReportTemplateId: values.templateReport.id };

    getLastSavedReportAction(query).then((res) => {
      setActiveGeneralTemplateAction({
        ...values,
        templateReport: res.generalReportTemplate
      });
      history.push(GENERAL_REPORT_EDIT_PATH);
    });
  };

  const onDismiss = () => {
    setValues({ templateReport: {} });
    setModalOpen(false);
  };

  return (
    <>
      <Dialog
        open={modalOpen}
        classes={{
          paper: styles.popupContainer
        }}
        BackdropProps={{
          classes: {
            root: styles.backDrop
          }
        }}
        disableBackdropClick
        keepMounted
        onClose={() => setModalOpen(false)}>
        <DialogTitle
          classes={{
            root: styles.popupTitle
          }}>
          <div className={styles.headerTitle}>
            <h2>Create report</h2>
            {isMobile && <BackButton onCancel={onDismiss} />}
          </div>
        </DialogTitle>

        <DialogContent
          classes={{
            root: styles.popupText
          }}>
          <FormControl classes={{ root: styles.formControl }}>
            <section className={styles.formCell}>
              <label>Report name*</label>
              <div className={styles.inputWrapper}>
                <SelectInput
                  name="templateReport"
                  value={values.templateReport?.id || ''}
                  menuItems={reportTemplatesList || []}
                  onSelect={onSelect}
                />
              </div>
            </section>
          </FormControl>
        </DialogContent>

        <DialogActions
          classes={{
            root: styles.popupControls
          }}>
          <section className={styles.buttonsBlock}>
            <MainButton text="Cancel" action={onDismiss} type="secondary" size="popup" />
            <MainButton
              text="Create"
              action={onAgree}
              type="primary"
              size="popup"
              isDisabled={!values.templateReport?.id}
            />
          </section>
        </DialogActions>
      </Dialog>
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  reportTemplatesList: selectShortedReportTemplatesListData(),
  reportTemplateData: selectSingleReportTemplate()
});

const mapDispatchToProps = {
  getShortedReportTemplatesAction: getShortedReportTemplates,
  getGeneralTemplateAction: getReportTemplate,
  setActiveGeneralTemplateAction: setActiveTemplate,
  getLastSavedReportAction: getLastSavedGeneralReport
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(AddGeneralReportPopup);
