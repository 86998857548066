import api from '../api';
import { queryToString } from 'helpers/AppHelpers';

export const getLocationsApi = (query) =>
  api.get(`/Locations/Shorted?${queryToString(query)}`).then((res) => res.data);

export const getProjectsApi = (query) =>
  api.get(`/ShortedProjects?${queryToString(query)}`).then((res) => res.data);

export const getRentalStatusesApi = (query) =>
  api.get(`/AssetRentalStatuses?${queryToString(query)}`).then((res) => res.data);

export const getSeveritiesApi = (query) =>
  api.get(`/TicketSeverities?${queryToString(query)}`).then((res) => res.data);

export const getSublocationsApi = (id) =>
  api.get(`/Locations/Sublocations?locationId=${id}`).then((res) => res.data);

export const getAssetFieldsApi = (query) =>
  api.get(`/AssetFields?${queryToString(query)}`).then((res) => res.data);

export const getTicketTypesApi = (query) =>
  api.get(`/ServiceTickets/TicketTypes?${queryToString(query)}`).then((res) => res.data);

export const getRootCausesApi = (query) =>
  api.get(`/ServiceTickets/RootCauses?${queryToString(query)}`).then((res) => res.data);

export const createTicketsApi = (data) =>
  api.post(`/Assets/ServiceTickets`, data).then((res) => res);

export const assignAssetsToLocationApi = (data) =>
  api.put(`/Assets/CurrentLocationHistory`, data).then((res) => res);

export const updateAssetsStatusApi = (data) =>
  api.put(`/AssetRentalStatuses`, data).then((res) => res);

export const updateFieldsApi = (data) => api.put(`/Assets/AssetFields`, data).then((res) => res);
