import api from '../api';
import { browserHistory } from '../browserHistory';
import {
  SET_LOOSE_ITEM_DASHBOARD_TABLES_DATA,
  SET_LOOSE_ITEM_DASHBOARD_CONFIG,
  CLEAR_LOOSE_ITEM_DASHBOARD_STATE,
  SET_MOVED_LOOSE_ITEMS_DATA,
  CLEAR_MOVED_LOOSE_ITEMS_STATE
} from 'constants/reduceConstants';
import { LOOSE_ITEMS_LIST_PATH, MOVED_LOOSE_ITEMS_PATH } from '../constants/routeConstants';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from '../helpers/AppHelpers';
import { ERROR_MOVED_LOOSE_ITEMS, SUCCESS_MOVED_LOOSE_ITEMS } from '../constants/infoSnackbarData';

export const getLooseItemDashboardConfig = () => (dispatch) => {
  return api.looseItemDashboard
    .getLooseItemDashboardConfigApi()
    .then((res) => {
      dispatch({ type: SET_LOOSE_ITEM_DASHBOARD_CONFIG, payload: res });
      return res;
    })
    .catch((err) => console.log(err));
};

export const getLooseItemDashboardTablesData = (query) => (dispatch) => {
  return api.looseItemDashboard
    .getLooseItemDashboardTablesDataApi(query)
    .then((res) => {
      dispatch({ type: SET_LOOSE_ITEM_DASHBOARD_TABLES_DATA, payload: res });
      return res;
    })
    .catch((err) => console.log(err));
};

export const getShortedLooseItems = (query) => () =>
  api.looseItemDashboard
    .getShortedLooseItemsApi(query)
    .then((res) => res)
    .catch((err) => console.log(err));

export const updateLooseItemDashboardConfig = (data) => () =>
  api.looseItemDashboard
    .updateLooseItemDashboardConfigApi(data)
    .then((res) => res)
    .catch((err) => console.log(err));

export const moveLooseItems = (data) => (dispatch) =>
  api.looseItemDashboard.moveLooseItemsApi(data).then(
    (res) => {
      dispatch({ type: SET_MOVED_LOOSE_ITEMS_DATA, payload: res.moveLooseItems });
      if (res.moveLooseItems?.length) {
        browserHistory.push(MOVED_LOOSE_ITEMS_PATH);
        return 'success';
      } else {
        enqueueErrorSnackbar(ERROR_MOVED_LOOSE_ITEMS);
        return 'error';
      }
    },
    (err) => console.log(err)
  );

export const moveItems = (data) => () =>
  api.looseItemDashboard.moveItemsApi(data).then(
    (res) => {
      enqueueSuccessSnackbar(SUCCESS_MOVED_LOOSE_ITEMS);
      browserHistory.push(LOOSE_ITEMS_LIST_PATH);
      return res;
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      return errors;
    }
  );

export const getLooseItemsLocations = (query) => () =>
  api.looseItemDashboard
    .getLooseItemsLocationsApi(query)
    .then((res) => res)
    .catch((err) => console.log(err));

export const clearMovedItemsState = () => (dispatch) => {
  dispatch({ type: CLEAR_MOVED_LOOSE_ITEMS_STATE });
};

export const clearLooseItemDashboardData = () => (dispatch) => {
  dispatch({ type: CLEAR_LOOSE_ITEM_DASHBOARD_STATE });
};
