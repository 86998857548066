import React, { useEffect, useRef, useState } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import {
  selectFilterCriteriaData,
  selectFilterData,
  selectSelectedColumnsData,
  selectTableData
} from 'hooks/LooseItems/selectors';
import {
  clearState,
  getFilterCriteria,
  getLooseItemsCounts,
  getTableData,
  setListFilter,
  updateLooseItemPart
} from 'actions/looseItemsAndPartsListActions';
import styles from './LooseItemsList.module.scss';
import SearchTextFieldRefactored from 'components/SearchTextFieldRefactored/SearchTextFieldRefactored';
import { cloneObj, pluck, removeDuplicates } from 'helpers/AppHelpers';
import PageTable from './components/PageTable/PageTable';
import { LOOSE_ITEMS_AND_TENTS_TABLE_PARAMETER } from 'constants/configTableConstants';
import ImportIcon from 'assets/images/importFile.svg';
import ImportFilesPopup from 'components/ImportFilesPopup/ImportFilesPopup';
import AddItemButton from './components/Buttons/AddItemButton';
import SummaryHeader from 'components/SummaryComponents/SummaryHeader/SummaryHeader';
import TablePaginationRefactored from 'components/TablePaginationRefactored/TablePaginationRefactored';
import {
  getChangedByApplyFilter,
  getChangedByLimitFilter,
  getChangedByPageFilter,
  getChangedBySearchFilter,
  getChangedBySortFilter
} from 'helpers/SummaryPagesHelpers';
import { useMobileViewport } from 'hooks/useMobileViewport';
import MoveLooseItemsPopup from 'components/MoveLooseItemsPopup/MoveLooseItemsPopup';
import AddColumnButton from './components/Buttons/AddColumnButton';
import LooseItemsCounts from './components/Counts/LooseItemsCounts';
import DownloadCSVButton from 'components/DownloadCSVButton/DownloadCSVButton';
import { useUserConfig } from 'hooks/useUserConfig';
import { useHistory } from 'react-router-dom';
import { FilterButton, FilterChips, FilterWrapper } from 'components/FilterComponentsV2';
import FilterModal from './components/Filter';
import { generateChips } from './helpers';

function LooseItemsList({
  tableData,
  getTableDataAction,
  getLooseItemsCountsAction,
  filter,
  filterCriteria,
  setFilterAction,
  getFilterCriteriaAction,
  updateLooseItemPartAction,
  clearStateAction
}) {
  const history = useHistory();
  const isMobile = useMobileViewport();
  const { filters, pagination, responseReceived, sortRules } = filter;
  const tableRef = useRef(null);

  const { isConfigReceived, getTableLimit, isBasicRoleUser, isAdminUser, isOperationsManagerUser } =
    useUserConfig();

  const query = new URLSearchParams(window.location.search);
  const searchValue = query.get('searchQuery');

  const [openFilter, setOpenFilter] = useState(false);
  const [chips, setChips] = useState([]);

  const [tableState, setTableState] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);

  const customColumns = useSelector(selectSelectedColumnsData());

  const [counts, setCounts] = useState({});

  const generateInitialQuery = () => {
    const queryFilter = {
      ...filter,
      filters: { ...filter.filters, searchQuery: searchValue || '' },
      pagination: { ...pagination, limit: getTableLimit(LOOSE_ITEMS_AND_TENTS_TABLE_PARAMETER) }
    };
    if (searchValue) {
      query.delete('searchQuery');
      history.replace({ search: query.toString() });
    }
    return queryFilter;
  };

  const isFilterApplied =
    filters.categoryIds?.length ||
    filters.looseItemIds?.length ||
    filters.locationIds?.length ||
    filters.locationJobNumberIds?.length ||
    filters.sublocationIds?.length ||
    filters.statusNames?.length ||
    filters.showWithBaa ||
    filters.showWithReorderTrigger;

  const deleteChip = (chip) => {
    const newFilters = cloneObj(filters);

    switch (chip.key) {
      case 'showWithBaa':
      case 'showWithReorderTrigger':
        newFilters[chip.key] = false;
        break;
      case 'statusNames':
        newFilters[chip.key] = newFilters[chip.key].filter((name) => name !== chip.itemName);
        break;
      case 'locationIds': {
        newFilters[chip.key] = newFilters[chip.key].filter((id) => id !== chip.itemId);

        const sublocations = filterCriteria.locations.find(
          ({ id }) => id === chip.itemId
        )?.sublocations;
        const subIds = pluck(sublocations, 'id');
        if (subIds?.length) {
          newFilters.sublocationIds = newFilters.sublocationIds.filter(
            (id) => !subIds.includes(id)
          );
        }

        break;
      }
      default:
        newFilters[chip.key] = newFilters[chip.key].filter((id) => id !== chip.itemId);
        break;
    }

    applyFilter(newFilters);
  };

  const getItems = (query, isAutoload) => {
    setFilterAction(query);

    getTableDataAction(query).then((data) => {
      setItems(data, query, isAutoload);
      getLooseItemsCountsAction(query).then((res) => setCounts(res));
    });
  };

  const setItems = (data, query, isAutoload) => {
    if (!isAutoload) {
      setTableState(data?.items || []);
    } else {
      setTableState(tableState.concat(data?.items || []));
    }

    const updatedFilter = {
      ...query,
      pagination: { limit: data.limit, page: data.pageNumber, totalPages: data.totalPages },
      responseReceived: true
    };
    setFilterAction(updatedFilter);
  };

  useEffect(() => {
    if (!isConfigReceived) return;

    getFilterCriteriaAction().then(() => {
      getItems(generateInitialQuery());
    });
  }, [isConfigReceived]);

  useEffect(() => {
    if (!filterCriteria?.locations?.length) return;

    setChips(generateChips(filterCriteria, filters));
  }, [filter, filterCriteria]);

  const findMatches = (value) => getItems(getChangedBySearchFilter(filter, value));
  const sortTable = (rowName) => getItems(getChangedBySortFilter(filter, rowName));
  const changePage = (page, param) =>
    getItems(getChangedByPageFilter(filter, page), param === 'AUTOLOAD');
  const applyFilter = (values) => getItems(getChangedByApplyFilter(filter, values));
  const changeLimit = () =>
    getItems(getChangedByLimitFilter(filter, getTableLimit(LOOSE_ITEMS_AND_TENTS_TABLE_PARAMETER)));

  const emptyResponse = () => 'items' in tableData && !tableData?.items?.length;

  const emptyFilters = () => {
    return (
      filters.searchQuery === '' &&
      !filters.categoryIds.length &&
      !filters.looseItemIds.length &&
      !filters.locationIds.length &&
      !filters.locationJobNumberIds.length &&
      !filters.statusNames.length &&
      !filters.showWithBaa &&
      !filters.showWithReorderTrigger
    );
  };

  const getRowsCount = () => {
    return removeDuplicates(tableState.map(({ id }) => id))?.length;
  };

  const handleUpdateRow = (row) => {
    updateLooseItemPartAction(row).then(() => {
      if (getTableLimit(LOOSE_ITEMS_AND_TENTS_TABLE_PARAMETER) !== 100) {
        getItems(filter);
      } else {
        const rowId = row?.['locationLooseItemId'];
        const rowIndex = tableState.findIndex(
          ({ locationLooseItemId }) => locationLooseItemId === rowId
        );

        const clonedTableState = cloneObj(tableState);

        clonedTableState[rowIndex] = row;
        setTableState(clonedTableState);
      }
      getLooseItemsCountsAction(filter).then((res) => setCounts(res));
    });
  };

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  const csvQuery = {
    ...filter,
    filters: { ...filters, looseItemCustomFieldIds: customColumns.map(({ id }) => id) }
  };

  const openFilterModal = () => setOpenFilter(true);

  return (
    <section className={styles.pageWrapper}>
      <FilterModal open={openFilter} setOpen={setOpenFilter} onApply={applyFilter} />
      <div className={styles.pageContainer}>
        <ImportFilesPopup open={openPopup} setOpen={setOpenPopup} />
        <section className={styles.headerWrapper}>
          <SummaryHeader title="Loose Items List">
            {!isMobile && !isBasicRoleUser && (
              <button className={styles.addLooseItemsButton} onClick={() => setOpenPopup(true)}>
                <img src={ImportIcon} alt="import_file" />
                <span>Add loose items from CSV</span>
              </button>
            )}
            {!isMobile && <DownloadCSVButton filter={csvQuery} endpoint="LooseItems/Serialized" />}
            <div className={styles.controls}>
              {!isBasicRoleUser && <AddItemButton />}
              {(isAdminUser || isOperationsManagerUser) && (
                <MoveLooseItemsPopup isDashboardScreen={true} />
              )}
            </div>
          </SummaryHeader>
          {!isMobile && <LooseItemsCounts counts={counts} />}
          <div className={styles.tableControls}>
            <FilterWrapper>
              <FilterButton onClick={openFilterModal} isActive={openFilter || isFilterApplied} />
              {!isMobile && <FilterChips chips={chips} onDelete={deleteChip} />}
            </FilterWrapper>
            <div className={styles.tableControls__block}>
              <SearchTextFieldRefactored
                inputValue={filters.searchQuery}
                updateSearchParam={findMatches}
              />
              {!isMobile && <AddColumnButton />}
            </div>
          </div>
        </section>
        {isMobile && <AddColumnButton />}
        {isMobile && <LooseItemsCounts counts={counts} />}
        <PageTable
          isMobile={isMobile}
          data={tableState}
          tableRef={tableRef}
          emptyFilters={emptyFilters}
          responseReceived={responseReceived}
          sortRules={sortRules}
          updateSortParam={sortTable}
          updateRow={handleUpdateRow}
        />
      </div>
      <TablePaginationRefactored
        isMobile={isMobile}
        rows={getRowsCount()}
        currentPage={pagination.page}
        totalPages={pagination.totalPages}
        selectAllOption={true}
        onPageSelect={changePage}
        pageLimit={pagination.limit}
        hide={emptyResponse()}
        tableListParameter={LOOSE_ITEMS_AND_TENTS_TABLE_PARAMETER}
        getTableData={changeLimit}
        tableRef={tableRef}
      />
    </section>
  );
}

const mapStateToProps = createStructuredSelector({
  tableData: selectTableData(),
  filter: selectFilterData(),
  filterCriteria: selectFilterCriteriaData()
});

const mapDispatchToProps = {
  getTableDataAction: getTableData,
  getLooseItemsCountsAction: getLooseItemsCounts,
  setFilterAction: setListFilter,
  updateLooseItemPartAction: updateLooseItemPart,
  getFilterCriteriaAction: getFilterCriteria,
  clearStateAction: clearState
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(LooseItemsList);
