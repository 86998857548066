import {
  SET_BATCH_ASSET_UPDATES_LOCATIONS,
  SET_BATCH_ASSET_UPDATES_PROJECTS,
  SET_BATCH_ASSET_UPDATES_RENTAL_STATUSES,
  SET_BATCH_ASSET_UPDATES_SEVERITIES,
  SET_BATCH_ASSET_UPDATES_TAB,
  CLEAR_BATCH_ASSET_UPDATES_STATE
} from 'constants/reduceConstants';

const initialState = {
  selectedTab: '',
  locations: [],
  projects: [],
  rentalStatuses: [],
  severities: [],
  formState: {}
};

const batchAssetUpdatesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_BATCH_ASSET_UPDATES_TAB:
      return { ...state, selectedTab: payload };
    case SET_BATCH_ASSET_UPDATES_LOCATIONS:
      return { ...state, locations: payload };
    case SET_BATCH_ASSET_UPDATES_PROJECTS:
      return { ...state, projects: payload };
    case SET_BATCH_ASSET_UPDATES_RENTAL_STATUSES:
      return { ...state, rentalStatuses: payload };
    case SET_BATCH_ASSET_UPDATES_SEVERITIES:
      return { ...state, severities: payload };
    case CLEAR_BATCH_ASSET_UPDATES_STATE:
      return { ...initialState, formState: payload };
    default:
      return state;
  }
};

export default batchAssetUpdatesReducer;
