import React, { useEffect } from 'react';
import styles from './GeneralReportEdit.module.scss';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { useReportSelector, useReportActions } from 'hooks/ReportList';

import GeneralReportDetails from './components/GeneralDetails';
import ReportEditWrapper from '../../commonComponents/ReportEditWrapper';

import { formatDate, wait } from 'helpers/AppHelpers';

export default function GeneralReportEdit() {
  const { id } = useParams();

  const { getUsersForAssignAction, startAutoSaveAction } = useReportActions();
  const { activeTemplate, currentGeneralReport } = useReportSelector();
  const { templateReport } = activeTemplate;

  const startAutoSave = () => {
    wait(1000).then(() => startAutoSaveAction());
  };

  const methods = useForm({
    defaultValues: {
      reportType: {},
      generalReportTemplate: {},
      generalReportFields: [],
      resources: [],
      assignedAtUtc: null,
      signatures: []
    },
    mode: 'onChange'
  });
  const { reset, getValues } = methods;

  useEffect(() => {
    if (templateReport?.id) {
      const presetValues = {
        ...getValues(),
        createdAtUtc: formatDate(new Date()),
        reportType: { ...templateReport?.reportType },
        generalReportTemplate: { id: templateReport?.id, name: templateReport?.name },
        generalReportFields: templateReport?.generalReportFields
      };

      reset(presetValues);
      startAutoSave();
    }
  }, [activeTemplate]);

  useEffect(() => {
    getUsersForAssignAction({ shouldIncludeCurrentUser: true });
  }, []);

  useEffect(() => {
    if (id && currentGeneralReport?.id) {
      reset(currentGeneralReport);
      if (!currentGeneralReport.submitted) {
        startAutoSave();
      }
    }
  }, [currentGeneralReport]);

  return (
    <section className={styles.wrapper}>
      <FormProvider {...methods}>
        <ReportEditWrapper reportType="general">
          <GeneralReportDetails />
        </ReportEditWrapper>
      </FormProvider>
    </section>
  );
}
