import { cloneObj } from 'helpers/AppHelpers';

export const getUniqueAssets = (assetsArray) =>
  assetsArray.filter(
    (value, index, array) => array.findIndex((item) => item.drCode === value.drCode) === index
  );

export const tabs = {
  locationUpdate: 'locationUpdate',
  assetsInspection: 'assetsInspection',
  addAssetTicket: 'addAssetTicket',
  locationVerification: 'locationVerification',
  assetsStatusUpdate: 'assetsStatusUpdate',
  fieldsUpdate: 'fieldsUpdate'
};

export const defaultTab = tabs.locationUpdate;

export const isTabValueExist = (value) => Object.hasOwn(tabs, value);

export const TOGGLE_BUTTONS = [
  { id: 1, label: 'Location Update', value: tabs.locationUpdate },
  { id: 2, label: 'Assets Inspection', value: tabs.assetsInspection },
  { id: 3, label: 'Add Asset Ticket', value: tabs.addAssetTicket },
  { id: 4, label: 'Location Verification', value: tabs.locationVerification },
  { id: 5, label: 'Status Update', value: tabs.assetsStatusUpdate },
  { id: 6, label: 'Fields Update', value: tabs.fieldsUpdate }
];

export const defaultTicketModel = {
  filterLocationIds: [],
  assets: [],
  severity: {},
  severityList: [],
  resources: [],
  description: '',
  isOpened: true,
  ticketTypeList: [],
  rootCauseList: []
};

export const defaultAssetInspectionValues = { assets: [], filterLocationIds: [] };
export const defaultLocationVerificationValues = { assets: [], location: {} };
export const defaultStatusUpdateValues = {
  assets: [],
  rental: { rentalStatus: {} },
  filterLocationIds: []
};
export const defaultFieldsUpdateValues = { assets: [], assetFields: [], filterLocationIds: [] };
export const defaultAddAssetTicketsValues = { createServiceTickets: [defaultTicketModel] };
export const defaultLocationUpdateValues = {
  assets: [],
  location: {},
  sublocation: null,
  notes: '',
  sublocations: []
};

export const getOtherTabNames = (selectedTabName) =>
  Object.values(tabs).filter((name) => name !== selectedTabName);

export const getUpdatedTabValues = (tabName, assets, formValues) => {
  switch (tabName) {
    case tabs.locationVerification:
      return { ...defaultLocationVerificationValues, assets };
    case tabs.assetsInspection:
      return { assets };
    case tabs.assetsStatusUpdate:
      return { ...formValues[tabs.assetsStatusUpdate], assets };
    case tabs.locationUpdate:
      return { ...formValues[tabs.locationUpdate], assets };
    case tabs.fieldsUpdate:
      return { ...formValues[tabs.fieldsUpdate], assets };
    case tabs.addAssetTicket: {
      const createServiceTickets = cloneObj(formValues[tabs.addAssetTicket].createServiceTickets);
      if (assets?.length) {
        createServiceTickets[0].assets = assets;
        return { createServiceTickets };
      } else {
        return { createServiceTickets: [defaultTicketModel] };
      }
    }
  }
};

export const inspectedStatus = { name: 'Inspected', displayName: 'Inspected' };
export const verifiedStatus = { name: 'Verified', displayName: 'Verified' };
