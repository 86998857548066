import React from 'react';
import styles from './LevelsTable.module.scss';

import { NoDataTableRow, TableWrapper } from 'components/SummaryComponents';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';

import { Table, TableHead, TableBody } from '@material-ui/core';

import clsx from 'clsx';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { TEAM_MEMBER_LIST_PATH } from 'constants/routeConstants';

import { setTeamMemberListPrefilledFilter } from 'actions/teamMemberActions';

import { getProjectName } from 'helpers/AppHelpers';

const EmptyTable = () => (
  <div className={styles.table}>
    <TableWrapper>
      <Table>
        <TableHead>
          <StyledTableRow>
            <StyledTableCell>Job</StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          <NoDataTableRow isTableFiltered={false} />
        </TableBody>
      </Table>
    </TableWrapper>
  </div>
);

export default function StaffingLevelsTable({ dashboard, dashboardId, department }) {
  const dispatch = useDispatch();
  const history = useHistory();

  if (!('staffingLevelsProjects' in dashboard)) {
    return <EmptyTable />;
  }

  const { duration1ColumnName, duration2ColumnName, staffingLevelsProjects, total } = dashboard;

  const handleCellClick = (options) => {
    const filter = {
      searchQuery: '',
      dashboardId: dashboardId,
      dashboardType: 'StaffingLevelsAndAvailability',
      ...options
    };

    dispatch(setTeamMemberListPrefilledFilter(filter));
    history.push(TEAM_MEMBER_LIST_PATH);
  };

  return (
    <div className={styles.table}>
      <TableWrapper>
        <Table>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Job</StyledTableCell>
              <StyledTableCell>{duration1ColumnName || ''}</StyledTableCell>
              <StyledTableCell>{duration2ColumnName || ''}</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {staffingLevelsProjects?.length ? (
              <>
                {staffingLevelsProjects.map(({ project, staffingLevelsDurationValues }, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell className={styles.projectCell}>
                      {getProjectName(project)}
                    </StyledTableCell>
                    <StyledTableCell>
                      <span
                        className={clsx(
                          staffingLevelsDurationValues?.duration1Value > 0 && styles.clickable
                        )}
                        onClick={() =>
                          staffingLevelsDurationValues?.duration1Value > 0 &&
                          handleCellClick({
                            dashboardDuration: 1,
                            dashboardProjectId: project.id
                          })
                        }>
                        {staffingLevelsDurationValues?.duration1Value || 0}
                      </span>
                    </StyledTableCell>
                    <StyledTableCell>
                      <span
                        className={clsx(
                          staffingLevelsDurationValues?.duration2Value > 0 && styles.clickable
                        )}
                        onClick={() =>
                          staffingLevelsDurationValues?.duration2Value > 0 &&
                          handleCellClick({
                            dashboardDuration: 2,
                            dashboardProjectId: project.id
                          })
                        }>
                        {staffingLevelsDurationValues?.duration2Value || 0}
                      </span>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
                <StyledTableRow className={styles.total}>
                  <StyledTableCell>GRAND TOTAL WORKING</StyledTableCell>
                  <StyledTableCell>{total?.duration1Value || 0}</StyledTableCell>
                  <StyledTableCell>{total?.duration2Value || 0}</StyledTableCell>
                </StyledTableRow>
              </>
            ) : (
              <NoDataTableRow isTableFiltered={!!department?.id} />
            )}
          </TableBody>
        </Table>
      </TableWrapper>
    </div>
  );
}
