import { useSelector } from 'react-redux';

import {
  selectBatchAssetUpdatesSelectedTab,
  selectBatchAssetUpdatesLocationsData,
  selectBatchAssetUpdatesProjectsData,
  selectBatchAssetUpdatesSeverities,
  selectBatchAssetUpdatesRentalStatusesData,
  selectBatchAssetUpdatesFormState
} from 'pages/BatchUpdates/Asset/selectors';

export function useBatchAssetUpdatesSelector() {
  const selectedTab = useSelector(selectBatchAssetUpdatesSelectedTab());

  const locations = useSelector(selectBatchAssetUpdatesLocationsData());

  const projects = useSelector(selectBatchAssetUpdatesProjectsData());

  const rentalStatuses = useSelector(selectBatchAssetUpdatesRentalStatusesData());

  const severities = useSelector(selectBatchAssetUpdatesSeverities());

  const formState = useSelector(selectBatchAssetUpdatesFormState());

  return {
    locations,
    projects,
    rentalStatuses,
    severities,
    selectedTab,
    formState
  };
}
