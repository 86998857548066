import React from 'react';
import styles from './Details.module.scss';

import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';

import {
  dateTimeFormat,
  formatDate,
  getLocationWithSublocationName,
  getUserFullName
} from 'helpers/AppHelpers';

import Subtitle from 'components/DetailsComponents/Subtitle';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';

import { useTicketSelector } from 'hooks/Ticket';

export default function TicketRemarks() {
  const { currentTicket } = useTicketSelector();
  const { remarks } = currentTicket;

  return (
    <div className={styles.remarks}>
      <Subtitle>Remarks</Subtitle>

      <TableContainer className={styles.table} component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Description</StyledTableCell>
              <StyledTableCell>Location(Sublocation)</StyledTableCell>
              <StyledTableCell>Date</StyledTableCell>
              <StyledTableCell>Created</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {remarks?.length ? (
              remarks.map(
                ({ id, description, createdAtUtc, createdByUser, assetLocationHistory }) => (
                  <StyledTableRow key={id}>
                    <StyledTableCell>{description}</StyledTableCell>
                    <StyledTableCell>
                      {getLocationWithSublocationName(
                        assetLocationHistory?.location,
                        assetLocationHistory?.sublocation
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      {createdAtUtc ? formatDate(createdAtUtc, dateTimeFormat) : ''}
                    </StyledTableCell>
                    <StyledTableCell>
                      {createdByUser?.firstName ? getUserFullName(createdByUser) : ''}
                    </StyledTableCell>
                  </StyledTableRow>
                )
              )
            ) : (
              <StyledTableRow>
                <StyledTableCell colSpan={10} className={styles.empty}>
                  No available data in the table.
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
