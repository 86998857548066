import React, { useEffect, useRef, useState } from 'react';
import styles from './PicklistDetailed.module.scss';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  selectDrCodes,
  selectPicklistAssetStatuses,
  selectSinglePicklistData,
  selectStatusesData,
  selectTruckOrTrailers
} from '../selectors';
import {
  clearPickListState,
  deletePicklist,
  getAssets,
  getPicklistAssetStatuses,
  getSinglePicklist,
  getStatuses,
  updatePicklist,
  searchTruckOrTrailer,
  updatePicklistAsset,
  searchDrCodes,
  deleteFileFromAsset,
  deleteItems,
  checkBillOfLadingItems,
  getPicklistAssetsCounting,
  checkMatchingLocation
} from 'actions/pickListActions';
import { selectUserConfig } from 'pages/commonSelectors';

import { useParams, useHistory } from 'react-router-dom';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { useManagePicklistItemActions } from 'hooks/ManagePicklistItem';

import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import DatePicker from 'components/StyledComponents/DatePicker';
import SelectInput from 'components/SelectInput/SelectInput';
import SearchInputWithTags from 'components/SearchInputWithTags/SearchInputWithTags';
import UploadFileButton from 'components/UploadFileButton/UploadFileButton';
import LinkComponent from 'components/LinkComponent/LinkComponent';

import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import ExpandMore from '@material-ui/icons/ExpandMore';
import CreateIcon from '@material-ui/icons/Create';
import {
  Button,
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@material-ui/core';

import {
  ASSET_DETAILED_PATH,
  BILL_OF_LADING_DETAILS_PATH,
  LOCATION_DETAILED_PATH,
  LOOSE_ITEM_DETAILED_PATH,
  MANAGE_PICKLIST_LOOSE_ITEMS_PATH
} from 'constants/routeConstants';
import {
  DELETE_ASSETS_FROM_THE_PICKLIST,
  DELETE_ASSETS_FROM_THE_PICKLIST_TYPE,
  DELETE_FILE_FROM_PICKLIST_ASSET,
  DELETE_FILE_FROM_PICKLIST_ASSET_TYPE,
  DELETE_PICKLIST,
  DELETE_PICKLIST_ASSET,
  DELETE_PICKLIST_ASSET_TYPE,
  PICKLIST_ASSETS_LOCATION_CHECK,
  PICKLIST_ASSETS_LOCATION_CHECK_TYPE,
  PICKLIST_COMPLETED_STATUS,
  PICKLIST_COMPLETED_STATUS_TYPE
} from 'constants/dialogPopupsData';

import {
  allKeyboardLatSymbols,
  enrichNameToId,
  formatDate,
  isAdminUser,
  isOperationsManagerUser,
  reformatInputValue,
  fileDownload,
  isFieldTechUser,
  isCreatorUser,
  isLocationInConfig,
  isPersonnelManagerUser,
  isTeamMemberUser,
  cloneObj
} from 'helpers/AppHelpers';

import BasketIcon from 'assets/images/busketIcon.svg';
import RemoveIcon from 'assets/images/deleteIcon.svg';

import FileActionsPopper from '../components/FileActionsPopper/FileActionsPopper';

import clsx from 'clsx';

import PicklistDetailedFilter from './PicklistDetailedFilter';

import Header from './components/Header';
import Details from './components/Details/Details';
import MatchingLocation from '../../../../components/ModalContents/MatchingLocation';
import ConfirmationPopup from '../../../../components/ConfirmationPopup/ConfirmationPopup';

const RECEIVED_AT_YARD_STATUS = 'Received at Yard';
const RECEIVED_AT_JOB_SITE_STATUS = 'Received at Job Site';

const PICKLIST_STATUSES = {
  open: 'Open',
  delivered: 'Delivered',
  completed: 'Completed'
};
let key = new Date().getTime() + Math.random();

function PicklistDetailed({
  currentPicklist,
  getPickListAction,
  checkBillOfLadingItemsAction,
  checkMatchingLocationAction,
  deletePicklistAction,
  deleteItemsAction,
  updatePickListAction,
  updatePicklistAssetAction,
  getAssetsAction,
  getPicklistAssetStatusesAction,
  getPicklistAssetsCountingAction,
  picklistAssetStatuses,
  getStatusListAction,
  picklistStatuses,
  clearStateAction,
  userConfig,
  truckOrTrailers,
  drCodes,
  searchDrCodesAction,
  deleteFileFromAssetAction,
  searchTruckOrTrailerAction
}) {
  const isMobile = useMobileViewport();
  const { id } = useParams();
  const history = useHistory();
  const { userRole, email, locations } = userConfig;
  const savedStartRangeState = useRef(null);
  const [tableState, setTableState] = useState([]);
  const [values, setValues] = useState({});
  const [truckOrTrailerOptions, setTruckOrTrailerOptions] = useState([]);
  const [drCodesOptions, setDrCodesOptions] = useState([]);
  const [filter, setFilter] = useState({
    filters: {
      picklistIds: [id],
      billOfLadingIds: [],
      originLocationIds: [],
      originLocationJobNumberIds: [],
      originSublocationIds: [],
      picklistAssetStatusNames: [],
      pickUpDateFrom: null,
      pickUpDateTo: null,
      trackAndTrailerAssetDrCodes: [],
      isAssetsOnly: false,
      isLooseItemsOnly: false
    },
    sortRules: []
  });

  const { setPicklistDataAction } = useManagePicklistItemActions();

  const { sortRules } = filter;

  const [notesEditArray, setNotesEditArray] = useState([]);
  const [drIdsEditArray, setDrIdsEditArray] = useState([]);
  const [datesEditArray, setDatesEditArray] = useState([]);
  const [statusEditArray, setStatusEditArray] = useState([]);
  const [truckEditArray, setTruckEditArray] = useState([]);
  const [checkedArray, setCheckedArray] = useState([]);

  const [dialogModalData, setDialogModalData] = useState({
    isOpened: false
  });

  const getAssets = (inputFilter) => {
    getAssetsAction(inputFilter || filter).then((res) => {
      setTableState(res || []);
      getPicklistAssetsCountingAction(inputFilter || filter);
    });
  };
  const getFilteredAssets = (inputFilter) => {
    const query = { ...filter, filters: { ...filter.filters, ...inputFilter } };
    getAssetsAction(query).then((res) => {
      setTableState(res || []);
      setFilter(query);
      getPicklistAssetsCountingAction(query);
    });
  };

  useEffect(() => {
    getStatusListAction();
  }, []);

  useEffect(() => {
    getPicklistAssetStatusesAction({ picklistId: id });
  }, []);

  useEffect(() => {
    if (id) {
      getPickListAction(id);
      getAssets(filter);
    }
  }, [id]);

  useEffect(() => {
    if (truckOrTrailers?.items?.length) {
      setTruckOrTrailerOptions(truckOrTrailers?.items);
    }
  }, [truckOrTrailers]);

  useEffect(() => {
    if (drCodes?.length) {
      setDrCodesOptions(drCodes);
    }
    return () => {
      setDrCodesOptions([]);
    };
  }, [drCodes]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  useEffect(() => {
    return () => {
      setTruckOrTrailerOptions([]);
    };
  }, []);

  const isDeleteAvailable = () => {
    if (isAdminUser(userRole)) return true;
    else if (isOperationsManagerUser(userRole) || isFieldTechUser(userRole))
      return isCreatorUser(currentPicklist.createdByUser.email, email) || isLocationAvailable();
    else return isCreatorUser(currentPicklist.createdByUser.email, email);
  };
  const isCreateBoLAvailable = () => {
    if (isAdminUser(userRole)) return true;
    else return isCreatorUser(currentPicklist.createdByUser.email, email);
  };
  const isCellEditable = () => {
    if (isAdminUser(userRole)) return true;
    else if (isOperationsManagerUser(userRole) || isFieldTechUser(userRole))
      return isCreatorUser(currentPicklist.createdByUser.email, email) || isLocationAvailable();
    else return isCreatorUser(currentPicklist.createdByUser.email, email);
  };
  const isPencilAvailable = () => {
    if (isAdminUser(userRole)) return true;
    else if (isPersonnelManagerUser(userRole) || isTeamMemberUser(userRole))
      return isCreatorUser(currentPicklist.createdByUser.email, email);
    else return isCreatorUser(currentPicklist.createdByUser.email, email) || isLocationAvailable();
  };
  const isLocationAvailable = () =>
    locations?.some((location) => location?.id === +currentPicklist?.location?.id);

  const getFilteredArray = (array, removedIds, param) => {
    return [...array].filter((el) => removedIds.indexOf(param === 'byElem' ? el.id : el) === -1);
  };
  const filterArrays = (ids) => {
    setCheckedArray(getFilteredArray(checkedArray, ids, 'byId'));
    setStatusEditArray(getFilteredArray(statusEditArray, ids, 'byElem'));
    setNotesEditArray(getFilteredArray(notesEditArray, ids, 'byElem'));
    setDatesEditArray(getFilteredArray(datesEditArray, ids, 'byElem'));
  };

  const onAgree = () => {
    switch (dialogModalData.type) {
      case DELETE_PICKLIST_ASSET_TYPE: {
        setDialogModalData({ isOpened: false });
        const arrayWithRowId = [dialogModalData.deletedRowId];
        const query = { ids: arrayWithRowId };
        deleteItemsAction(query).then((res) => {
          if (res.status === 200) {
            getAssets();
            filterArrays(arrayWithRowId);
          }
        });
        break;
      }
      case DELETE_ASSETS_FROM_THE_PICKLIST_TYPE: {
        setDialogModalData({ isOpened: false });
        const query = { ids: checkedArray };
        deleteItemsAction(query).then((res) => {
          if (res.status === 200) {
            getAssets();
            filterArrays(checkedArray);
          }
        });
        break;
      }
      case DELETE_FILE_FROM_PICKLIST_ASSET_TYPE: {
        setDialogModalData({ isOpened: false });
        deleteFileFromAssetAction(dialogModalData.asset.resource.id).then((res) => {
          if (res.status === 200) {
            const row = { ...dialogModalData.asset, resource: null };
            updateAssetInRow(row);
          }
        });
        break;
      }
      case PICKLIST_COMPLETED_STATUS_TYPE: {
        setDialogModalData({ isOpened: false });
        updatePickListAction(
          {
            ...currentPicklist,
            status: { ...picklistStatuses.find((el) => el.name === PICKLIST_STATUSES.completed) },
            assets: [...tableState]
          },
          true,
          false
        ).then((res) => {
          if (res.id) {
            setTableState(res?.assets || []);
            filterArrays(checkedArray);
          }
        });
        break;
      }
      case PICKLIST_ASSETS_LOCATION_CHECK_TYPE: {
        updateRow(dialogModalData.editedRow);
        setDialogModalData({ isOpened: false });
        break;
      }
      default:
        deletePicklistAction(currentPicklist.id);
        break;
    }
  };

  const onDismiss = () => {
    setDialogModalData({ isOpened: false });
  };

  const onChangeStatus = () => {
    if (currentPicklist?.status?.name === PICKLIST_STATUSES.open) {
      updatePickListAction(
        {
          ...currentPicklist,
          status: { ...picklistStatuses.find((el) => el.name === PICKLIST_STATUSES.delivered) },
          assets: [...tableState]
        },
        true
      ).then((res) => {
        if (res.id) {
          setTableState(res?.assets || []);
          filterArrays(checkedArray);
        }
      });
    } else {
      setDialogModalData({ ...PICKLIST_COMPLETED_STATUS, isOpened: true });
    }
  };

  const removePicklist = () => setDialogModalData({ ...DELETE_PICKLIST, isOpened: true });

  const handleManageLooseItems = (row) => {
    const locationLooseItemDetails = row?.locationLooseItem?.id
      ? {
          id: row.locationLooseItem.id,
          moveQuantity: row?.moveQuantity || 0,
          moveByAheadAccount: row?.moveByAheadAccount || 0,
          picklistAssetId: row?.id
        }
      : {};

    setPicklistDataAction({
      locationLooseItemDetails,
      activePicklist: currentPicklist
    });
    history.push(MANAGE_PICKLIST_LOOSE_ITEMS_PATH);
  };

  const updateAssetInRow = (row) => {
    updatePicklistAssetAction(row).then((res) => res.id && getAssets());
  };

  const onNotesInput = (event, id) => {
    const notesArray = [...notesEditArray];
    const index = notesEditArray.findIndex((item) => item.id === id);
    notesArray[index].value = reformatInputValue(event.target.value, 1000, allKeyboardLatSymbols);
    setNotesEditArray(notesArray);
  };

  const handleNotesEditUpdate = (row, param) => {
    if (param === 'accept') {
      const asset = {
        ...row,
        notes: notesEditArray.find((item) => item.id === row.id)?.value
      };
      updateAssetInRow(asset);
    }
    if (notesEditArray.find((item) => item.id === row.id)) {
      setNotesEditArray(notesEditArray.filter((item) => item.id !== row.id));
    } else {
      setNotesEditArray([...notesEditArray, { id: row.id, value: row.notes }]);
    }
  };

  const handleDatesEditUpdate = (row, param) => {
    if (param === 'accept') {
      const asset = {
        ...row,
        pickupDate: datesEditArray.find((item) => item.id === row.id)?.value
      };
      updateAssetInRow(asset);
    }
    if (param === 'decline') {
      updateAssetInRow({ ...row, pickupDate: null });
    }
    if (datesEditArray.find((item) => item.id === row.id)) {
      setDatesEditArray(datesEditArray.filter((item) => item.id !== row.id));
    } else {
      setDatesEditArray([...datesEditArray, { id: row.id, value: row?.pickupDate || null }]);
    }
  };

  const handleDateChange = (date, name) => {
    const datesArray = [...datesEditArray];
    const index = datesEditArray.findIndex((item) => item.id === name);
    datesArray[index].value = date || null;
    setDatesEditArray(datesArray);
  };

  const handleAllCheckbox = () => {
    savedStartRangeState.current = null;

    if (checkedArray.length) {
      setCheckedArray([]);
    } else {
      setCheckedArray([...tableState.map(({ id }) => id)]);
    }
  };

  const selectSimpleCheckbox = (isCheckboxSelected, rowId) => {
    savedStartRangeState.current = { selectedId: rowId, isSelected: isCheckboxSelected };

    let newCheckedArray = [...checkedArray];
    if (isCheckboxSelected) {
      newCheckedArray.push(rowId);
    } else {
      newCheckedArray = newCheckedArray.filter((id) => id !== rowId);
    }
    setCheckedArray(newCheckedArray);
  };

  const selectRange = (isCheckboxSelected, selectedRowId) => {
    if (!savedStartRangeState.current || !savedStartRangeState.current?.selectedId) {
      selectSimpleCheckbox(isCheckboxSelected, selectedRowId);
      return;
    }

    const { selectedId, isSelected } = savedStartRangeState.current;

    const startIndex = tableState.findIndex(({ id }) => id === selectedId);
    const endIndex = tableState.findIndex(({ id }) => id === selectedRowId);

    if (isNaN(startIndex) || isNaN(endIndex)) return;
    if (startIndex === endIndex) {
      selectSimpleCheckbox(isCheckboxSelected, selectedRowId);
      return;
    }

    const range = [startIndex, endIndex].sort((a, b) => a - b);
    const min = range[0];
    const max = range[1] + 1;
    const idsInRange = tableState.slice(min, max).map(({ id }) => id);

    if (isSelected) {
      if (idsInRange.every((id) => checkedArray.includes(id))) return;

      const currentCheckedState = cloneObj(checkedArray);
      idsInRange.forEach((rangeId) => {
        if (checkedArray.includes(rangeId)) return;
        currentCheckedState.push(rangeId);
      });

      setCheckedArray(currentCheckedState);
    } else {
      if (idsInRange.every((id) => !checkedArray.includes(id))) return;

      const excludedIds = [];
      idsInRange.forEach((rangeId) => {
        if (!checkedArray.includes(rangeId)) return;
        excludedIds.push(rangeId);
      });

      setCheckedArray(checkedArray.filter((id) => !excludedIds.includes(id)));
    }
  };

  const handleCheckbox = (e, rowId) => {
    if (!e.nativeEvent.shiftKey) {
      selectSimpleCheckbox(e.target.checked, rowId);
    } else {
      selectRange(e.target.checked, rowId);
    }
  };

  const onRemoveRow = (rowId) => {
    setDialogModalData({ ...DELETE_PICKLIST_ASSET, isOpened: true, deletedRowId: rowId });
  };

  const onDeleteSelected = () => {
    setDialogModalData({ ...DELETE_ASSETS_FROM_THE_PICKLIST, isOpened: true });
  };

  const handleCreateBOLClick = () => {
    checkBillOfLadingItemsAction({ picklistAssetIds: checkedArray });
  };

  const onSortApply = (rowName) => {
    let currFilter = {};
    if (sortRules.indexOf(rowName) >= 0) {
      if (sortRules.indexOf('-') >= 0) {
        currFilter = { ...filter, sortRules: rowName };
      } else {
        currFilter = { ...filter, sortRules: `-${rowName}` };
      }
    } else {
      currFilter = { ...filter, sortRules: rowName };
    }
    setFilter(currFilter);
    getAssets({
      ...currFilter
    });
  };

  const isSorted = (rowName) => {
    return sortRules.indexOf(rowName) >= 0;
  };

  const isAscend = (rowName) => {
    return sortRules.indexOf(rowName) >= 0 && sortRules.indexOf('-') >= 0;
  };

  const onStatusSelect = (data) => {
    const statusArray = [...statusEditArray];
    const index = statusEditArray.findIndex((item) => item.id === data.name);
    statusArray[index].value = data.value;
    setStatusEditArray(statusArray);
  };

  const handleStatusEditUpdate = (row, param) => {
    if (param === 'accept') {
      const asset = {
        ...row,
        status: statusEditArray.find((item) => item.id === row.id)?.value
      };
      updateAssetInRow(asset);
    }
    if (statusEditArray.find((item) => item.id === row.id)) {
      setStatusEditArray(statusEditArray.filter((item) => item.id !== row.id));
    } else {
      setStatusEditArray([...statusEditArray, { id: row.id, value: row.status }]);
    }
  };

  const onSelect = (selectedItem) => {
    setValues({
      ...values,
      [selectedItem.name]: selectedItem.value
    });
  };

  const handleTruckEditUpdate = (row, param) => {
    if (param === 'accept') {
      const asset = {
        ...row,
        truck: truckEditArray.find((item) => item.id === row.id)?.value
      };
      updateAssetInRow(asset);
    }
    if (param === 'decline') {
      const asset = { ...row, truck: null };
      updateAssetInRow(asset);
    }
    if (truckEditArray.find((item) => item.id === row.id)) {
      setTruckEditArray(truckEditArray.filter((item) => item.id !== row.id));
    } else {
      setTruckEditArray([...truckEditArray, { id: row.id, value: row.truck }]);
    }
  };

  const onTruckSelect = (value, rowId) => {
    const truckArray = [...truckEditArray];
    const index = truckEditArray.findIndex((item) => item.id === rowId);
    truckArray[index].value = value;
    setTruckEditArray(truckArray);
    key = new Date().getTime() + Math.random();
  };

  const onTruckOrTrailerFilterChange = (searchQuery) => {
    const value = searchQuery ? searchQuery.replace(allKeyboardLatSymbols, '') : '';
    if (value !== '') {
      const currFilter = {
        pagination: {
          limit: 39,
          page: 1
        },
        filters: {
          isDeleted: false,
          includeThirdPartyTruck: true,
          equipmentIds: [9, 10],
          searchQuery
        }
      };
      searchTruckOrTrailerAction(currFilter);
    }
  };

  const showMatchModal = (row, res) => {
    setDialogModalData({
      ...PICKLIST_ASSETS_LOCATION_CHECK,
      editedRow: row,
      content: (
        <MatchingLocation
          items={res.assets}
          options={{
            itemType: 'asset',
            documentType: 'picklist',
            endpointType: res['isPicklistWithSublocation'] ? 'sublocation' : 'location'
          }}
        />
      )
    });
  };

  const updateRow = (row) => {
    const asset = {
      ...row,
      asset: drIdsEditArray.find((item) => item.id === row.id)?.value
    };
    updateAssetInRow(asset);
    handleDrIdsEditUpdate(row);
  };

  const handleSaveDrCode = (row) => {
    const query = {
      picklistId: currentPicklist.id,
      assetIds: [drIdsEditArray.find((item) => item.id === row.id)?.value?.id]
    };
    checkMatchingLocationAction(query).then((res) => {
      if (!res?.assets?.length) {
        updateRow(row);
      } else {
        showMatchModal(row, res);
      }
    });
  };

  const handleDrIdsEditUpdate = (row) => {
    if (drIdsEditArray.find((item) => item.id === row.id)) {
      setDrIdsEditArray([]);
    } else {
      setDrIdsEditArray([{ id: row.id, value: row.asset }]);
    }
    setDrCodesOptions([]);
  };

  const onDrIdSelect = (value, rowId) => {
    const drCodesArray = [...drIdsEditArray];
    const index = drIdsEditArray.findIndex((item) => item.id === rowId);
    drCodesArray[index].value = value;
    setDrIdsEditArray(drCodesArray);
    key = new Date().getTime() + Math.random();
  };

  const onDrCodesFilterChange = (searchQuery, assetId) => {
    const value = searchQuery ? searchQuery.replace(allKeyboardLatSymbols, '') : '';
    const needAssetId = tableState.find(({ id }) => id === assetId).asset.id;
    if (value !== '') {
      const currFilter = {
        assetId: needAssetId,
        picklistId: currentPicklist.id,
        limit: 40,
        searchQuery
      };
      searchDrCodesAction(currFilter);
    }
  };

  const onApply = () => {
    const newArray = [...tableState].map((el) =>
      checkedArray.indexOf(el.id) === -1 ? el : { ...el, status: values.status }
    );
    updatePickListAction(
      {
        ...currentPicklist,
        assets: [...newArray]
      },
      true
    ).then((res) => {
      if (res.id) {
        getAssets();
        setStatusEditArray(
          [...statusEditArray].map((el) =>
            checkedArray.indexOf(el.id) === -1 ? el : { ...el, value: values.status }
          )
        );
        setValues({});
        setCheckedArray([]);
      }
    });
  };

  const isNotOpenStatus = () => currentPicklist?.status?.name !== PICKLIST_STATUSES.open;

  const isPicklistCompleted = () => currentPicklist?.status?.name === PICKLIST_STATUSES.completed;

  const onUpload = (assetRow, file) => updateAssetInRow({ ...assetRow, resource: file });

  const onDownloadFile = (asset) => {
    fileDownload(asset.resource);
  };

  const onDelete = (asset) => {
    setDialogModalData({ ...DELETE_FILE_FROM_PICKLIST_ASSET, isOpened: true, asset: asset });
  };

  const isUploadAvailable = () => {
    if (isAdminUser(userRole)) return true;
    if (isOperationsManagerUser(userRole) || isFieldTechUser(userRole)) {
      return (
        isLocationInConfig(userRole, locations, currentPicklist.location.id) ||
        isCreatorUser(currentPicklist.createdByUser.email, email)
      );
    }
    return isCreatorUser(currentPicklist.createdByUser.email, email);
  };

  const isHeadCheckboxChecked = () => {
    const ids = tableState.map(({ id }) => id);
    return ids.every((el) => checkedArray.includes(el));
  };

  const handlePencilClick = (row) => {
    if (row?.locationLooseItem?.id) {
      handleManageLooseItems(row);
    } else {
      handleDrIdsEditUpdate(row);
    }
  };

  const areCheckboxesAvailable = () => isCreateBoLAvailable() || isDeleteAvailable();

  const isFilterEmpty = () => {
    const { filters } = filter;
    return (
      !filters?.billOfLadingIds?.length &&
      !filters?.originLocationIds?.length &&
      !filters?.originLocationJobNumberIds?.length &&
      !filters?.originSublocationIds?.length &&
      !filters?.picklistAssetStatusNames?.length &&
      !filters?.trackAndTrailerAssetDrCodes?.length &&
      filters.pickUpDateFrom === null &&
      filters.pickUpDateTo === null &&
      filters.isAssetsOnly === false &&
      filters.isLooseItemsOnly === false
    );
  };

  return (
    <>
      <section className={styles.pageContainer}>
        <ConfirmationPopup data={dialogModalData} onAgree={onAgree} onDismiss={onDismiss} />
        {currentPicklist?.id === +id && (
          <div>
            <Header
              onRemove={removePicklist}
              updateTable={getAssets}
              onAddItems={handleManageLooseItems}
              onChangeStatus={onChangeStatus}
              isTableEmpty={!tableState.length}
            />
            <Details />

            <section className={styles.tableHelpers}>
              <div className={styles.tableHelpers__leftBlock}>
                <PicklistDetailedFilter
                  isMobile={isMobile}
                  onApply={getFilteredAssets}
                  filters={filter.filters}
                  picklistCreatedDate={currentPicklist?.createdAtUtc}
                />
                {isNotOpenStatus() && !!checkedArray.length && (
                  <div className={styles.statusField}>
                    <SelectInput
                      name="status"
                      value={values?.status?.name || ''}
                      menuItems={enrichNameToId(picklistAssetStatuses) || []}
                      onSelect={onSelect}
                    />
                    <Button className={styles.status_button} onClick={onApply}>
                      {isMobile ? <CheckIcon /> : <span>Apply</span>}
                    </Button>
                  </div>
                )}
              </div>

              {!!checkedArray.length && (
                <div className={styles.tableHelpers__controls}>
                  {isCreateBoLAvailable() && (
                    <Button className={styles.create_bol_button} onClick={handleCreateBOLClick}>
                      <span>Create Bill of Lading</span>
                    </Button>
                  )}
                  {isDeleteAvailable() && (
                    <Button className={styles.delete_button} onClick={onDeleteSelected}>
                      <span>Delete All</span>
                    </Button>
                  )}
                </div>
              )}
            </section>

            <TableContainer className={styles.tableContainer} component={Paper}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    {!!tableState.length && areCheckboxesAvailable() && (
                      <StyledTableCell className={styles.checkboxHeadCell}>
                        <Checkbox
                          classes={{ root: styles.checkbox, checked: styles.checked }}
                          onChange={handleAllCheckbox}
                          value={'selectAll'}
                          checked={isHeadCheckboxChecked()}
                        />
                      </StyledTableCell>
                    )}
                    <StyledTableCell>
                      <div
                        className={`${styles.sortHeader} ${
                          isSorted('itemName') ? styles.sorted : ''
                        } ${isAscend('itemName') ? styles.ascend : ''}`}
                        onClick={() => onSortApply('itemName')}>
                        <span>DR-ID or Loose Item(QTY)</span>
                        <ExpandMore />
                      </div>
                    </StyledTableCell>
                    <StyledTableCell>Origin Location (Sublocation)</StyledTableCell>
                    <StyledTableCell className={styles.notesHeadCell}>
                      Picklist Notes
                    </StyledTableCell>
                    <StyledTableCell className={styles.dateHeadCell}>
                      <div
                        className={`${styles.sortHeader} ${
                          isSorted('pickupDate') ? styles.sorted : ''
                        } ${isAscend('pickupDate') ? styles.ascend : ''}`}
                        onClick={() => onSortApply('pickupDate')}>
                        <span>Pick Up date</span>
                        <ExpandMore />
                      </div>
                    </StyledTableCell>
                    <StyledTableCell className={styles.statusHeadCell}>
                      <div
                        className={`${styles.sortHeader} ${
                          isSorted('status.name') ? styles.sorted : ''
                        } ${isAscend('status.name') ? styles.ascend : ''}`}
                        onClick={() => onSortApply('status.name')}>
                        <span>Status of item</span>
                        <ExpandMore />
                      </div>
                    </StyledTableCell>
                    <StyledTableCell className={styles.truckHeadCell}>
                      <div
                        className={`${styles.sortHeader} ${
                          isSorted('truck.drCode') ? styles.sorted : ''
                        } ${isAscend('truck.drCode') ? styles.ascend : ''}`}
                        onClick={() => onSortApply('truck.drCode')}>
                        <span>Truck or Trailer</span>
                        <ExpandMore />
                      </div>
                    </StyledTableCell>
                    <StyledTableCell className={styles.freightBillHeadCell}>
                      Freight Bill
                    </StyledTableCell>
                    <StyledTableCell className={styles.loadHeadCell}>Load File</StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableState?.length ? (
                    tableState.map((row) => (
                      <StyledTableRow key={row.id}>
                        {areCheckboxesAvailable() && (
                          <StyledTableCell
                            className={clsx(
                              styles.checkboxCell,
                              row.id === savedStartRangeState.current?.selectedId &&
                                !isMobile &&
                                styles.highlighted
                            )}>
                            <Checkbox
                              classes={{ root: styles.checkbox, checked: styles.checked }}
                              onChange={(e) => handleCheckbox(e, row.id)}
                              value={row.id}
                              checked={!!checkedArray?.find((id) => id === row.id)}
                            />
                          </StyledTableCell>
                        )}
                        <StyledTableCell className={styles.drIdCell}>
                          {!drIdsEditArray?.find((item) => item.id === row.id) ? (
                            <section className={styles.drIdsBlock}>
                              <LinkComponent
                                path={
                                  row?.locationLooseItem?.id
                                    ? `${LOOSE_ITEM_DETAILED_PATH}/${row.locationLooseItem.looseItem.id}`
                                    : `${ASSET_DETAILED_PATH}/${row.asset.id}`
                                }
                                name={row?.itemName || ''}
                                isRedirectAvailable={
                                  row?.locationLooseItem?.id ? true : !!row?.asset?.id
                                }
                              />
                              {!!row?.locationLooseItem?.id && (
                                <span>{' (' + (row.moveQuantity || 0) + ') '}</span>
                              )}
                              {!!(
                                isPencilAvailable() &&
                                row?.status?.displayName !== RECEIVED_AT_YARD_STATUS &&
                                row?.status?.displayName !== RECEIVED_AT_JOB_SITE_STATUS &&
                                (row?.locationLooseItem?.id || row?.asset?.id)
                              ) && (
                                <IconButton
                                  className={styles.pencil}
                                  onClick={() => handlePencilClick(row)}>
                                  <CreateIcon />
                                </IconButton>
                              )}
                            </section>
                          ) : (
                            <section className={styles.drIdsBlock}>
                              <SearchInputWithTags
                                displayName="drCode"
                                defaultInputValue={
                                  drIdsEditArray?.find((item) => item.id === row.id)?.value || {}
                                }
                                options={drCodesOptions}
                                multiple={false}
                                onInputChange={onDrCodesFilterChange}
                                onSelect={onDrIdSelect}
                                index={row.id}
                                key={key}
                                specialView={true}
                                disablePaste={true}
                                fullWidth={true}
                              />

                              <div className={styles.helpers}>
                                <Button
                                  onClick={() => handleDrIdsEditUpdate(row, 'decline')}
                                  className={styles.alert}>
                                  <CloseIcon />
                                </Button>
                                <Button
                                  onClick={() => handleSaveDrCode(row)}
                                  className={styles.success}>
                                  <CheckIcon />
                                </Button>
                              </div>
                            </section>
                          )}
                        </StyledTableCell>
                        <StyledTableCell className={styles.locationCell}>
                          <LinkComponent
                            path={`${LOCATION_DETAILED_PATH}/${row.location?.id}`}
                            name={
                              row?.location?.id && row?.sublocation?.id
                                ? row.location.siteCode + '(' + row.sublocation.name + ')'
                                : row?.location?.id
                                ? row.location.siteCode
                                : ''
                            }
                            isRedirectAvailable={!row.location?.deleted && row?.location?.id}
                          />
                        </StyledTableCell>
                        <StyledTableCell className={styles.noteCell}>
                          {!notesEditArray.find((item) => item.id === row.id) ? (
                            <section
                              className={clsx(
                                styles.notesBlock,
                                isCellEditable() && styles.pointer
                              )}
                              onClick={() => isCellEditable() && handleNotesEditUpdate(row)}>
                              {row?.notes || '\u2000'}
                            </section>
                          ) : (
                            <section className={styles.notesBlock}>
                              <TextField
                                multiline
                                name={row.id}
                                className={styles.textInput}
                                variant="outlined"
                                value={
                                  notesEditArray?.find((item) => item.id === row.id)?.value || ''
                                }
                                onChange={(event) => onNotesInput(event, row.id)}
                              />
                              <div className={styles.helpers}>
                                <Button
                                  onClick={() => handleNotesEditUpdate(row, 'decline')}
                                  className={styles.alert}>
                                  <CloseIcon />
                                </Button>
                                <Button
                                  onClick={() => handleNotesEditUpdate(row, 'accept')}
                                  className={styles.success}>
                                  <CheckIcon />
                                </Button>
                              </div>
                            </section>
                          )}
                        </StyledTableCell>
                        <StyledTableCell className={styles.dateCell}>
                          {!datesEditArray?.find((item) => item.id === row.id)?.id ? (
                            <section
                              className={clsx(
                                styles.datesBlock,
                                isCellEditable() && styles.pointer
                              )}
                              onClick={() => isCellEditable() && handleDatesEditUpdate(row)}>
                              {row?.pickupDate ? formatDate(row.pickupDate) : '\u2000'}
                            </section>
                          ) : (
                            <section className={styles.datesBlock}>
                              <DatePicker
                                name={row.id}
                                minDate={`${new Date().getFullYear()}-01-01T06:47:17.913Z`}
                                selectedDate={
                                  datesEditArray?.find((item) => item.id === row.id)?.value || null
                                }
                                handleDateChange={handleDateChange}
                                isMobile={true}
                                size="full"
                              />
                              <div className={styles.helpers}>
                                <Button
                                  onClick={() => handleDatesEditUpdate(row, 'decline')}
                                  className={styles.alert}>
                                  <img src={BasketIcon} alt="basket-icon" />
                                </Button>
                                <Button
                                  onClick={() => handleDatesEditUpdate(row, 'accept')}
                                  className={styles.success}>
                                  <CheckIcon />
                                </Button>
                              </div>
                            </section>
                          )}
                        </StyledTableCell>
                        <StyledTableCell className={styles.statusCell}>
                          {!statusEditArray?.find((item) => item.id === row.id) ? (
                            <section
                              className={clsx(
                                styles.statusBlock,
                                isNotOpenStatus() &&
                                  isCellEditable() &&
                                  row?.status?.displayName !== RECEIVED_AT_YARD_STATUS &&
                                  row?.status?.displayName !== RECEIVED_AT_JOB_SITE_STATUS &&
                                  styles.pointer
                              )}
                              onClick={() => {
                                if (
                                  isNotOpenStatus() &&
                                  isCellEditable() &&
                                  row?.status?.displayName !== RECEIVED_AT_YARD_STATUS &&
                                  row?.status?.displayName !== RECEIVED_AT_JOB_SITE_STATUS
                                )
                                  handleStatusEditUpdate(row);
                              }}>
                              {row?.status?.displayName || ''}
                            </section>
                          ) : (
                            <section className={styles.statusBlock}>
                              <SelectInput
                                name={row.id}
                                value={
                                  statusEditArray?.find((item) => item.id === row.id)?.value.name ||
                                  ''
                                }
                                menuItems={enrichNameToId(picklistAssetStatuses) || []}
                                onSelect={(value) => onStatusSelect(value)}
                              />

                              <div className={styles.helpers}>
                                <Button
                                  onClick={() => handleStatusEditUpdate(row, 'decline')}
                                  className={styles.alert}>
                                  <CloseIcon />
                                </Button>
                                <Button
                                  onClick={() => handleStatusEditUpdate(row, 'accept')}
                                  className={styles.success}>
                                  <CheckIcon />
                                </Button>
                              </div>
                            </section>
                          )}
                        </StyledTableCell>
                        <StyledTableCell className={styles.truckCell}>
                          {!truckEditArray?.find((item) => item.id === row.id) ? (
                            <section
                              className={clsx(
                                styles.truckBlock,
                                isCellEditable() && styles.pointer
                              )}
                              onClick={() => isCellEditable() && handleTruckEditUpdate(row)}>
                              <LinkComponent
                                path={`${ASSET_DETAILED_PATH}/${row.truck.id}`}
                                name={row?.truck?.drCode || '\u2000'}
                                isRedirectAvailable={row.truck?.id && row.truck?.id !== -1}
                              />
                            </section>
                          ) : (
                            <section className={styles.truckBlock}>
                              <SearchInputWithTags
                                displayName="drCode"
                                defaultInputValue={
                                  truckEditArray?.find((item) => item.id === row.id)?.value || {}
                                }
                                options={truckOrTrailerOptions}
                                multiple={false}
                                onInputChange={onTruckOrTrailerFilterChange}
                                onSelect={onTruckSelect}
                                index={row.id}
                                key={key}
                                specialView={true}
                                fullWidth={true}
                              />

                              <div className={styles.helpers}>
                                <Button
                                  onClick={() => handleTruckEditUpdate(row, 'decline')}
                                  className={styles.alert}>
                                  <img src={BasketIcon} alt="basket-icon" />
                                </Button>
                                <Button
                                  onClick={() => handleTruckEditUpdate(row, 'accept')}
                                  className={styles.success}>
                                  <CheckIcon />
                                </Button>
                              </div>
                            </section>
                          )}
                        </StyledTableCell>
                        <StyledTableCell className={styles.freightBillCell}>
                          <LinkComponent
                            path={`${BILL_OF_LADING_DETAILS_PATH}/${row?.billOfLading?.id}`}
                            name={row?.billOfLading?.freightBill || ''}
                          />
                        </StyledTableCell>
                        <StyledTableCell className={styles.loadCell}>
                          <section className={styles.loadFileSection}>
                            {row?.resource?.id ? (
                              <FileActionsPopper
                                asset={row}
                                onDelete={onDelete}
                                onDownload={onDownloadFile}
                                isUploadAvailable={isUploadAvailable}
                              />
                            ) : (
                              <>
                                {isUploadAvailable() && (
                                  <UploadFileButton onUploadFile={onUpload} picklistAsset={row} />
                                )}
                              </>
                            )}
                          </section>
                        </StyledTableCell>
                        <StyledTableCell className={styles.lastCell}>
                          {row?.status?.displayName !== RECEIVED_AT_JOB_SITE_STATUS &&
                            row?.status?.displayName !== RECEIVED_AT_YARD_STATUS &&
                            isDeleteAvailable() && (
                              <div
                                className={styles.imageBlock}
                                onClick={() => onRemoveRow(row.id)}>
                                <img src={RemoveIcon} alt="remove" />
                              </div>
                            )}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  ) : (
                    <StyledTableRow>
                      <StyledTableCell colSpan={10} className={styles.emptyResponseTable}>
                        {isPicklistCompleted()
                          ? 'No available items in the picklist.'
                          : isFilterEmpty()
                          ? 'Add items to the picklist.'
                          : 'No matches in the table.'}
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </section>
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  currentPicklist: selectSinglePicklistData(),
  picklistAssetStatuses: selectPicklistAssetStatuses(),
  userConfig: selectUserConfig(),
  picklistStatuses: selectStatusesData(),
  truckOrTrailers: selectTruckOrTrailers(),
  drCodes: selectDrCodes()
});

const mapDispatchToProps = {
  getPickListAction: getSinglePicklist,
  getPicklistAssetsCountingAction: getPicklistAssetsCounting,
  updatePickListAction: updatePicklist,
  updatePicklistAssetAction: updatePicklistAsset,
  getAssetsAction: getAssets,
  deletePicklistAction: deletePicklist,
  deleteItemsAction: deleteItems,
  checkBillOfLadingItemsAction: checkBillOfLadingItems,
  checkMatchingLocationAction: checkMatchingLocation,
  getPicklistAssetStatusesAction: getPicklistAssetStatuses,
  getStatusListAction: getStatuses,
  searchTruckOrTrailerAction: searchTruckOrTrailer,
  searchDrCodesAction: searchDrCodes,
  deleteFileFromAssetAction: deleteFileFromAsset,
  clearStateAction: clearPickListState
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(PicklistDetailed);
