export const tableHeadConfig = [
  { id: 1, label: 'Name', isSortable: true, parameter: 'name' },
  { id: 2, label: 'Location', isSortable: true, parameter: 'location.siteCode' },
  { id: 3, label: 'Sublocation', isSortable: true, parameter: 'sublocation.name' },
  { id: 4, label: 'Project Number', isSortable: true, parameter: 'project.jobNumber' },
  { id: 5, label: 'Status', isSortable: true, parameter: 'status.name' },
  { id: 6, label: 'POC Name', isSortable: false, parameter: null },
  { id: 7, label: 'Created by', isSortable: false, parameter: null },
  { id: 8, label: 'Creation date', isSortable: true, parameter: 'createdAtUtc' }
];
