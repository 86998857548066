import React, { createRef, useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import styles from './Calendar.module.scss';
import { MainButton } from 'components/StyledComponents';
import FastBackwardIco from 'assets/images/fastBackwardIco.svg';
import FastForwardIco from 'assets/images/fastForwardIco.svg';
import SetEmploymentPopup from 'components/SetEmploymentPopup/SetEmploymentPopup';
import { areDatesSame, dateTimeFormat, formatDate, removeCharacterZ } from 'helpers/AppHelpers';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { getTeamMemberCalendar } from 'actions/teamMemberActions';
import clsx from 'clsx';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import moment from 'moment';
import { months } from './helpers';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { selectCurrentTeamMemberData } from '../../../selectors';
import { useUserConfig } from 'hooks/useUserConfig';
import MoreButton from './MoreButton';
import downloadIcon from 'assets/images/pdf_download.svg';
import cancelActionIcon from 'assets/images/cancel.svg';
import { TransparentButton, BlueButton } from 'components/Buttons';

const FastBackButton = ({ text }) => (
  <section>
    <img src={FastBackwardIco} alt="" /> {text}
  </section>
);
const FastForwardButton = ({ text }) => (
  <section>
    {text} <img src={FastForwardIco} alt="" />
  </section>
);

const PAGE_PARAMS = {
  prev: 'prev',
  next: 'next'
};

function Calendar({ getTeamMemberCalendarAction, onCancelAction }) {
  const isMobile = useMobileViewport();
  const calendarRef = createRef();
  const containerRef = createRef();

  const teamMember = useSelector(selectCurrentTeamMemberData());

  const { isAdminUser, isPersonnelManagerUser } = useUserConfig();

  const [showUploadDate, setShowUploadDate] = useState('');

  const [openPopup, setOpenPopup] = useState(false);
  const [popupData, setPopupData] = useState({});
  const [eventState, setEventState] = useState([]);
  const [filter, setFilter] = useState({
    teamMemberId: teamMember.id,
    year: +new Date().getFullYear(),
    month: +new Date().getMonth() + 1
  });

  const shouldCellDisableForRoles = () => !(isAdminUser || isPersonnelManagerUser);

  const setTimeToDate = (date) => `${date}T12:00:00.000`;

  const changeDate = (date) => {
    let newDate = removeCharacterZ(date);
    newDate = formatDate(newDate);
    newDate = setTimeToDate(newDate);
    return newDate;
  };

  const enrichEventsNameToTitle = (events, isContractSCA) =>
    events?.map((item) => {
      return {
        ...item,
        title: item?.project?.jobNumber || item['employmentType']?.name || '',
        start: item?.start ? changeDate(item.start) : null,
        end: item?.end ? changeDate(item.end) : null,
        note: item?.note || null,
        backgroundColor:
          item['employmentType']?.name === 'Project'
            ? isContractSCA
              ? '#bfbfbf'
              : item['employmentType']?.color
            : item['employmentType']?.color
      };
    }) || [];

  const getEvents = (extraFilter, calendarApi, param) =>
    getTeamMemberCalendarAction(extraFilter || filter).then((res) => {
      setEventState(
        enrichEventsNameToTitle(res['teamMemberEmploymentStatuses'], res?.isContractSCA)
      );
      setFilter({
        ...filter,
        year: res.year,
        month: res.month
      });
      calendarApi && calendarApi[param]();
    });

  useEffect(() => {
    if (teamMember?.id && !teamMember?.deleted) {
      getEvents();
    }
  }, [teamMember?.id]);

  const isFilterMonthEqualTo = (number) => filter.month === number;

  const getFilter = (param) => {
    let obj = { ...filter };
    if (param === PAGE_PARAMS.next) {
      if (isFilterMonthEqualTo(12)) {
        obj = { ...obj, month: 1, year: filter.year + 1 };
      } else {
        obj = { ...obj, month: filter.month + 1, year: filter.year };
      }
    } else {
      if (isFilterMonthEqualTo(1)) {
        obj = { ...obj, month: 12, year: filter.year - 1 };
      } else {
        obj = { ...obj, month: filter.month - 1, year: filter.year };
      }
    }
    return obj;
  };

  const onChangePage = (param) => {
    const calendarApi = calendarRef.current.getApi();
    const extraFilter = getFilter(param);
    getEvents(extraFilter, calendarApi, param);
  };

  const getMonthTitle = () => months[filter.month - 1].long;

  const isOther = (day) => (day.isOther ? styles.otherMonthDayCell : styles.currentMonthDayCell);

  const getEventStyle = (e) =>
    clsx(styles.event_block, !!e.event._def.extendedProps.note && styles.described);

  const setSchedule = () => {
    setOpenPopup(true);
    setPopupData({
      start: formatDate(new Date()),
      end: formatDate(new Date()),
      isSendNotification: false,
      teamMember: { ...teamMember }
    });
  };

  const findNeedEvent = (event, selectedDay) => {
    const startDate = formatDate(event.start);
    const endDate = event?.end ? formatDate(event.end) : '';
    const selectedDate = formatDate(selectedDay);

    if (endDate) {
      return selectedDate >= startDate && selectedDate <= endDate;
    } else return areDatesSame(selectedDate, startDate);
  };

  const availableStatusesForUsers = ['Available', 'Unavailable', 'Avail', 'Confirm'];
  const isCellClickUnavailable = (title) =>
    shouldCellDisableForRoles() && title && !availableStatusesForUsers.includes(title);

  const onDayClick = (info) => {
    const selectedDay = formatDate(info.date);

    const needEvent = eventState.find((el) => findNeedEvent(el, selectedDay));

    if (isCellClickUnavailable(needEvent?.title || '')) {
      return;
    }

    let data = {};

    if (needEvent?.id) {
      data = {
        ...needEvent,
        start: formatDate(needEvent.start),
        end: needEvent?.end ? formatDate(needEvent.end) : selectedDay,
        teamMember: { ...teamMember }
      };
    } else {
      data = {
        start: selectedDay,
        end: selectedDay,
        isSendNotification: false,
        teamMember: { ...teamMember }
      };
    }
    setPopupData(data);
    setOpenPopup(true);
  };

  const getRange = (event) => {
    return {
      startRange: formatDate(event.event._instance.range.start),
      endRange: formatDate(event.event._instance.range.end)
    };
  };
  const handleEventClick = (e) => {
    const { startRange, endRange } = getRange(e);

    const needEvent = eventState.find(
      ({ start, end }) => formatDate(start) === startRange && formatDate(end) === endRange
    );

    if (isCellClickUnavailable(needEvent?.title || '')) {
      return;
    }

    setPopupData({
      ...needEvent,
      start: formatDate(needEvent.start),
      end: formatDate(needEvent.end),
      teamMember
    });
    setOpenPopup(true);
  };

  const getMonthName = (param) => {
    let index;
    if (param === PAGE_PARAMS.next) {
      index = isFilterMonthEqualTo(12) ? 1 : filter.month + 1;
    } else {
      index = isFilterMonthEqualTo(1) ? 12 : filter.month - 1;
    }
    return months[index - 1].short;
  };

  const getPdfName = () =>
    `${
      teamMember?.firstName +
      ' ' +
      teamMember?.lastName +
      ' Schedule ' +
      moment(new Date()).format('LL')
    }.pdf`;

  const printDocument = () => {
    setTimeout(() => {
      html2canvas(containerRef.current, {
        scale: 5,
        useCORS: true,
        allowTaint: true
      }).then((canvas) => {
        const image = canvas.toDataURL('image/jpeg');
        const doc = new jsPDF({
          orientation: 'landscape',
          unit: 'px',
          format: 'a4'
        });

        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();

        const widthRatio = pageWidth / canvas.width;
        const heightRatio = pageHeight / canvas.height;

        const canvasWidth = canvas.width * widthRatio;
        const canvasHeight = canvas.height * heightRatio;

        const marginX = 0;
        const marginY = 0;

        doc.addImage(image, 'JPEG', marginX, marginY, canvasWidth, canvasHeight, null, 'SLOW');
        doc.save(getPdfName());
        setShowUploadDate('');
      });
    }, 1250);
  };

  const downloadPdf = () => {
    setShowUploadDate(formatDate(new Date(), dateTimeFormat));
  };

  const handleCancelAction = () => {
    onCancelAction(getEvents);
  };

  useEffect(() => {
    if (showUploadDate) {
      printDocument();
    }
  }, [showUploadDate]);

  return (
    <section className={styles.container}>
      <SetEmploymentPopup
        open={openPopup}
        setOpen={setOpenPopup}
        data={popupData}
        getEvents={getEvents}
        isDetailsScreen={true}
        isMobile={isMobile}
      />
      <div className={styles.titleBlock}>
        <h1>Working calendar</h1>
        {isMobile && (
          <MoreButton
            onDownload={downloadPdf}
            onSetSchedule={setSchedule}
            onCancelAction={handleCancelAction}
          />
        )}
      </div>
      <div className={styles.header}>
        {!isMobile && (
          <section className={styles.helpers}>
            <BlueButton label="Set schedule" onClick={setSchedule} />
            <TransparentButton label="Download" onClick={downloadPdf}>
              <img src={downloadIcon} alt="download_icon" />
            </TransparentButton>
            {(isAdminUser || isPersonnelManagerUser) && (
              <TransparentButton label="Cancel Action" onClick={handleCancelAction}>
                <img src={cancelActionIcon} alt="cancel_action" />
              </TransparentButton>
            )}
          </section>
        )}
        <div className={styles.switchers}>
          <MainButton
            text={<FastBackButton text={<span>{getMonthName(PAGE_PARAMS.prev)}</span>} />}
            type="primary"
            size="navigation"
            action={() => onChangePage(PAGE_PARAMS.prev)}
          />
          {isMobile && (
            <div className={styles.monthTitle}>{getMonthTitle() + ' ' + filter.year}</div>
          )}
          <MainButton
            text={<FastForwardButton text={<span>{getMonthName(PAGE_PARAMS.next)}</span>} />}
            type="primary"
            size="navigation"
            action={() => onChangePage(PAGE_PARAMS.next)}
          />
        </div>
      </div>
      <section className={styles.calendarSection} ref={containerRef}>
        {!!showUploadDate && <span>Upload date: {showUploadDate}</span>}
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          headerToolbar={{
            left: '',
            center: isMobile ? '' : 'title',
            right: ''
          }}
          firstDay={0}
          views={{
            dayGridMonth: {
              dayCellClassNames: isOther,
              dayHeaderClassNames: styles.dayHeader,
              titleFormat: { year: 'numeric', month: 'long' },
              dateClick: onDayClick
            }
          }}
          events={eventState}
          eventClassNames={getEventStyle}
          eventTextColor="black"
          eventBorderColor="#9C9C9C"
          eventClick={handleEventClick}
          displayEventTime={false}
          eventDisplay="block"
          contentHeight={isMobile ? 450 : 550}
        />
      </section>
    </section>
  );
}

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = {
  getTeamMemberCalendarAction: getTeamMemberCalendar
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(Calendar);
