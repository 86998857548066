import React, { useEffect, useState } from 'react';
import styles from './TeamMemberListFilter.module.scss';

import FilterWrapper from 'components/FilterComponents/FilterWrapper';
import AutocompleteWithSelectAll from 'components/AutocompleteWithSelectAll/AutocompleteWithSelectAll';
import TooltipSelectingDepartment from 'components/InfoTooltip/InfoTooltip';

import { useDispatch, useSelector } from 'react-redux';
import { selectTeamMemberFilter } from '../selectors';
import { getTeamMemberFilterCriteria } from 'actions/teamMemberActions';

import { cloneObj, isEqual, doNotDriveId, doDriveId, driveStatuses } from 'helpers/AppHelpers';
import { getValuesAfterChipDeleting, getChips } from './helpers';

import { Checkbox } from '@material-ui/core';

import { useUserConfig } from 'hooks/useUserConfig';

import clsx from 'clsx';

export default function TeamMemberListFilter({ isMobile, onApply }) {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({});

  const [initialOptions, setInitialOptions] = useState({});
  const [options, setOptions] = useState({});

  const [chips, setChips] = useState([]);

  const { isAdminUser, isPersonnelManagerUser } = useUserConfig();

  const filter = useSelector(selectTeamMemberFilter());

  const getFilterCriteria = (departmentIds) =>
    dispatch(getTeamMemberFilterCriteria({ departmentIds }));

  const returnValues = () => {
    setValues(filter.filters);
    if (!isEqual(filter.filters.teamMemberDepartmentIds, values['teamMemberDepartmentIds'])) {
      getFilterCriteria(filter.filters.teamMemberDepartmentIds).then((res) => setOptions(res));
    }
  };

  useEffect(() => {
    getFilterCriteria(filter.filters.teamMemberDepartmentIds || []).then((res) => {
      setInitialOptions(res);
      setOptions(res);
    });
  }, []);

  useEffect(() => {
    if (!filter.responseReceived) return;
    if (!initialOptions?.['clearanceTypes']?.length) return;

    const { doNotDrive, doDrive } = filter.filters;
    const transformedValues = cloneObj(filter.filters);

    transformedValues.driveStatusIds =
      doNotDrive && doDrive
        ? [doNotDriveId, doDriveId]
        : doNotDrive
        ? [doNotDriveId]
        : doDrive
        ? [doDriveId]
        : [];

    setValues(transformedValues);
    createChips(transformedValues);
  }, [filter, initialOptions]);

  const handleClearClick = () => {
    const defaultState = {
      ...values,
      teamMemberIds: [],
      teamMemberDepartmentIds: [],
      clearanceTypeIds: [],
      teamMemberLicenseIds: [],
      teamMemberCapacityIds: [],
      teamMemberStatusIds: [],
      teamMemberSkillsetIds: [],
      readOnlyTeamMemberDepartmentIds: [],
      showDeleted: false,
      driveStatusIds: [],
      doNotDrive: false,
      doDrive: false,
      dashboardId: '',
      dashboardField: '',
      dashboardType: '',
      dashboardDuration: null,
      dashboardProjectId: null
    };
    setValues(defaultState);
    isMobile && setChips([]);
    getFilterCriteria([]).then((res) => setOptions(res));
  };

  const handleBackClick = () => {
    isMobile && createChips(filter.filters);
    setOpen(false);
    returnValues();
  };

  const handleIconClick = (value) => {
    setOpen(value || !open);
    open && returnValues();
  };

  const handleDepartmentSelect = (name, value) => {
    getFilterCriteria(value || []).then((res) => {
      setOptions(res);
      const resetState = {
        ...values,
        teamMemberDepartmentIds: [...value],
        teamMemberIds: [],
        clearanceTypeIds: [],
        teamMemberLicenseIds: [],
        teamMemberCapacityIds: [],
        teamMemberSkillsetIds: []
      };
      setValues(resetState);
      isMobile && createChips(resetState);
    });
  };

  const handleSelect = (name, value) => {
    const updatedValues = { ...values, [name]: [...value] };
    setValues(updatedValues);
    isMobile && createChips(updatedValues);
  };

  const handleDriveStatusSelect = (name, value) => {
    const updatedValues = {
      ...values,
      [name]: value,
      doDrive: value.includes(doDriveId),
      doNotDrive: value.includes(doNotDriveId)
    };
    setValues(updatedValues);
    isMobile && createChips(updatedValues);
  };
  const handleCheck = (e) => {
    const name = e.target.name;
    const updatedValues = { ...values, [name]: !values[name] };
    setValues(updatedValues);
    isMobile && createChips(updatedValues);
  };

  const handleClickApply = () => {
    onApply(values);
    createChips(values);
    setOpen(false);
  };

  const handleChipDelete = (chip) => {
    const newChipsArray = chips.filter(({ chipId }) => chipId !== chip.chipId);
    setChips(newChipsArray);
    const newValues = getValuesAfterChipDeleting(isMobile ? values : filter.filters, chip);
    setValues(newValues);
    if (!isMobile) {
      onApply(newValues);
    }
  };

  const createChips = (inputFilter) => {
    const chipsArray = getChips(inputFilter, initialOptions);
    setChips(chipsArray);
  };

  const isFilterActive = () =>
    open ||
    !!filter?.filters?.teamMemberIds?.length ||
    !!filter?.filters?.teamMemberDepartmentIds?.length ||
    !!filter?.filters?.clearanceTypeIds?.length ||
    !!filter?.filters?.teamMemberLicenseIds?.length ||
    !!filter?.filters?.driveStatusIds?.length ||
    !!filter?.filters?.teamMemberCapacityIds?.length ||
    !!filter?.filters?.teamMemberStatusIds?.length ||
    !!filter?.filters?.teamMemberSkillsetIds?.length ||
    !!filter?.filters?.readOnlyTeamMemberDepartmentIds?.length ||
    !!filter?.filters?.showDeleted ||
    // TM Dashboard
    !!filter?.filters?.dashboardId;

  return (
    <FilterWrapper
      isMobile={isMobile}
      open={open}
      transparentBackDrop={false}
      onClick={handleIconClick}
      onApply={handleClickApply}
      onCancel={handleBackClick}
      onClear={handleClearClick}
      onBack={handleBackClick}
      chips={chips}
      disableSeparateChipDelete={(chip) => chip.key === 'department'}
      onChipDelete={handleChipDelete}
      isFilterActive={isFilterActive}>
      <section className={styles.wrapper}>
        <div className={styles.block}>
          <div className={styles.block__cell}>
            <div className={styles.block__cell_department}>
              Department
              <TooltipSelectingDepartment
                text="By selecting Department, lists of Capacity, Team Member, Clearance, Trainings &
        Certifications, Skillset will be narrowed."
              />
            </div>
            <div className={styles.block__cell_input}>
              <AutocompleteWithSelectAll
                name="teamMemberDepartmentIds"
                label="name"
                value={values?.['teamMemberDepartmentIds'] || []}
                options={options?.['teamMemberDepartments'] || []}
                onSelect={handleDepartmentSelect}
                selectAll={false}
                disableByObjectTracker={true}
              />
            </div>
          </div>
          <div className={styles.block__cell}>
            <label className={styles.block__cell_label}>Team member</label>
            <div className={styles.block__cell_input}>
              <AutocompleteWithSelectAll
                name="teamMemberIds"
                label="lastName"
                labelType="teamMember"
                value={values?.['teamMemberIds'] || []}
                options={options?.['teamMembers'] || []}
                onSelect={handleSelect}
                selectAll={false}
                disableByObjectTracker={true}
              />
            </div>
          </div>
          <div className={styles.block__cell}>
            <label className={styles.block__cell_label}>Clearance</label>
            <div className={styles.block__cell_input}>
              <AutocompleteWithSelectAll
                name="clearanceTypeIds"
                label="name"
                value={values?.['clearanceTypeIds'] || []}
                options={options?.['clearanceTypes'] || []}
                onSelect={handleSelect}
                selectAll={false}
                disableByObjectTracker={true}
              />
            </div>
          </div>
          <div className={styles.block__cell}>
            <label className={styles.block__cell_label}>Trainings & Certifications</label>
            <div className={styles.block__cell_input}>
              <AutocompleteWithSelectAll
                name="teamMemberLicenseIds"
                label="name"
                value={values?.['teamMemberLicenseIds'] || []}
                options={options?.['teamMemberLicenses'] || []}
                onSelect={handleSelect}
                selectAll={false}
                disableByObjectTracker={true}
              />
            </div>
          </div>
          <div className={styles.block__cell}>
            <label className={styles.block__cell_label}>Drive Status</label>
            <div className={styles.block__cell_input}>
              <AutocompleteWithSelectAll
                name="driveStatusIds"
                label="displayName"
                value={values?.['driveStatusIds'] || []}
                options={driveStatuses || []}
                onSelect={handleDriveStatusSelect}
                selectAll={false}
                disableByObjectTracker={true}
              />
            </div>
          </div>
        </div>
        <div className={styles.block}>
          <div className={styles.block__cell}>
            <label className={styles.block__cell_label}>Capacity</label>
            <div className={styles.block__cell_input}>
              <AutocompleteWithSelectAll
                name="teamMemberCapacityIds"
                label="name"
                value={values?.['teamMemberCapacityIds'] || []}
                options={options?.['teamMemberCapacities'] || []}
                onSelect={handleSelect}
                selectAll={false}
                disableByObjectTracker={true}
              />
            </div>
          </div>
          <div className={styles.block__cell}>
            <label className={styles.block__cell_label}>Employment</label>
            <div className={styles.block__cell_input}>
              <AutocompleteWithSelectAll
                name="teamMemberStatusIds"
                label="name"
                value={values?.['teamMemberStatusIds'] || []}
                options={options?.['teamMemberStatuses'] || []}
                onSelect={handleSelect}
                selectAll={false}
                disableByObjectTracker={true}
              />
            </div>
          </div>
          <div className={styles.block__cell}>
            <label className={styles.block__cell_label}>Skillset</label>
            <div className={styles.block__cell_input}>
              <AutocompleteWithSelectAll
                name="teamMemberSkillsetIds"
                label="name"
                value={values?.['teamMemberSkillsetIds'] || []}
                options={options?.['teamMemberSkillsets'] || []}
                onSelect={handleSelect}
                selectAll={false}
                disableByObjectTracker={true}
              />
            </div>
          </div>
          <div className={styles.block__cell}>
            <label className={styles.block__cell_label}>Read-only Schedules</label>
            <div className={styles.block__cell_input}>
              <AutocompleteWithSelectAll
                name="readOnlyTeamMemberDepartmentIds"
                label="name"
                value={values?.['readOnlyTeamMemberDepartmentIds'] || []}
                options={options?.['readOnlyTeamMemberDepartments'] || []}
                onSelect={handleSelect}
                selectAll={true}
                disableByObjectTracker={true}
              />
            </div>
          </div>
          {(isAdminUser || isPersonnelManagerUser) && (
            <div className={clsx(styles.block__cell, styles.check)}>
              <label className={styles.block__cell_label}>Show removed Team Members</label>
              <div className={styles.block__cell_input}>
                <Checkbox
                  classes={{ root: styles.checkbox, checked: styles.checked }}
                  onChange={handleCheck}
                  name="showDeleted"
                  checked={!!values?.['showDeleted']}
                />
              </div>
            </div>
          )}
        </div>
      </section>
    </FilterWrapper>
  );
}
