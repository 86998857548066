import {
  SET_LEAVE_LIST,
  SET_LOCATIONS_FOR_LEAVE_LIST,
  SET_STATUSES_FOR_LEAVE_LIST,
  SET_PROJECTS_FOR_LEAVE_LIST,
  SET_LEAVE_LIST_FILTER,
  SET_CURRENT_LEAVE_LIST,
  CLEAR_LEAVE_LIST_STATE,
  SET_LEAVE_LIST_STATUSES,
  SET_TRUCK_OR_TRAILERS,
  RESET_LEAVE_LIST_FILTER
} from 'constants/reduceConstants';
import { getClearedFilterByUnmount } from '../helpers/AppHelpers';

const defaultFilter = {
  filters: {
    searchQuery: '',
    locationIds: [],
    locationJobNumberIds: [],
    statusNames: [],
    projectIds: [],
    createdFrom: null,
    createdTo: null
  },
  pagination: {
    page: 1,
    totalPages: 1,
    limit: 10
  },
  sortRules: [],
  responseReceived: false
};

const initialState = {
  leaveList: [],
  locationList: [],
  statusList: [],
  projectList: [],
  leaveListFilter: { ...defaultFilter },
  currentLeaveList: {},
  leaveListAssetStatuses: [],
  truckOrTrailers: []
};

const leaveListReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LEAVE_LIST:
      return {
        ...state,
        leaveList: payload
      };
    case SET_LOCATIONS_FOR_LEAVE_LIST:
      return {
        ...state,
        locationList: payload
      };
    case SET_STATUSES_FOR_LEAVE_LIST:
      return {
        ...state,
        statusList: payload
      };
    case SET_PROJECTS_FOR_LEAVE_LIST:
      return {
        ...state,
        projectList: payload
      };
    case SET_CURRENT_LEAVE_LIST:
      return { ...state, currentLeaveList: payload };
    case SET_LEAVE_LIST_STATUSES:
      return { ...state, leaveListAssetStatuses: payload };
    case SET_LEAVE_LIST_FILTER:
      return {
        ...state,
        leaveListFilter: payload
      };
    case RESET_LEAVE_LIST_FILTER:
      return {
        ...state,
        leaveListFilter: {
          ...state.leaveListFilter,
          filters: { ...defaultFilter.filters, ...payload }
        }
      };
    case SET_TRUCK_OR_TRAILERS:
      return { ...state, truckOrTrailers: payload };
    case CLEAR_LEAVE_LIST_STATE:
      return { ...initialState, leaveListFilter: getClearedFilterByUnmount(state.leaveListFilter) };
    default:
      return state;
  }
};

export default leaveListReducer;
