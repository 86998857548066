import React from 'react';
import styles from './StaffingLevelsAvailability.module.scss';

import LevelsTable from './LevelsTable';
import LevelsBar from './LevelsBar';
import AvailabilityTable from './AvailabilityTable';
import AvailabilityCharts from './AvailabilityCharts';

export default function StaffingLevels({ data }) {
  return (
    <div className={styles.section}>
      <LevelsTable
        dashboard={data?.staffingLevelsDashboard || {}}
        dashboardId={data.id}
        department={data?.department || {}}
      />
      <LevelsBar
        dashboard={data?.staffingLevelsDashboard || {}}
        department={data?.department || {}}
      />

      <AvailabilityTable
        dashboard={data?.availabilityDashboard || {}}
        dashboardId={data.id}
        department={data?.department || {}}
      />

      <AvailabilityCharts dashboard={data?.availabilityDashboard || {}} />
    </div>
  );
}
