import React, { useEffect, useState } from 'react';
import styles from './Filter.module.scss';

import FilterWrapper from 'components/FilterComponents/FilterWrapper';

import { FormLabel } from 'components/FormComponents';
import FormAutoMultiSelect from 'components/FormComponents/FormAutoMultiSelect/FormAutoMultiSelect';
import FormCheckbox from 'components/FormComponents/FormCheckbox/FormCheckbox';
import FormMultiSelect from 'components/FormComponents/FormMultiSelect/FormMultiSelect';

import { getChips, getValuesAfterChipDeleting } from './helpers';

import { useTrainingsActions, useTrainingsSelector } from 'hooks/Trainings';
import { useMobileViewport } from 'hooks/useMobileViewport';

import { FormProvider, useForm } from 'react-hook-form';

import clsx from 'clsx';

export default function Filter({ onApply }) {
  const isMobile = useMobileViewport();
  const methods = useForm({
    defaultValues: { externalTrainingIds: [], groupIds: [], sourceIds: [], showAvailable: false },
    mode: 'onChange'
  });
  const { getValues, reset, setValue } = methods;

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState({});
  const [chips, setChips] = useState([]);

  const { filter } = useTrainingsSelector();
  const { getFilterCriteriaAction } = useTrainingsActions();

  useEffect(() => {
    getFilterCriteriaAction().then((res) => setOptions(res));
  }, []);

  useEffect(() => {
    if (open) {
      reset(filter.filters);
      isMobile && createChips(filter.filters);
    }
  }, [open]);

  useEffect(() => {
    if (options?.['externalTrainings']?.length) {
      reset(filter.filters);
      createChips(filter.filters);
    }
  }, [filter, options]);

  const { externalTrainings, groupTrainings, source } = options;

  const handleClearClick = () => {
    const defaultState = {
      ...getValues(),
      externalTrainingIds: [],
      groupIds: [],
      sourceIds: [],
      showAvailable: false
    };
    reset(defaultState);
    isMobile && setChips([]);
  };

  const handleBackClick = () => {
    isMobile && createChips(filter.filters);
    setOpen(false);
  };
  const handleIconClick = (value) => setOpen(value || !open);

  const checkChips = (name, value) => isMobile && createChips({ ...getValues(), [name]: value });

  const handleMultiSelect = (name, value) => {
    setValue(name, value);
    checkChips(name, value);
  };

  const handleClickApply = () => {
    const values = getValues();
    onApply(values);
    createChips(values);
    setOpen(false);
  };

  const handleChipDelete = (chip) => {
    const newChipsArray = chips.filter(({ chipId }) => chipId !== chip.chipId);
    setChips(newChipsArray);
    const newValues = getValuesAfterChipDeleting(getValues(), chip);
    reset(newValues);
    if (!isMobile) {
      onApply(newValues);
    }
  };

  const createChips = (inputFilter) => {
    const chipsArray = getChips(inputFilter, options);
    setChips(chipsArray);
  };

  return (
    <FilterWrapper
      isMobile={isMobile}
      open={open}
      transparentBackDrop={false}
      onClick={handleIconClick}
      onApply={handleClickApply}
      onCancel={handleBackClick}
      onClear={handleClearClick}
      onBack={handleBackClick}
      chips={chips}
      onChipDelete={handleChipDelete}>
      <FormProvider {...methods}>
        <section className={styles.wrapper}>
          <div className={styles.block}>
            <div className={styles.block__cell}>
              <FormLabel>Training & Certifications</FormLabel>
              <FormAutoMultiSelect
                name="externalTrainingIds"
                menuItems={externalTrainings || []}
                options={{
                  label: 'name',
                  disableByObjectTracker: true,
                  disableLabel: true,
                  extraAction: checkChips
                }}
              />
            </div>
            <div className={styles.block__cell}>
              <FormLabel>Group</FormLabel>
              <FormAutoMultiSelect
                name="groupIds"
                menuItems={groupTrainings || []}
                options={{
                  label: 'name',
                  disableByObjectTracker: true,
                  disableLabel: true,
                  extraAction: checkChips
                }}
              />
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.block__cell}>
              <FormLabel>Source</FormLabel>
              <FormMultiSelect
                name="sourceIds"
                options={source || []}
                onSelect={handleMultiSelect}
                trackById
              />
            </div>
            <div className={clsx(styles.block__cell, styles.checkbox)}>
              <FormLabel>Available only</FormLabel>
              <FormCheckbox name="showAvailable" onSelectTriggered={checkChips} />
            </div>
          </div>
        </section>
      </FormProvider>
    </FilterWrapper>
  );
}
