import { useState, useCallback, useMemo, useEffect } from 'react';
import axios from 'axios';
import store from '../store';
import { TOKEN_KEY } from 'constants/commonConstants';
import { SET_IS_LOGGED_IN } from '../constants/reduceConstants';
import { captureException, withScope } from '@sentry/react';
import { dateTimeFormat, formatDate, serializeError } from '../helpers/AppHelpers';

const instances = {
  // New prod:
  production: {
    backendUrl: 'https://api-production.deployedresources.com/',
    url: 'https://drat2.deployedresources.com'
  },
  // Old prod:
  prod: {
    backendUrl: 'https://api.deployedresources.com/',
    url: 'https://cp.deployedresources.com'
  },
  stage: {
    backendUrl: 'https://api-stage.deployedresources.com/',
    url: 'https://cp-stage.deployedresources.com'
  },
  dev: {
    backendUrl: 'https://api-dev.deployedresources.com/',
    url: 'https://cp-dev.deployedresources.com'
  },
  qa: {
    backendUrl: 'https://api-qa.deployedresources.com/',
    url: 'https://cp-qa.deployedresources.com'
  },
  local: {
    backendUrl: 'https://api-dev.deployedresources.com/',
    url: 'http://localhost:3000'
  }
};
export const env = process.env.REACT_APP_ENVIRONMENT || 'local';

export const API_BASE_ADDRESS = instances[env].backendUrl;
export const BASE_ADDRESS = instances[env].url;

export const getToken = () => {
  return localStorage.getItem(TOKEN_KEY);
};

const ignoredUrls = ['/User/Search', '/Asset/Search'];

const axiosInstance = axios.create({
  baseURL: API_BASE_ADDRESS,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response) {
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

const logoutFromSystem = () => {
  localStorage.removeItem(TOKEN_KEY);
  store.dispatch({
    type: SET_IS_LOGGED_IN,
    payload: false
  });
};

export const useAxiosLoader = () => {
  const [counter, setCounter] = useState(0);
  const inc = useCallback(() => setCounter((counter) => counter + 1), [setCounter]);
  const dec = useCallback(() => setCounter((counter) => counter - 1), [setCounter]);

  const isUrlIgnored = (url) => ignoredUrls.some((ignoredUrl) => url.includes(ignoredUrl));

  const interceptors = useMemo(
    () => ({
      request: (config) => {
        !isUrlIgnored(config.url) && inc();
        if (getToken()) {
          config.headers.authorization = `Bearer ${getToken() === 'undefined' ? '' : getToken()}`;
        }
        return config;
      },
      response: (response) => {
        counter >= 0 && dec();
        return response;
      },
      error: async (error) => {
        const status = error?.response?.status;
        const shouldLogout = status === 401;

        counter >= 0 && dec();

        if (shouldLogout) {
          logoutFromSystem();
        } else {
          withScope((scope) => {
            const errObj = error?.request?.response ? JSON.parse(error?.request?.response) : {};
            scope.setExtra('error', serializeError(errObj));
            scope.setExtra('env', process.env.REACT_APP_ENVIRONMENT || 'undefined');
            scope.setExtra('time', formatDate(new Date(), dateTimeFormat));
            scope.setTag('status', status);
            captureException(error);
          });
        }

        return Promise.reject(error);
      }
    }),
    [inc, dec]
  );

  useEffect(() => {
    const reqInterceptor = axiosInstance.interceptors.request.use(
      interceptors.request,
      interceptors.error
    );
    const resInterceptor = axiosInstance.interceptors.response.use(
      interceptors.response,
      interceptors.error
    );
    return () => {
      axiosInstance.interceptors.request.eject(reqInterceptor);
      axiosInstance.interceptors.response.eject(resInterceptor);
    };
  }, [interceptors]);

  counter < 0 && setCounter(0);
  return [counter > 0];
};

export default {
  get: (url, config) => axiosInstance.get(url, config),
  post: (url, data, config) => axiosInstance.post(url, data, config),
  put: (url, data, config) => axiosInstance.put(url, data, config),
  delete: (url, config) => axiosInstance.delete(url, config),
  patch: (url, data, config) => axiosInstance.patch(url, data, config)
};
