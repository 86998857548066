import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import styles from './SetEmploymentPopup.module.scss';
import { MainButton } from '../StyledComponents';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { selectCreationFormData } from './selectors';
import {
  checkClearance,
  clearState,
  createEvent,
  createMultipleEvents,
  deleteEvent,
  getCreationForm,
  updateEvent
} from 'actions/setEmploymentPopupActions';
import {
  allKeyboardLatSymbols,
  formatDate,
  getUserFullName,
  merge,
  reformatInputValue,
  sortByParam,
  getDifferenceInDays
} from 'helpers/AppHelpers';
import InputSearchWithMultiselect from '../InputSearchWithMultiselect/InputSearchWithMultiselect';
import { selectUserConfig } from 'pages/commonSelectors';
import DeleteIcon from '@material-ui/icons/Delete';
import { useUserConfig } from 'hooks/useUserConfig';
import clsx from 'clsx';
import StyledDatePicker from 'components/StyledDatePicker/StyledDatePicker';
import ConfirmationPopup from 'components/ConfirmationPopup/ConfirmationPopup';
import DialogWrapper from '../DialogComponents/DialogWrapper';
import DialogHeader from '../DialogComponents/DialogHeader';
import HeaderButton from '../DetailsComponents/HeaderButton';
import CustomCheckbox from '../CustomCheckbox/CustomCheckbox';

let key = new Date().getTime() + Math.random();

const CHECK_PROBLEM_CLEARANCE_TYPE = 'CHECK_PROBLEM_CLEARANCE_TYPE';
const STATUS_REWRITE_TYPE = 'STATUS_REWRITE_TYPE';

function ModalContent({ teamMembers, isProfileScreen, type }) {
  const {
    teamMembersWithEmptyClearance,
    teamMembersWithInactiveClearance,
    teamMembersWithExistedStatus
  } = teamMembers;

  const getString = (members) => members.map((el) => getUserFullName(el)).join(', ');

  const inactiveClearanceStr =
    teamMembersWithInactiveClearance?.length || teamMembersWithEmptyClearance?.length
      ? getString(
          sortByParam(
            merge(teamMembersWithInactiveClearance, teamMembersWithEmptyClearance),
            'lastName'
          )
        )
      : '';

  const rewriteStatusStr = teamMembersWithExistedStatus?.length
    ? getString(teamMembersWithExistedStatus)
    : '';

  return (
    <div className={styles.modal}>
      <div className={styles.modal__header}>
        {isProfileScreen
          ? type === STATUS_REWRITE_TYPE
            ? `Team member selected has existing scheduling statuses.`
            : `Team member doesn't have an active Clearance.`
          : type === STATUS_REWRITE_TYPE
          ? `Team member(s) selected has existing scheduling statuses.`
          : `Team member(s) doesn't have an active Clearance.`}
      </div>
      {!isProfileScreen && (
        <div className={styles.modal__content}>
          {inactiveClearanceStr && type !== STATUS_REWRITE_TYPE && (
            <div className={styles.modal__content_label}>
              <b>Team member(s) without clearance:</b> {inactiveClearanceStr}.
            </div>
          )}
          {rewriteStatusStr && type === STATUS_REWRITE_TYPE && (
            <div className={styles.modal__content_label}>
              <b>Team member(s) with existing scheduling statuses:</b> {rewriteStatusStr}.
            </div>
          )}
        </div>
      )}
      <div className={styles.modal__footer}>
        {type === STATUS_REWRITE_TYPE
          ? 'Do you want to rewrite status?'
          : 'Would you like to proceed?'}
      </div>
    </div>
  );
}

function SetEmploymentPopup({
  isMobile,
  open,
  setOpen,
  data,
  getEvents,
  creationFormData,
  getCreationFormAction,
  createEventAction,
  updateEventAction,
  deleteEventAction,
  clearStateAction,
  isDetailsScreen,
  createMultipleEventsAction,
  checkClearanceAction,
  userConfig
}) {
  const [validationErrors, setValidationErrors] = useState({});
  const [values, setValues] = useState({ isSendNotification: false });
  const { start, end } = values;

  const [dialogModalData, setDialogModalData] = useState({ isOpened: false });

  const { teamMember } = userConfig;
  const { isAdminUser, isPersonnelManagerUser, isTeamMemberUser } = useUserConfig();

  const isMultipleAllowed = () => 'teamMembers' in values;

  const calculateDayCount = () => {
    if (!start || !end) return 0;

    const count = getDifferenceInDays(start, end);
    return count < 0 ? count : count + 1;
  };

  useEffect(() => {
    if (open) {
      setValues({ ...data });
      getCreationFormAction();
    }
  }, [open]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  const { projects, employmentTypes, teamMembers } = creationFormData;

  const onClear = () => {
    setOpen(false);
    setValues({});
    setValidationErrors({});
    key = new Date().getTime() + Math.random();
  };

  const onSelect = (selectedItem, value) => {
    if (selectedItem === 'teamMembers') {
      validateForm('teamMember', '');
      setValues({
        ...values,
        [selectedItem]: [...value.map(({ id }) => id)]
      });
      return;
    }
    if (selectedItem?.name) {
      validateForm(selectedItem.name, '');
      setValues({
        ...values,
        [selectedItem.name]: selectedItem.value
      });
    } else {
      validateForm(selectedItem, '');
      setValues({
        ...values,
        [selectedItem]: value
      });
    }
  };
  const onEmploymentSelect = (name, value) => {
    validateForm(name, '');
    validateForm('project', '');
    setValues({ ...values, [name]: value });
  };

  const handleDateChange = (date, name) => {
    validateForm(name, '');
    validateForm('duration', '');
    setValues({ ...values, [name]: date ? formatDate(date) : null });
  };

  const clickCheckbox = (name, value) => {
    setValues({ ...values, [name]: value });
  };

  const validateForm = (key, newMessage) => {
    if (key) {
      setValidationErrors((state) => ({ ...state, [key]: newMessage }));
      return;
    }
    let isValidForm = true;
    if (isMultipleAllowed() && !values?.teamMembers?.length) {
      setValidationErrors((state) => ({
        ...state,
        teamMember: 'Team member field is required'
      }));
      isValidForm = false;
    }
    if (!isMultipleAllowed() && !values['teamMember']?.id) {
      setValidationErrors((state) => ({
        ...state,
        teamMember: 'Team member field is required'
      }));
      isValidForm = false;
    }
    if (!values['employmentType']?.name) {
      setValidationErrors((state) => ({
        ...state,
        employmentType: 'Status field is required'
      }));
      isValidForm = false;
    }
    if (values['employmentType']?.name === 'Project') {
      if (Object.keys(values?.project || {}).length === 0) {
        setValidationErrors((state) => ({
          ...state,
          project: 'Project field is required'
        }));
        isValidForm = false;
      }
    }
    if (!values.start) {
      setValidationErrors((state) => ({
        ...state,
        start: 'Start Date is required'
      }));
      isValidForm = false;
    }
    if (!values.end) {
      setValidationErrors((state) => ({
        ...state,
        end: 'End Date is required'
      }));
      isValidForm = false;
    }
    if (values.start && values.end && formatDate(values.start) > formatDate(values.end)) {
      setValidationErrors((state) => ({
        ...state,
        duration: 'Start date cannot be greater than End date'
      }));
      isValidForm = false;
    }
    return isValidForm;
  };

  const formIsInvalid = () => Object.values(validationErrors).filter(Boolean).length;

  const onCancel = () => onClear();

  const updateCalendarView = (response) => {
    if (response?.status === 200) {
      onClear();
      getEvents();
    }
  };

  const onDelete = () => {
    const query = { id: values.id, startDate: values.start, endDate: values.end };
    deleteEventAction(query).then((res) => updateCalendarView(res));
  };

  const isOwnProfile = () => teamMember?.id === values?.teamMember?.id;

  const onInput = (event) => {
    const key = event.target.name;
    const value = reformatInputValue(event.target.value, 255, allKeyboardLatSymbols);
    setValues({ ...values, [key]: value });
  };

  const enrichStatusOptions = (options, status) => {
    if (options?.length && status?.id && !options.map(({ id }) => id).includes(status.id)) {
      return options.concat({ ...status }).sort((a, b) => a.id > b.id);
    } else return options;
  };

  const enrichProjectOptions = (options, project) => {
    if (options?.length && project?.id && !options.map(({ id }) => id).includes(project.id)) {
      return options.concat({ ...project }).sort((a, b) => a.id > b.id);
    } else return options;
  };

  const createEvents = (excludedIds = []) => {
    if (isMultipleAllowed()) {
      const allowedMembers = values.teamMembers.filter((id) => !excludedIds.includes(id));

      if (!allowedMembers.length) {
        closeModal();
        return;
      }

      const data = {
        ...values,
        teamMembers: teamMembers.filter((el) => allowedMembers.includes(el.id))
      };
      createMultipleEventsAction(data).then((res) => updateCalendarView(res));
    } else {
      if (values?.id) {
        updateEventAction(values).then((res) => updateCalendarView(res));
      } else {
        createEventAction(values).then((res) => updateCalendarView(res));
      }
    }
    closeModal();
  };

  const agreeModal = () => {
    if (dialogModalData.type === CHECK_PROBLEM_CLEARANCE_TYPE) {
      if (
        dialogModalData.teamMembersObject?.['teamMembersWithExistedStatus']?.length &&
        isMultipleAllowed()
      ) {
        showStatusModal(dialogModalData.teamMembersObject);
        return;
      }
    }
    createEvents();
  };

  const dismissModal = () => {
    if (dialogModalData.type === STATUS_REWRITE_TYPE && isMultipleAllowed()) {
      const excludedIds = dialogModalData.teamMembersObject['teamMembersWithExistedStatus'].map(
        ({ id }) => id
      );
      createEvents(excludedIds);
      return;
    }
    closeModal();
  };

  const closeModal = () => setDialogModalData({ isOpened: false });

  const onApply = () => {
    if (!validateForm()) {
      return;
    }
    checkClearance();
  };

  const checkClearance = () => {
    const teamMemberIds = isMultipleAllowed() ? values.teamMembers : [values.teamMember.id];
    const startDate = values.start;
    const endDate = values.end;
    checkClearanceAction({ teamMemberIds, startDate, endDate }).then((res) => {
      if (res['displayWarningPopups']) {
        if (values.employmentType.name === 'Project') {
          if (
            res?.['teamMembersWithEmptyClearance']?.length ||
            res?.['teamMembersWithInactiveClearance']?.length
          ) {
            showClearanceModal(res);
          } else {
            if (res?.['teamMembersWithExistedStatus']?.length && isMultipleAllowed()) {
              showStatusModal(res);
            } else {
              createEvents();
            }
          }
        } else {
          if (res?.['teamMembersWithExistedStatus']?.length && isMultipleAllowed()) {
            showStatusModal(res);
          } else {
            createEvents();
          }
        }
      } else {
        createEvents();
      }
    });
  };

  const showClearanceModal = (teamMembersObject) => {
    setDialogModalData({
      isOpened: true,
      content: <ModalContent teamMembers={teamMembersObject} isProfileScreen={isDetailsScreen} />,
      agreeText: 'Yes',
      cancelText: 'No',
      type: CHECK_PROBLEM_CLEARANCE_TYPE,
      teamMembersObject
    });
  };

  const showStatusModal = (teamMembersObject) => {
    setDialogModalData({
      isOpened: true,
      content: (
        <ModalContent
          teamMembers={teamMembersObject}
          isProfileScreen={isDetailsScreen}
          type={STATUS_REWRITE_TYPE}
        />
      ),
      agreeText: 'Yes',
      cancelText: 'No',
      type: STATUS_REWRITE_TYPE,
      teamMembersObject
    });
  };

  const shouldDisableFields = isDetailsScreen
    ? isAdminUser || isPersonnelManagerUser
      ? false
      : !isTeamMemberUser
    : !isAdminUser && !isPersonnelManagerUser;

  return (
    <>
      <DialogWrapper open={open} onClose={onCancel}>
        <section className={styles.popup}>
          <DialogHeader onClose={onCancel} title="Set schedule">
            {values?.id && isMobile && (
              <HeaderButton onClick={onDelete}>
                <DeleteIcon />
              </HeaderButton>
            )}
          </DialogHeader>
          <section className={styles.form}>
            {!isDetailsScreen && teamMembers?.length && (
              <div className={styles.form__row}>
                <label>Team member*</label>
                <div className={styles.form__row_input}>
                  <InputSearchWithMultiselect
                    name={isMultipleAllowed() ? 'teamMembers' : 'teamMember'}
                    defaultValue={
                      isMultipleAllowed() ? values.teamMembers : values?.teamMember?.id || ''
                    }
                    options={teamMembers || []}
                    onFilterSelect={onSelect}
                    multiselect={isMultipleAllowed()}
                    isInvalid={!!validationErrors.teamMember}
                    refreshKey={key}
                    optionType="teamMember"
                    disabled={shouldDisableFields || !isMultipleAllowed()}
                  />
                  {!!validationErrors.teamMember && <span>{validationErrors.teamMember}</span>}
                </div>
              </div>
            )}
            <div className={styles.form__row}>
              <label>Status*</label>
              <div className={styles.form__row_input}>
                <InputSearchWithMultiselect
                  name="employmentType"
                  defaultValue={values?.employmentType?.id || ''}
                  options={enrichStatusOptions(employmentTypes, values['employmentType']) || []}
                  onFilterSelect={onEmploymentSelect}
                  multiselect={false}
                  refreshKey={key}
                  isInvalid={!!validationErrors.employmentType}
                  disabled={shouldDisableFields}
                />
                {!!validationErrors.employmentType && (
                  <span>{validationErrors.employmentType}</span>
                )}
              </div>
            </div>
            {values['employmentType']?.name === 'Project' && (
              <div className={styles.form__row}>
                <label>Project*</label>
                <div className={styles.form__row_input}>
                  <InputSearchWithMultiselect
                    name="project"
                    defaultValue={values?.project?.id || ''}
                    options={enrichProjectOptions(projects, values?.project) || []}
                    onFilterSelect={onSelect}
                    multiselect={false}
                    refreshKey={key}
                    isInvalid={!!validationErrors.project}
                    disabled={shouldDisableFields}
                  />
                  {!!validationErrors.project && <span>{validationErrors.project}</span>}
                </div>
              </div>
            )}
            <div className={styles.form__row}>
              <label>Duration*</label>
              <section className={styles.form__row_dates}>
                <div className={styles.dates_row}>
                  <div className={styles.dates_wrapper}>
                    {isMobile && <label>From</label>}
                    <StyledDatePicker
                      name="start"
                      value={values?.start || null}
                      onChange={handleDateChange}
                      isInvalid={!!validationErrors.duration || !!validationErrors.start}
                      isDisabled={shouldDisableFields}
                      customPositionClasses={!isMobile && styles.pickerFromPosition}
                    />
                    {!!validationErrors.start && <span>{validationErrors.start}</span>}
                  </div>
                  <div className={styles.dates_wrapper}>
                    {isMobile && <label>To</label>}
                    <StyledDatePicker
                      name="end"
                      value={values?.end || null}
                      onChange={handleDateChange}
                      isInvalid={!!validationErrors.duration || !!validationErrors.end}
                      isDisabled={shouldDisableFields}
                      customPositionClasses={!isMobile && styles.pickerToPosition}
                    />
                    {!!validationErrors.end && <span>{validationErrors.end}</span>}
                  </div>
                </div>
                {!!validationErrors.duration && <span>{validationErrors.duration}</span>}
              </section>
            </div>
            <div className={styles.form__row}>
              <label>Day Count</label>
              <div className={styles.form__row_dayCount}>
                <div
                  className={clsx(
                    styles.field,
                    calculateDayCount() < 0 && styles.redText,
                    shouldDisableFields && styles.disabled
                  )}>
                  <TextField variant="outlined" value={calculateDayCount()} />
                </div>
              </div>
            </div>
            <div className={styles.form__textarea}>
              <label>Note</label>
              <div
                className={clsx(
                  styles.form__textarea_input,
                  !(isAdminUser || isPersonnelManagerUser) && styles.disabled
                )}>
                <TextField
                  name="note"
                  multiline
                  rows={4}
                  variant="outlined"
                  value={values.note || ''}
                  onChange={onInput}
                  disabled={shouldDisableFields}
                />
                <span>{values.note ? values.note.length : 0}/255</span>
              </div>
            </div>
            {!isOwnProfile() && (
              <div className={styles.form__checkbox}>
                <label>Send notification</label>
                <div className={styles.form__checkbox_input}>
                  <CustomCheckbox
                    name="isSendNotification"
                    value={values.isSendNotification}
                    onChange={clickCheckbox}
                    isDisabled={shouldDisableFields}
                  />
                </div>
              </div>
            )}
          </section>
          <div className={styles.footer}>
            <div className={styles.footer__primary_row}>
              <MainButton text="Cancel" action={onCancel} type="secondary" size="popup" />
              {!shouldDisableFields && (
                <MainButton
                  text="Apply"
                  action={onApply}
                  type="primary"
                  size="popup"
                  isDisabled={!!formIsInvalid()}
                />
              )}
            </div>
            {values?.id && !isMobile && !shouldDisableFields && (
              <div className={styles.footer__extra_row}>
                <MainButton text="Delete" action={onDelete} type="extra" size="popup" />
              </div>
            )}
          </div>
        </section>
      </DialogWrapper>
      <ConfirmationPopup data={dialogModalData} onAgree={agreeModal} onDismiss={dismissModal} />
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  creationFormData: selectCreationFormData(),
  userConfig: selectUserConfig()
});

const mapDispatchToProps = {
  getCreationFormAction: getCreationForm,
  createEventAction: createEvent,
  updateEventAction: updateEvent,
  createMultipleEventsAction: createMultipleEvents,
  checkClearanceAction: checkClearance,
  deleteEventAction: deleteEvent,
  clearStateAction: clearState
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(SetEmploymentPopup);
