import React, { useEffect, useState } from 'react';
import styles from './TicketEditForm.module.scss';

import FormSearchInput from 'components/FormComponents/FormSearchInput/FormSearchInput';
import FormSelectInput from 'components/FormComponents/FormSelectInput/FormSelectInput';
import FormImagesUpload from 'components/FormComponents/FormImagesUpload/FormImagesUpload';
import FormDatePicker from 'components/FormComponents/FormDatePicker/FormDatePicker';
import FormTextArea from 'components/FormComponents/FormTextArea/FormTextArea_v2';
import FormVideosUpload from 'components/FormComponents/FormVideosUpload/FormVideosUpload';
import FormFilesUpload from 'components/FormComponents/FormFilesUpload/FormFilesUpload';
import FormCheckbox from 'components/FormComponents/FormCheckbox/FormCheckbox';
import FormServerAssetSearch from 'components/FormComponents/FormServerAssetSearch/FormServerAssetSearch';
import { FormServerUserSearch } from 'components/FormComponents';

import { useFormContext } from 'react-hook-form';
import { useTicketActions, useTicketSelector } from 'hooks/Ticket';
import { useUserConfig } from 'hooks/useUserConfig';
import { useParams } from 'react-router-dom';

import {
  dateTimeFormat,
  enrichNameToId,
  getDate,
  getUserFullName,
  startOfCurrentYear
} from 'helpers/AppHelpers';

import clsx from 'clsx';

import RequiredItems from './RequiredItems';
import ReopenTicketSettings from './ReopenTicketSettings';

export default function TicketEditForm() {
  const { id } = useParams();

  const { formState, clearErrors, watch, getValues, setValue } = useFormContext();
  const { errors } = formState;

  const { creationForm } = useTicketSelector();
  const { ticketTypes, rootCauses, severities } = creationForm;
  const { getCreationFromAction } = useTicketActions();

  const { isBasicRoleUser, isPersonnelManagerUser } = useUserConfig();

  const [severityOptions, setSeverityOptions] = useState([]);

  useEffect(() => {
    if (!severities?.length) return;
    if (!ticketTypes?.length) return;

    const typeId = getValues('ticketType')?.id;
    const selectedTypeSeverities = ticketTypes.find(({ id }) => id === typeId)?.severities || [];
    setSeverityOptions(selectedTypeSeverities?.length ? selectedTypeSeverities : severities);
  }, [severities, ticketTypes]);

  const resetTicketFrequencyValue = () => {
    setValue('reopenTicketFrequency', null);
    setValue('reopenOnSpecificDate', null);
  };

  const checkFieldError = (name) => errors[name]?.message && clearErrors(name);

  const selectAsset = (name, value) => {
    checkFieldError(name);
    setValue(name, value);
    value?.id && getCreationFromAction({ assetId: value.id });
  };

  const selectOption = (name, value) => setValue(name, value);

  const selectSeverity = ({ name, value }) => {
    setValue(name, value);
    checkFieldError(name);
  };

  const selectType = (name, value) => {
    setValue(name, value);

    selectSeverity({
      name: 'severity',
      value:
        value?.severities?.length === 1
          ? { id: value.severities[0].name, ...value.severities[0] }
          : {}
    });
    setSeverityOptions(value?.severities?.length ? value.severities : severities);
    resetTicketFrequencyValue();

    setValue('description', value?.description || '');
  };

  const assetWatcher = watch('asset');
  const assigneeUserWatcher = watch('assignedToUser');
  const assigneeDateWatcher = watch('assignedAtUtc');
  const ticketTypeWatcher = watch('ticketType');

  const conditions = {
    assigneeBlock: !isBasicRoleUser && !isPersonnelManagerUser,
    notificationReminder: !!assigneeUserWatcher?.id && !!assigneeDateWatcher,
    reopenSettings: !isBasicRoleUser && !isPersonnelManagerUser && !!ticketTypeWatcher?.reopening
  };

  return (
    <div className={styles.form}>
      <div className={styles.general}>
        <div className={styles.general__block}>
          <div className={styles.general__block_row}>
            <label>Asset*</label>
            <FormServerAssetSearch
              name="asset"
              max={100}
              onSelect={selectAsset}
              getLabel={(option) => option?.drCode || ''}
              isDisabled={!!id}
              getQuery={(searchQuery) => ({
                pagination: { limit: 39, page: 1 },
                filters: { isDeleted: false, searchQuery }
              })}
            />
          </div>
          {id && (
            <div className={styles.general__block_row}>
              <label>Opened By</label>
              <div className={styles.general__block_row_input}>
                {getUserFullName(getValues('openedByUser'))}
              </div>
            </div>
          )}
          {id && (
            <div className={styles.general__block_row}>
              <label>Opened On</label>
              <div className={styles.general__block_row_input}>
                {getDate(getValues('openedAtUtc'), dateTimeFormat)}
              </div>
            </div>
          )}
          <div className={styles.general__block_row}>
            <label>Ticket Type</label>
            <FormSearchInput
              name="ticketType"
              options={ticketTypes || []}
              onSelect={selectType}
              isDisabled={!assetWatcher?.id}
              clearable
            />
          </div>
          <div className={styles.general__block_row}>
            <label>Root Cause</label>
            <FormSearchInput
              clearable
              name="rootCause"
              options={rootCauses || []}
              onSelect={selectOption}
              isDisabled={!assetWatcher?.id}
            />
          </div>
          <div className={styles.general__block_row}>
            <label>Severity*</label>
            <FormSelectInput
              name="severity"
              options={enrichNameToId(severityOptions) || []}
              onSelect={selectSeverity}
            />
          </div>
          {!id && (
            <div className={clsx(styles.general__block_row, styles.checkbox)}>
              <label>Open</label>
              <FormCheckbox name="isOpened" />
            </div>
          )}
          {conditions.assigneeBlock && (
            <>
              <div className={styles.general__block_row}>
                <label>Assignee</label>
                <FormServerUserSearch
                  name="assignedToUser"
                  getLabel={(option) => getUserFullName(option)}
                  getQuery={(searchQuery) => ({
                    pagination: { limit: 39, page: 1 },
                    filters: { shouldIncludeCurrentUser: true, searchQuery }
                  })}
                  onSelect={selectOption}
                />
              </div>
              <div className={styles.general__block_row}>
                <label>Due Date</label>
                <FormDatePicker name="assignedAtUtc" min={startOfCurrentYear} placement="top" />
              </div>
            </>
          )}
          {conditions.notificationReminder && (
            <div className={clsx(styles.general__block_row, styles.checkbox)}>
              <label>Notification Reminder</label>
              <FormCheckbox name="notificationReminder" />
            </div>
          )}
          <div className={clsx(styles.general__block_row, styles.description)}>
            <label>Description*</label>
            <FormTextArea name="description" options={{ max: 1000 }} />
          </div>
        </div>

        <div className={styles.general__block}>
          <div className={clsx(styles.general__block_row, styles.alignTop)}>
            <label>Photo</label>
            <FormImagesUpload />
          </div>
          <div className={clsx(styles.general__block_row, styles.alignTop)}>
            <label>Video</label>
            <FormVideosUpload />
          </div>
          <div className={clsx(styles.general__block_row, styles.alignTop)}>
            <label>Files</label>
            <FormFilesUpload />
          </div>
        </div>
      </div>

      {conditions.reopenSettings && <ReopenTicketSettings />}

      <RequiredItems />

      {id && (
        <div className={styles.remarks}>
          <h3>Remarks*</h3>
          <FormTextArea name="remarksMessage" options={{ max: 1000 }} />
        </div>
      )}
    </div>
  );
}
