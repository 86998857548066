import React from 'react';
import styles from './components.module.scss';
import { useFormContext } from 'react-hook-form';
import FormDatePicker from 'components/FormComponents/FormDatePicker/FormDatePicker';

export default function GeneralDetails({ getWeather }) {
  const { getValues } = useFormContext();

  const data = [
    {
      id: 1,
      label: 'Location',
      value: getValues('project')?.id
        ? `${getValues('project')
            .locations.map(({ siteCode }) => siteCode)
            .join(', ')}`
        : `${getValues('location')?.siteCode}${
            getValues('location')?.project?.id
              ? ' (' + getValues('location')?.project?.name + ')'
              : ''
          }`
    },
    { id: 2, label: 'Report name', value: getValues('generalReportTemplate')?.name || '' }
  ];

  const handleDateClick = (_, date) => getWeather(date);

  return (
    <div className={styles.general}>
      {data.map((el) => (
        <section key={el.id} className={styles.general_details}>
          <label>{el.label}</label>
          <b>{el.value}</b>
        </section>
      ))}
      <section className={styles.general_details}>
        <label>Date*</label>
        <FormDatePicker name="createdAtUtc" isClearable={false} extraAction={handleDateClick} />
      </section>
    </div>
  );
}
