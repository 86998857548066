import React, { useEffect } from 'react';
import styles from './Filter.module.scss';

import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import { FilterActions } from 'components/FilterComponentsV2';
import FormAutoMultiSelect from 'components/FormComponents/FormAutoMultiSelect/FormAutoMultiSelect';
import { FormDayPicker, FormLabel } from 'components/FormComponents';

import { FormProvider, useForm } from 'react-hook-form';

import { useUserLogsSelector } from 'hooks/UserLogs';

import { enrichNameToId } from 'helpers/AppHelpers';

export default function Filter({ open, setOpen, options, onApply }) {
  const methods = useForm({
    defaultValues: {
      userIds: [],
      historyEventTypes: [],
      startDate: null,
      endDate: null
    },
    mode: 'onChange'
  });
  const { getValues, reset, watch } = methods;

  const { filter } = useUserLogsSelector();

  useEffect(() => {
    if (open) {
      reset(filter.filters);
    }
  }, [open]);

  const { users, eventTypes } = options;

  const closePopup = () => setOpen(false);

  const applyFilter = () => {
    onApply(getValues());
    closePopup();
  };

  const clearFilter = () => {
    const defaultState = {
      ...getValues(),
      startDate: null,
      endDate: null,
      userIds: [],
      historyEventTypes: []
    };
    reset(defaultState);
  };

  const startDateWatcher = watch('startDate');
  const endDateWatcher = watch('endDate');

  return (
    <DialogWrapper open={open} onClose={closePopup}>
      <div className={styles.dialog}>
        <FormProvider {...methods}>
          <div className={styles.form}>
            <div className={styles.form__block}>
              <div className={styles.form__block_cell}>
                <FormLabel>User</FormLabel>
                <FormAutoMultiSelect
                  name="userIds"
                  menuItems={users || []}
                  options={{
                    labelType: 'user',
                    disableByObjectTracker: true,
                    disableLabel: true
                  }}
                />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Action</FormLabel>
                <FormAutoMultiSelect
                  name="historyEventTypes"
                  menuItems={enrichNameToId(eventTypes) || []}
                  options={{
                    label: 'displayName',
                    disableByObjectTracker: true,
                    disableLabel: true
                  }}
                />
              </div>
            </div>
            <div className={styles.form__block}>
              <div className={styles.form__block_cell}>
                <FormLabel>Start Date</FormLabel>
                <FormDayPicker name="startDate" max={endDateWatcher || new Date()} />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>End Date</FormLabel>
                <FormDayPicker name="endDate" min={startDateWatcher || null} max={new Date()} />
              </div>
            </div>
          </div>
        </FormProvider>
        <FilterActions onClear={clearFilter} onCancel={closePopup} onApply={applyFilter} />
      </div>
    </DialogWrapper>
  );
}
