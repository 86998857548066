import React, { useEffect, useState } from 'react';
import styles from './LeaveListFilter.module.scss';
import { Backdrop, Chip, Fade, IconButton, Paper, Popper } from '@material-ui/core';
import FilterIcon from 'assets/images/filter.svg';
import InputSearchWithMultiselect from 'components/InputSearchWithMultiselect/InputSearchWithMultiselect';
import Multiselect from 'components/Multiselect/Multiselect';
import { enrichNameToId, formatDate, getRandomId, pluck } from 'helpers/AppHelpers';
import CloseIcon from '@material-ui/icons/Close';
import { MainButton } from 'components/StyledComponents';
import { createStructuredSelector } from 'reselect';
import { selectLocationsData, selectProjects, selectStatusesData } from '../selectors';
import { getLocations, getProjects, getStatuses } from 'actions/leaveListActions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import clsx from 'clsx';
import ClearFilterButton from 'components/ClearFilterButton/ClearFilterButton';
import FilterHeader from 'components/FilterComponents/FilterHeader';
import AutocompleteWithSelectAll from 'components/AutocompleteWithSelectAll/AutocompleteWithSelectAll';
import StyledDatePicker from '../../../../components/StyledDatePicker/StyledDatePicker';
import moment from 'moment';

function LeaveListFilter({
  isMobile,
  locationList,
  getLocationListAction,
  statusList,
  getStatusListAction,
  projectList,
  getProjectListAction,
  filter,
  onApply,
  openFilter,
  setOpenFilter
}) {
  const { filters } = filter;
  const [chips, setChips] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [values, setValues] = useState({ ...filters });
  const [key, setKey] = useState(new Date().getTime() + Math.random());

  const [sublocations, setSublocations] = useState([]);

  const handleFilterOpen = (event) => {
    setOpenFilter(!openFilter);
    setAnchorEl(event.currentTarget);
    setValues(filters);
    isMobile && createChips(filters);
  };

  const handleFilterClose = () => {
    isMobile && createChips(filters);
    setOpenFilter(false);
  };

  const applyFilter = () => {
    createChips(values);
    setOpenFilter(!openFilter);
    onApply(values);
  };

  const handleClearFilter = () => {
    const defaultState = {
      ...values,
      locationIds: [],
      locationJobNumberIds: [],
      sublocationIds: [],
      statusNames: [],
      projectIds: [],
      createdTo: null,
      createdFrom: null
    };
    setValues(defaultState);
    isMobile && setChips([]);
  };

  const onFilterSelect = (e, value) => {
    if (e === 'locationIds') {
      const locations = [...value.map(({ id }) => id)];
      if (values?.sublocationIds?.length) {
        const availableSublocations = value
          .flatMap(({ sublocations }) => sublocations)
          .map(({ id }) => id);
        const subValue = values.sublocationIds.filter((id) => availableSublocations.includes(id));
        setValues({ ...values, [e]: locations, sublocationIds: subValue });
        isMobile && createChips({ ...values, [e]: locations, sublocationIds: subValue });
      } else {
        setValues({ ...values, [e]: locations });
        isMobile && createChips({ ...values, [e]: locations });
      }
    } else {
      let currentFilter = {};
      if (e?.target) {
        currentFilter = {
          ...values,
          [e.target.name]: [...e.target.value]
        };
      } else {
        currentFilter = {
          ...values,
          [e]: [...value.map(({ id }) => id)]
        };
      }
      setValues(currentFilter);
      isMobile && createChips(currentFilter);
    }
  };

  const handleChipDelete = (item) => {
    const filterObj = {
      ...values,
      locationJobNumberIds:
        item.key === 'jobNumber'
          ? values.locationJobNumberIds.filter((id) => id !== item.id)
          : values.locationJobNumberIds,
      sublocationIds:
        item.key === 'sublocation'
          ? values.sublocationIds.filter((id) => id !== item.id)
          : values.sublocationIds,
      statusNames:
        item.key === 'status'
          ? values.statusNames.filter((name) => name !== item.name)
          : values.statusNames,
      projectIds:
        item.key === 'project'
          ? values.projectIds.filter((id) => id !== item.id)
          : values.projectIds
    };

    if (item.key === 'location') {
      filterObj.locationIds = values.locationIds.filter((id) => id !== item.id);

      const sublocations = locationList.find(({ id }) => id === item.id)?.sublocations;
      const subIds = pluck(sublocations, 'id');
      if (subIds?.length) {
        filterObj.sublocationIds =
          values.sublocationIds?.filter((id) => !subIds.includes(id)) || [];
      }
    }

    if (item.key === 'dateFromTo') {
      filterObj.createdFrom = null;
      filterObj.createdTo = null;
    }
    if (item.key === 'createdFrom') {
      filterObj.createdFrom = null;
    }
    if (item.key === 'createdTo') {
      filterObj.createdTo = null;
    }

    setValues(filterObj);
    setKey(new Date().getTime() + Math.random());
    setChips(
      chips.filter((chip) => {
        if (chip.key === item.key) {
          return chip.id !== item.id || chip.name !== item.name;
        } else {
          return true;
        }
      })
    );
    if (!isMobile) {
      onApply(filterObj);
    }
  };

  const createChips = (inputFilter, subs) => {
    const chipsArray = [];
    if (inputFilter?.locationIds?.length) {
      chipsArray.push(
        ...inputFilter.locationIds.map((item) => {
          return {
            ...locationList?.find((option) => option.id === item),
            key: 'location'
          };
        })
      );
    }
    if (inputFilter?.locationJobNumberIds?.length) {
      chipsArray.push(
        ...inputFilter.locationJobNumberIds.map((item) => {
          return {
            ...locationList?.find((option) => option.id === item),
            key: 'jobNumber'
          };
        })
      );
    }
    if (inputFilter?.sublocationIds?.length) {
      chipsArray.push(
        ...inputFilter.sublocationIds.map((item) => {
          if (sublocations?.length) {
            return {
              ...sublocations?.find((option) => option.id === item),
              key: 'sublocation'
            };
          }
          if (subs?.length) {
            return {
              ...subs?.find((option) => option.id === item),
              key: 'sublocation'
            };
          }
        })
      );
    }
    if (inputFilter?.statusNames?.length) {
      chipsArray.push(
        ...inputFilter.statusNames.map((item) => {
          return {
            ...statusList.find((option) => option.name === item),
            key: 'status'
          };
        })
      );
    }
    if (inputFilter?.projectIds?.length) {
      chipsArray.push(
        ...inputFilter.projectIds.map((item) => {
          return {
            ...projectList?.find((option) => option.id === item),
            key: 'project'
          };
        })
      );
    }

    let isTwoDatesSelected = false;
    if (inputFilter?.createdFrom && inputFilter?.createdTo) {
      isTwoDatesSelected = true;
      chipsArray.push({
        key: 'dateFromTo',
        displayName: 'Created ' + inputFilter.createdFrom + ' - ' + inputFilter.createdTo,
        id: getRandomId()
      });
    }
    if (inputFilter?.createdFrom && !isTwoDatesSelected) {
      chipsArray.push({
        key: 'createdFrom',
        displayName: 'Created from ' + inputFilter.createdFrom,
        id: getRandomId()
      });
    }
    if (inputFilter?.createdTo && !isTwoDatesSelected) {
      chipsArray.push({
        key: 'createdTo',
        displayName: 'Created to ' + inputFilter.createdTo,
        id: getRandomId()
      });
    }
    setChips(chipsArray);
  };

  const selectOption = (name, value) => {
    const updatedValues = { ...values, [name]: [...value] };
    setValues(updatedValues);
    isMobile && createChips(updatedValues);
  };

  const selectDate = (date, name) => {
    const updatedValues = { ...values, [name]: date ? formatDate(date) : null };
    setValues(updatedValues);
    isMobile && createChips(updatedValues);
  };

  useEffect(() => {
    getLocationListAction({
      filters: {
        active: true,
        includeUserCheck: true
      }
    });
  }, []);

  useEffect(() => {
    if (!statusList?.length) {
      getStatusListAction();
    }
  }, [statusList]);

  useEffect(() => {
    if (values?.locationIds.length && locationList?.length) {
      const sublocs = locationList
        ?.filter((item) => values.locationIds.includes(item.id))
        ?.flatMap(({ sublocations }) => sublocations);
      setSublocations(sublocs || []);
    }
  }, [values, locationList]);

  useEffect(() => {
    if (!projectList?.length) {
      getProjectListAction();
    }
  }, [projectList]);

  useEffect(() => {
    if (locationList?.length && statusList?.length && projectList?.length) {
      const subs = filter?.filters?.locationIds?.length
        ? locationList
            .filter(({ id }) => filter.filters.locationIds.includes(id))
            .flatMap(({ sublocations }) => sublocations)
        : [];
      createChips(filter.filters, subs);
      setValues({ ...filter.filters });
    }
  }, [filter, locationList, statusList, projectList]);

  return (
    <div className={styles.filterWrapper}>
      {!isMobile && <Backdrop open={openFilter} />}
      <IconButton
        className={clsx(
          styles.filterButton,
          !isMobile
            ? (openFilter || chips?.length) && styles.filterButton__opened
            : chips?.length && styles.filterButton__opened
        )}
        onClick={handleFilterOpen}
        component="span">
        <img src={FilterIcon} alt="" />
      </IconButton>

      <Popper
        open={openFilter}
        anchorEl={anchorEl}
        placement="right-start"
        transition
        className={styles.popper}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper classes={{ root: styles.filterContainer }}>
              {isMobile && <FilterHeader onBack={handleFilterClose} />}
              <section className={styles.formWrapper}>
                <section className={styles.formColumn}>
                  <section className={styles.formCell}>
                    <label>Location</label>
                    <div className={styles.inputWrapper}>
                      <InputSearchWithMultiselect
                        name="locationIds"
                        defaultValue={values?.locationIds || []}
                        options={locationList || []}
                        onFilterSelect={onFilterSelect}
                        multiselect={true}
                        refreshKey={key}
                      />
                    </div>
                  </section>
                  <section className={styles.formCell}>
                    <label>Sublocation</label>
                    <div className={styles.inputWrapper}>
                      <InputSearchWithMultiselect
                        name="sublocationIds"
                        defaultValue={values?.sublocationIds || []}
                        options={sublocations || []}
                        onFilterSelect={onFilterSelect}
                        multiselect={true}
                        refreshKey={key}
                        disabled={!values.locationIds?.length}
                      />
                    </div>
                  </section>
                  <section className={styles.formCell}>
                    <label>Job Number</label>
                    <div className={styles.inputWrapper}>
                      <AutocompleteWithSelectAll
                        name="locationJobNumberIds"
                        label="locationJobNumber"
                        value={values?.locationJobNumberIds || []}
                        options={locationList || []}
                        onSelect={selectOption}
                        selectAll={false}
                        disableByObjectTracker={true}
                      />
                    </div>
                  </section>
                </section>
                <section className={styles.formColumn}>
                  <section className={styles.formCell}>
                    <label>Status</label>
                    <div className={styles.inputWrapper}>
                      <Multiselect
                        name="statusNames"
                        valuesArray={values?.statusNames}
                        options={enrichNameToId(statusList) || []}
                        onFilterSelect={onFilterSelect}
                      />
                    </div>
                  </section>
                  <section className={styles.formCell}>
                    <label>Project</label>
                    <div className={styles.inputWrapper}>
                      <InputSearchWithMultiselect
                        name="projectIds"
                        defaultValue={values?.projectIds || []}
                        options={projectList || []}
                        onFilterSelect={onFilterSelect}
                        multiselect={true}
                        refreshKey={key}
                      />
                    </div>
                  </section>
                  <section className={styles.formCell}>
                    <label>Created from</label>
                    <div className={styles.inputWrapper}>
                      <StyledDatePicker
                        name="createdFrom"
                        value={values?.createdFrom || null}
                        onChange={selectDate}
                        max={values.createdTo || moment().add(3, 'year')}
                        placement={isMobile && 'top'}
                      />
                    </div>
                  </section>
                  <section className={styles.formCell}>
                    <label>Created to</label>
                    <div className={styles.inputWrapper}>
                      <StyledDatePicker
                        name="createdTo"
                        value={values?.createdTo || null}
                        onChange={selectDate}
                        min={values.createdFrom}
                        max={moment().add(3, 'year')}
                        placement={isMobile && 'top'}
                      />
                    </div>
                  </section>
                </section>
                {isMobile && (
                  <section className={styles.chipsBlock}>
                    {!!chips?.length &&
                      chips.map((item) => (
                        <Chip
                          classes={{ icon: styles.chipIcon }}
                          key={`${item.id}-${item.key}`}
                          label={
                            item.key === 'jobNumber'
                              ? item.locationJobNumber
                              : item?.jobNumber && item?.name
                              ? item.jobNumber + ' ' + item.name
                              : '' || item.siteCode || item.displayName || item.name || ''
                          }
                          deleteIcon={<CloseIcon />}
                          className={styles.fieldsChip}
                          onDelete={() => handleChipDelete(item)}
                        />
                      ))}
                  </section>
                )}
              </section>
              <section className={styles.buttonWrapper}>
                <ClearFilterButton onClick={handleClearFilter} />
                <div className={styles.primary}>
                  <MainButton
                    text="Cancel"
                    action={handleFilterClose}
                    type="secondary"
                    size="filter_popup"
                  />
                  <MainButton
                    text="Apply"
                    action={applyFilter}
                    type="primary"
                    size="filter_popup"
                  />
                </div>
              </section>
            </Paper>
          </Fade>
        )}
      </Popper>
      {!isMobile && (
        <section className={styles.chipsBlock}>
          {!!chips?.length &&
            chips.map((item) => (
              <Chip
                classes={{ icon: styles.chipIcon }}
                key={`${item.id}-${item.key}`}
                label={
                  item.key === 'jobNumber'
                    ? item.locationJobNumber
                    : item?.jobNumber && item?.name
                    ? item.jobNumber + ' ' + item.name
                    : '' || item.siteCode || item.displayName || item.name || ''
                }
                deleteIcon={<CloseIcon />}
                className={styles.fieldsChip}
                onDelete={() => handleChipDelete(item)}
              />
            ))}
        </section>
      )}
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  locationList: selectLocationsData(),
  statusList: selectStatusesData(),
  projectList: selectProjects()
});

const mapDispatchToProps = {
  getLocationListAction: getLocations,
  getStatusListAction: getStatuses,
  getProjectListAction: getProjects
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(LeaveListFilter);
