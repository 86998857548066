import React from 'react';
import styles from './TicketEditForm.module.scss';

import { useTicketSelector } from 'hooks/Ticket';

import { useFormContext } from 'react-hook-form';
import { FormDayPicker, FormLabel, FormSelectInput } from 'components/FormComponents';

export default function ReopenTicketSettings() {
  const { clearErrors, setValue, formState, watch } = useFormContext();
  const { errors } = formState;

  const { creationForm } = useTicketSelector();
  const { reopenTicketFrequencies } = creationForm;

  const selectOption = ({ name, value }) => {
    errors?.reopenOnSpecificDate?.message && clearErrors('reopenOnSpecificDate');
    setValue(name, value, { shouldDirty: true });
    setValue('reopenOnSpecificDate', null);
  };

  const frequencyWatcher = watch('reopenTicketFrequency');

  return (
    <div className={styles.reopenSettings}>
      <h3>Reopen ticket settings</h3>

      <div className={styles.reopenSettings__block}>
        <div className={styles.reopenSettings__block_row}>
          <FormLabel>Reopen Frequency</FormLabel>
          <FormSelectInput
            clearable
            name="reopenTicketFrequency"
            options={reopenTicketFrequencies || []}
            onSelect={selectOption}
          />
        </div>
      </div>
      <div className={styles.reopenSettings__block}>
        <div className={styles.reopenSettings__block_row}>
          <FormLabel>Reopen on specific date</FormLabel>
          <FormDayPicker
            name="reopenOnSpecificDate"
            min={new Date()}
            disabled={frequencyWatcher?.name !== 'OneTime'}
          />
        </div>
      </div>
    </div>
  );
}
