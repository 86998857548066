import { useSelector } from 'react-redux';
import {
  selectCreationFormData,
  selectCurrentTeamMemberData
} from 'pages/TeamSchedule/TeamList/selectors';
import { selectUnsavedFormData } from 'pages/commonSelectors';

export function useTeamMemberSelector() {
  const currentTeamMember = useSelector(selectCurrentTeamMemberData());
  const creationFormData = useSelector(selectCreationFormData());

  const unsavedFormData = useSelector(selectUnsavedFormData());

  return {
    currentTeamMember,
    creationFormData,
    unsavedFormData
  };
}
