import React from 'react';

import TicketDetailsHeader from './components/Header';
import TicketDetailsWrapper from './components/Wrapper';
import TicketGeneralDetails from './components/Details/General';
import TicketDetailsDescription from './components/Details/Description';
import TicketAssigneeInfo from './components/Details/AssigneeInfo';
import TicketRequiredItems from './components/Details/RequiredItems';
import TicketRemarks from './components/Details/Remarks';
import TicketFiles from './components/Details/Files';
import ReopenTicketSettings from './components/Details/ReopenTicketSettings';

export default function TicketDetailed() {
  return (
    <TicketDetailsWrapper>
      <TicketDetailsHeader />
      <TicketGeneralDetails />
      <TicketFiles />
      <TicketDetailsDescription />
      <TicketAssigneeInfo />
      <ReopenTicketSettings />
      <TicketRequiredItems />
      <TicketRemarks />
    </TicketDetailsWrapper>
  );
}
