import api from '../api';
import { browserHistory } from '../browserHistory';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from 'helpers/AppHelpers';

import {
  SET_CURRENT_PREFIX,
  SET_PREFIX_TYPES,
  SET_ASSET_FIELDS,
  CLEAR_PREFIX_STATE
} from 'constants/reduceConstants';
import {
  ERROR_DELETE_FIELD,
  ERROR_PREFIX_EXIST,
  SUCCESS_CREATE_PREFIX,
  SUCCESS_DELETE_PREFIX,
  SUCCESS_UPDATE_PREFIX
} from '../constants/infoSnackbarData';

import { CATEGORY_LIST_PATH, PREFIX_DETAILED_PATH } from '../constants/routeConstants';
import { checkAssetSummaryCustomColumns } from './assetActions';

export const getSinglePrefix = (id) => (dispatch) => {
  api.prefix
    .getSinglePrefixApi(id)
    .then((res) => dispatch({ type: SET_CURRENT_PREFIX, payload: res }))
    .catch((err) => console.log(err));
};

export const getPrefixTypes = () => (dispatch) => {
  api.prefix
    .getPrefixTypesApi()
    .then((res) => dispatch({ type: SET_PREFIX_TYPES, payload: res }))
    .catch((err) => console.log(err));
};

export const getAssetFields = () => (dispatch) => {
  api.prefix
    .getAssetFieldsApi()
    .then((res) => dispatch({ type: SET_ASSET_FIELDS, payload: res }))
    .catch((err) => console.log(err));
};

export const getAssetFieldsDeletePermissions = (assetPrefixId, assetFieldId) => () => {
  return api.prefix
    .getAssetFieldsDeletePermissionsApi(assetPrefixId, assetFieldId)
    .then((res) => {
      if (!('canBeDeleted' in res)) return 'Error';
      if (res.canBeDeleted) {
        return 'Allowed';
      } else {
        enqueueErrorSnackbar(ERROR_DELETE_FIELD);
      }
    })
    .catch((err) => console.log(err));
};

export const createPrefix = (data) => () => {
  return api.prefix.createPrefixApi(data).then(
    (res) => {
      enqueueSuccessSnackbar(SUCCESS_CREATE_PREFIX);
      browserHistory.push(`${PREFIX_DETAILED_PATH}/${res.id}`);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      return errors;
    }
  );
};

const handleHidedFields = (fields, dispatch) => {
  if (!fields?.length || !Array.isArray(fields)) return;

  const hidedOnAssetSummaryFieldIds = fields
    .filter(({ isShowOnAssetSummary }) => !isShowOnAssetSummary)
    .map(({ id }) => id);

  dispatch(checkAssetSummaryCustomColumns(hidedOnAssetSummaryFieldIds));
};
export const updatePrefix = (data) => (dispatch) => {
  return api.prefix.updatePrefixApi(data).then(
    (res) => {
      handleHidedFields(data?.assetFields || [], dispatch);
      enqueueSuccessSnackbar(SUCCESS_UPDATE_PREFIX);
      browserHistory.push(`${PREFIX_DETAILED_PATH}/${res.id}`);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      return errors;
    }
  );
};

export const enqueueExistPrefixWarning = () => () => {
  enqueueErrorSnackbar(ERROR_PREFIX_EXIST);
};

export const deletePrefix = (id) => (dispatch) => {
  return api.prefix.deletePrefixApi(id).then(
    () => {
      enqueueSuccessSnackbar(SUCCESS_DELETE_PREFIX);
      dispatch({ type: CLEAR_PREFIX_STATE });
      browserHistory.push(CATEGORY_LIST_PATH);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
    }
  );
};

export const clearPrefixState = () => (dispatch) => {
  dispatch({ type: CLEAR_PREFIX_STATE });
};

export const updatePrefixField = (params) => () => {
  const modifiedField = { ...params, displayName: params?.name || '' };
  return api.prefix.updatePrefixFieldApi(modifiedField).then(
    (res) => res,
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );
};

export const deletePrefixField = (q) => () => {
  return api.prefix.deletePrefixFieldApi(q).then(
    (res) => res,
    (err) => {
      const errors = JSON.parse(err.request.response);
      return errors;
    }
  );
};
