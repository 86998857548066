import React from 'react';
import styles from './LeftSidebar.module.scss';
import { IconButton } from '@material-ui/core';
import openInNewTabIcon from 'assets/images/openInNewTabIcon.svg';
import { BASE_ADDRESS } from 'api/api';

export default function OpenInNewTabButton({ path }) {
  const handleClick = (e) => {
    e.stopPropagation();
    const url = BASE_ADDRESS + path;
    setTimeout(() => {
      window.open(url, '_blank');
    }, 0);
  };

  return (
    <IconButton className={styles.open_new_tab_button} onClick={handleClick} tabIndex="-1">
      <img src={openInNewTabIcon} alt="openInNew" />
    </IconButton>
  );
}
