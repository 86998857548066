import React from 'react';
import styles from './Details.module.scss';

import { useTicketSelector } from 'hooks/Ticket';

import { dateTimeFormat, formatDate, getUserFullName } from 'helpers/AppHelpers';

import { ASSET_DETAILED_PATH } from 'constants/routeConstants';

import DetailedRow from 'components/DetailsComponents/DetailedRow';
import LinkComponent from 'components/LinkComponent/LinkComponent';
import StatusIndicator from 'components/StatusIndicator';

export default function TicketGeneralDetails() {
  const { currentTicket } = useTicketSelector();

  const { openedByUser, openedAtUtc, ticketType, rootCause, asset, severity } = currentTicket;

  const details = [
    {
      id: 1,
      label: 'Asset',
      value: (
        <LinkComponent name={asset?.drCode || ''} path={`${ASSET_DETAILED_PATH}/${asset?.id}`} />
      )
    },
    {
      id: 2,
      label: 'Opened By',
      value: openedByUser?.firstName ? getUserFullName(openedByUser) : ''
    },
    {
      id: 3,
      label: 'Opened On',
      value: openedAtUtc ? formatDate(openedAtUtc, dateTimeFormat) : ''
    },
    { id: 4, label: 'Ticket Type', value: ticketType?.name || '' },
    { id: 5, label: 'Root Cause', value: rootCause?.name || '' }
  ];

  return (
    <div className={styles.general}>
      {details.map(({ id, label, value }) => (
        <DetailedRow key={id} label={label} value={value} disableFlex />
      ))}
      <DetailedRow label="Severity" value={severity?.displayName || ''} alignCenter>
        <StatusIndicator status={severity?.name} leftSpace />
      </DetailedRow>
    </div>
  );
}
