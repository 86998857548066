import React from 'react';
import styles from './RandRDashboardTable.module.scss';

import { NoDataTableRow, TableWrapper } from 'components/SummaryComponents';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';

import { Table, TableHead, TableBody } from '@material-ui/core';

import clsx from 'clsx';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { TEAM_MEMBER_LIST_PATH } from 'constants/routeConstants';

import { setTeamMemberListPrefilledFilter } from 'actions/teamMemberActions';

const COLUMNS = ['Capacity', 'On Roster', 'On Site', 'Available'];

export default function RandRDashboardTable({ data }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { capacities, total } = data;

  const handleCellClick = (capacityId, dashboardField) => {
    const filter = {
      searchQuery: '',
      dashboardId: data.id,
      dashboardType: 'RandRDashboard',
      dashboardField,
      teamMemberCapacityIds: [capacityId]
    };
    dispatch(setTeamMemberListPrefilledFilter(filter));
    history.push(TEAM_MEMBER_LIST_PATH);
  };

  return (
    <div className={styles.table}>
      <TableWrapper>
        <Table>
          <TableHead>
            <StyledTableRow>
              {COLUMNS.map((name, index) => (
                <StyledTableCell key={index}>{name}</StyledTableCell>
              ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {capacities?.length ? (
              <>
                {capacities.map(({ capacity, randRInfo }, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell className={styles.capacityCell}>
                      {capacity?.name || ''}
                    </StyledTableCell>
                    <StyledTableCell className={styles.onRosterCell}>
                      <span
                        className={clsx(randRInfo?.onRoster > 0 && styles.clickable)}
                        onClick={() =>
                          randRInfo?.onRoster > 0 && handleCellClick(capacity.id, 'OnRoster')
                        }>
                        {randRInfo?.onRoster || 0}
                      </span>
                    </StyledTableCell>
                    <StyledTableCell className={styles.onSiteCell}>
                      <span
                        className={clsx(randRInfo?.onSite > 0 && styles.clickable)}
                        onClick={() =>
                          randRInfo?.onSite > 0 && handleCellClick(capacity.id, 'OnSite')
                        }>
                        {randRInfo?.onSite || 0}
                      </span>
                    </StyledTableCell>
                    <StyledTableCell className={styles.availableCell}>
                      <span
                        className={clsx(randRInfo?.available > 0 && styles.clickable)}
                        onClick={() =>
                          randRInfo?.available > 0 && handleCellClick(capacity.id, 'Available')
                        }>
                        {randRInfo?.available || 0}
                      </span>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
                <StyledTableRow className={styles.total}>
                  <StyledTableCell>TOTAL</StyledTableCell>
                  <StyledTableCell>{total?.onRoster || 0}</StyledTableCell>
                  <StyledTableCell>{total?.onSite || 0}</StyledTableCell>
                  <StyledTableCell>{total?.available || 0}</StyledTableCell>
                </StyledTableRow>
              </>
            ) : (
              <NoDataTableRow isTableFiltered={!!data?.department?.id} />
            )}
          </TableBody>
        </Table>
      </TableWrapper>
    </div>
  );
}
