import {
  doDriveId,
  doNotDriveId,
  driveStatuses,
  getRandomId,
  getTeamMemberFullName
} from 'helpers/AppHelpers';

const mapItemIdsToChips = (options, ids, key, name, prop) => {
  return ids.map((id) => {
    const obj = options?.find((option) => option.id === id) || {};
    if (obj?.id || obj?.id === 0) {
      return {
        ...obj,
        key: key,
        displayName: key === 'teamMember' ? getTeamMemberFullName(obj) : obj[name] || '',
        chipId: getRandomId(),
        propertyOfValues: prop
      };
    }
  });
};

export const getChips = (inputFilter, options) => {
  const {
    clearanceTypes,
    teamMemberCapacities,
    teamMemberDepartments,
    teamMemberLicenses,
    teamMemberSkillsets,
    teamMemberStatuses,
    readOnlyTeamMemberDepartments
  } = options;
  let chipsArray = [];

  if (inputFilter?.clearanceTypeIds?.length) {
    chipsArray = chipsArray.concat(
      mapItemIdsToChips(
        clearanceTypes,
        inputFilter.clearanceTypeIds,
        'clearance',
        'name',
        'clearanceTypeIds'
      )
    );
  }
  if (inputFilter?.teamMemberCapacityIds?.length) {
    chipsArray = chipsArray.concat(
      mapItemIdsToChips(
        teamMemberCapacities,
        inputFilter['teamMemberCapacityIds'],
        'capacity',
        'name',
        'teamMemberCapacityIds'
      )
    );
  }
  if (inputFilter?.teamMemberDepartmentIds?.length) {
    chipsArray = chipsArray.concat(
      mapItemIdsToChips(
        teamMemberDepartments,
        inputFilter['teamMemberDepartmentIds'],
        'department',
        'name',
        'teamMemberDepartmentIds'
      )
    );
  }
  if (inputFilter?.teamMemberLicenseIds?.length) {
    chipsArray = chipsArray.concat(
      mapItemIdsToChips(
        teamMemberLicenses,
        inputFilter['teamMemberLicenseIds'],
        'license',
        'name',
        'teamMemberLicenseIds'
      )
    );
  }
  if (inputFilter?.teamMemberSkillsetIds?.length) {
    chipsArray = chipsArray.concat(
      mapItemIdsToChips(
        teamMemberSkillsets,
        inputFilter['teamMemberSkillsetIds'],
        'skillset',
        'name',
        'teamMemberSkillsetIds'
      )
    );
  }
  if (inputFilter?.teamMemberStatusIds?.length) {
    chipsArray = chipsArray.concat(
      mapItemIdsToChips(
        teamMemberStatuses,
        inputFilter['teamMemberStatusIds'],
        'status',
        'name',
        'teamMemberStatusIds'
      )
    );
  }
  if (inputFilter?.readOnlyTeamMemberDepartmentIds?.length) {
    chipsArray = chipsArray.concat(
      mapItemIdsToChips(
        readOnlyTeamMemberDepartments,
        inputFilter['readOnlyTeamMemberDepartmentIds'],
        'readOnlySchedules',
        'name',
        'readOnlyTeamMemberDepartmentIds'
      )
    );
  }
  if (inputFilter?.driveStatusIds?.length) {
    chipsArray = chipsArray.concat(
      mapItemIdsToChips(
        driveStatuses,
        inputFilter['driveStatusIds'],
        'driveStatus',
        'displayName',
        'driveStatusIds'
      )
    );
  }
  if (inputFilter?.['showDeleted']) {
    chipsArray.push({
      id: 'showDeleted',
      chipId: getRandomId(),
      key: 'showDeleted',
      displayName: 'Show removed Team Members'
    });
  }

  if (
    inputFilter?.dashboardId &&
    inputFilter?.dashboardType &&
    (inputFilter?.dashboardField || // R&R
      (inputFilter?.dashboardDuration && inputFilter?.dashboardProjectId) || // Staffing Levels
      (inputFilter?.dashboardDuration && inputFilter?.dashboardField)) // Staffing Availability
  ) {
    chipsArray.push({
      id: 'filteredByDashboard',
      chipId: getRandomId(),
      key: 'filteredByDashboard',
      displayName: 'Filtered by Dashboard'
    });
  }

  chipsArray = chipsArray.filter(Boolean);
  return chipsArray;
};

export const getValuesAfterChipDeleting = (values, chip) => {
  switch (chip.key) {
    case 'showDeleted':
      return { ...values, showDeleted: false };
    case 'driveStatus':
      return {
        ...values,
        driveStatusIds: values.driveStatusIds.filter((id) => id !== chip.id),
        doDrive: chip.id === doDriveId ? false : values.doDrive,
        doNotDrive: chip.id === doNotDriveId ? false : values.doNotDrive
      };
    case 'filteredByDashboard':
      return {
        ...values,
        dashboardId: '',
        dashboardField: '',
        dashboardType: '',
        dashboardDuration: null,
        dashboardProjectId: null
      };
    default:
      return {
        ...values,
        [chip.propertyOfValues]: values[chip.propertyOfValues].filter((id) => id !== chip.id)
      };
  }
};
