import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { selectUserConfig, selectUnsavedFormData } from 'pages/commonSelectors';
import { getUserConfig, setUnsavedFormData } from 'actions/commonActions';
import { Drawer, Button, IconButton, List, ListItem, ListItemIcon } from '@material-ui/core';
import styles from './LeftSidebar.module.scss';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import SearchIcon from '@material-ui/icons/Search';
import { listItems } from './LeftSidebarItems';
import { createStructuredSelector } from 'reselect';
import { onLogout } from '../../actions/authActions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { LOGIN_PATH, SEARCH_PATH } from 'constants/routeConstants';
import DialogPopup from '../DialogPopup/DialogPopup';
import {
  LOGOUT_TYPE,
  LOGOUT,
  WITHOUT_SAVING_TYPE,
  EXIT_WITHOUT_SAVING
} from 'constants/dialogPopupsData';
import { ReactComponent as LogoutIcon } from 'assets/images/logout.svg';
import { ReactComponent as QRReadIcon } from 'assets/images/qrRead.svg';
import { ReactComponent as QRScanIcon } from 'assets/images/qrScan.svg';
import SidebarListItem from './SidebarListItem';
import { isAdminUser, isPersonnelManagerUser } from 'helpers/AppHelpers';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { QRCodeScanner, QRCodeGenerator } from 'components/QR';

import projectData from '../../../package.json';

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),

    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1
    },
    overflow: 'hidden'
  },
  drawerDesktopClose: {
    width: '60px !important'
  },
  drawerMobileClose: {
    width: '0 !important',
    border: 'none'
  },
  searchBarStyle: {
    '& .MuiOutlinedInput-root': {
      color: '#fff',
      '&.Mui-focused fieldset': {
        border: '1px solid #fff',
        borderRadius: '8px'
      }
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: '0',
      '& button': {
        color: '#fff',
        marginRight: '5px'
      }
    }
  }
}));

function LeftSidebar({
  userConfig,
  getUserConfigAction,
  unsavedFormData,
  setUnsavedFormDataAction,
  onLogoutAction
}) {
  const isMobile = useMobileViewport();
  const classes = useStyles();
  const history = useHistory();
  const { userRole, teamMember, teamMemberProjectsExist, departments } = userConfig;
  const [searchInput, setSearchInput] = useState({ searchQuery: '' });

  const [openQR, setOpenQR] = useState(false);
  const [openScanner, setOpenScanner] = useState(false);

  const [open, setOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [selectedPath, setSelectedPath] = useState('');
  const [openCollapse, setOpenCollapse] = useState([false, false]);
  const [dialogModalData, setDialogModalData] = useState({
    isOpened: false
  });

  useEffect(() => {
    if (!Object.keys(userConfig).length) {
      getUserConfigAction();
    }
    setItems(listItems);
  }, [userConfig]);

  useEffect(() => {
    return history.listen(() => {
      const newItems = [...listItems];
      setItems(newItems);
    });
  }, [history]);

  const isTeamScheduleHidden = () => {
    if (isAdminUser(userRole) || isPersonnelManagerUser(userRole)) return false;
    else return teamMember?.id ? !teamMemberProjectsExist : !departments?.length;
  };

  const availableForReadOnlyRightUsers = () => {
    if (isAdminUser(userRole) || isPersonnelManagerUser(userRole)) return true;
    else return !teamMember?.id && departments?.length;
  };

  const separateValidation = (itemName) => {
    switch (itemName) {
      case 'Project Schedule':
        return isTeamScheduleHidden();
      case 'Team Member Dashboard':
      case 'Team Member History':
      case 'Team List':
        return !availableForReadOnlyRightUsers();
      default:
        return false;
    }
  };

  const isHidden = (item) => {
    const roleCheck = item.hiddenForRoles?.includes(userRole?.id);
    const mobileCheck = item.hiddenForMobile && isMobile;
    const isTeamMemberCheck = item.hiddenForNotTeamMember && !teamMember?.id;
    const separateCheck = item?.separateCheck && separateValidation(item.name);
    return roleCheck || mobileCheck || isTeamMemberCheck || separateCheck;
  };

  const handleSidebarOpen = () => {
    const newStateCollapse = [...openCollapse];
    for (let i = 0; i < newStateCollapse.length; i += 1) {
      newStateCollapse[i] = false;
    }
    setOpenCollapse(newStateCollapse);
    setOpen(!open);
  };

  const closeSidebar = () => {
    const newStateCollapse = [...openCollapse];
    for (let i = 0; i < newStateCollapse.length; i += 1) {
      newStateCollapse[i] = false;
    }
    setOpenCollapse(newStateCollapse);
    setOpen(false);
  };

  const handleNavigateClick = (path) => {
    openScanner && setOpenScanner(false);
    openQR && setOpenQR(false);
    if (unsavedFormData) {
      setDialogModalData({ ...EXIT_WITHOUT_SAVING, isOpened: true });
      setSelectedPath(path);
    } else if (path) {
      history.push(path);
      closeSidebar();
    }
  };

  const openSidebar = () => {
    setOpen(true);
  };

  const onLogoutSystem = () => {
    setDialogModalData({ ...LOGOUT, isOpened: true });
  };

  const onInput = (event) => {
    event.target.value = event.target.value.replace(/[А-Яа-я]/g, '').trimStart();
    setSearchInput({ searchQuery: event.target.value });
    if (event.key === 'Enter' && searchInput.searchQuery.length) {
      history.push(`${SEARCH_PATH}?searchQuery=${searchInput.searchQuery}`);
      setSearchInput({ searchQuery: '' });
      closeSidebar();
    }
  };

  const onAgree = () => {
    switch (dialogModalData.type) {
      case LOGOUT_TYPE:
        history.push(LOGIN_PATH);
        onLogoutAction();
        break;
      case WITHOUT_SAVING_TYPE:
        setUnsavedFormDataAction(false);
        history.push(selectedPath);
        closeSidebar();
        break;
      default:
        break;
    }
    setDialogModalData({ isOpened: false });
  };

  const onDismiss = () => {
    setDialogModalData({ isOpened: false });
  };

  const buildSideBarItems = (items) =>
    [...items]
      .map((el) => {
        if (isHidden(el)) return;
        if (!el.isCollapse) return { ...el };
        const collapseItems = [...el.collapseItems].filter((el) => !isHidden(el));
        return collapseItems.length === 0 ? false : { ...el, collapseItems: collapseItems };
      })
      .filter(Boolean);

  const sideBarItems = buildSideBarItems(items);

  const env =
    process.env.REACT_APP_ENVIRONMENT === 'prod'
      ? 'test'
      : process.env.REACT_APP_ENVIRONMENT || 'local';
  const isProdEnv = () => env === 'production';
  const getEnvTitle = () => (isProdEnv() ? '' : env + ' env');

  const generateQr = () => {
    setOpenQR(true);
    setOpen(false);
  };

  const scanQr = (data) => {
    const url =
      '/' +
      data
        .split('/')
        .filter((_, i) => i !== 0 && i !== 1 && i !== 2)
        .join('/');

    if (unsavedFormData) {
      setDialogModalData({ ...EXIT_WITHOUT_SAVING, isOpened: true });
      setSelectedPath(url);
    } else {
      history.push(url);
      closeSidebar();
    }
  };

  const openQRScan = () => {
    setOpenScanner(true);
    setOpen(false);
  };

  return (
    <section className={styles.sidebarContainer}>
      <DialogPopup data={dialogModalData} onAgree={onAgree} onDissmiss={onDismiss} />
      {openQR && <QRCodeGenerator open={openQR} setOpen={setOpenQR} />}
      {openScanner && <QRCodeScanner open={openScanner} setOpen={setOpenScanner} onScan={scanQr} />}
      {isMobile && (
        <Button className={styles.expandButton} onClick={openSidebar}>
          <MenuIcon />
        </Button>
      )}
      <Drawer
        variant="permanent"
        className={clsx(styles.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
          [classes.drawerDesktopClose]: !isMobile && !open,
          [classes.drawerMobileClose]: isMobile && !open
        })}
        classes={{
          paper: clsx(styles.paper, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
            [classes.drawerDesktopClose]: !isMobile && !open,
            [classes.drawerMobileClose]: isMobile && !open
          })
        }}
        onMouseEnter={openSidebar}
        onMouseLeave={closeSidebar}>
        <List>
          <ListItem className={clsx(styles.listItem, styles.menuIcon)}>
            <ListItemIcon className={styles.listItem__icon} onClick={handleSidebarOpen}>
              {open ? <CloseIcon /> : <MenuIcon />}
            </ListItemIcon>
            <div className={styles.searchPanel}>
              <TextField
                className={`${styles.searchInput} ${classes.searchBarStyle}`}
                type="text"
                placeholder="Search"
                variant="outlined"
                onChange={onInput}
                onKeyDown={onInput}
                value={searchInput.searchQuery}
                inputProps={{ tabIndex: '-1' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {searchInput.searchQuery ? (
                        <IconButton onClick={() => setSearchInput({ searchQuery: '' })}>
                          <CancelRoundedIcon fontSize="small" className={styles.clearIcon} />
                        </IconButton>
                      ) : (
                        <SearchIcon />
                      )}
                    </InputAdornment>
                  )
                }}
              />
            </div>
            {open && (
              <div className={clsx(styles.listItem__controls, styles.qr)}>
                <IconButton onClick={openQRScan}>
                  <QRScanIcon />
                </IconButton>
              </div>
            )}
            {isMobile && !isProdEnv() && (
              <div className={styles.envPanel}>
                <div className={styles.envTitle}>{getEnvTitle()}</div>
              </div>
            )}
          </ListItem>
          {sideBarItems.map((list) => (
            <SidebarListItem
              key={list.id}
              list={list}
              openCollapse={openCollapse}
              setOpenCollapse={setOpenCollapse}
              handleNavigateClick={handleNavigateClick}
            />
          ))}
          {open && (
            <>
              <ListItem className={styles.lastListItem}>
                <div className={styles.buttonPanel}>
                  <Button className={styles.buttonStyle} onClick={generateQr}>
                    <div className={styles.buttonLabel}>
                      <div className={styles.buttonIcon}>
                        <QRReadIcon />
                      </div>
                      <span className={styles.buttonText}>QR FOR PAGE</span>
                    </div>
                  </Button>
                </div>
              </ListItem>
              <ListItem className={styles.lastListItem}>
                <div className={styles.buttonPanel}>
                  <Button className={styles.buttonStyle} onClick={onLogoutSystem}>
                    <div className={styles.buttonLabel}>
                      <div className={styles.buttonIcon}>
                        <LogoutIcon />
                      </div>
                      <span className={styles.buttonText}>LOGOUT</span>
                    </div>
                  </Button>
                </div>
              </ListItem>
            </>
          )}
          {open && isMobile && (
            <ListItem className={styles.versionItem}>
              <div className={styles.versionPanel}>Version {projectData.version}</div>
            </ListItem>
          )}
        </List>
      </Drawer>
    </section>
  );
}

const mapStateToProps = createStructuredSelector({
  userConfig: selectUserConfig(),
  unsavedFormData: selectUnsavedFormData()
});

const mapDispatchToProps = {
  getUserConfigAction: getUserConfig,
  setUnsavedFormDataAction: setUnsavedFormData,
  onLogoutAction: onLogout
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(LeftSidebar);
