import React from 'react';
import styles from './AvailabilityTable.module.scss';

import { NoDataTableRow, TableWrapper } from 'components/SummaryComponents';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';

import { Table, TableHead, TableBody } from '@material-ui/core';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { setTeamMemberListPrefilledFilter } from 'actions/teamMemberActions';

import { TEAM_MEMBER_LIST_PATH } from 'constants/routeConstants';

import clsx from 'clsx';

const EmptyTable = () => (
  <div className={styles.table}>
    <TableWrapper>
      <Table>
        <TableHead>
          <StyledTableRow>
            <StyledTableCell>Status</StyledTableCell>
            <StyledTableCell>Staff Sourcing</StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          <NoDataTableRow isTableFiltered={false} />
        </TableBody>
      </Table>
    </TableWrapper>
  </div>
);

export default function AvailabilityTable({ dashboard, dashboardId, department }) {
  const dispatch = useDispatch();
  const history = useHistory();

  if (!('total' in dashboard)) return <EmptyTable />;

  const {
    available,
    availableNotSubmitted,
    pto,
    rr,
    total,
    totalUnavailable,
    unavailable,
    undeterminedData,
    duration1ColumnName,
    duration2ColumnName
  } = dashboard;

  const COLUMNS = [
    { id: 1, label: 'Status' },
    { id: 2, label: 'Staff Sourcing' },
    { id: 3, label: duration1ColumnName },
    { id: 4, label: duration2ColumnName }
  ];

  const handleCellClick = (options) => {
    const filter = {
      searchQuery: '',
      dashboardId: dashboardId,
      dashboardType: 'StaffingLevelsAndAvailability',
      ...options
    };
    dispatch(setTeamMemberListPrefilledFilter(filter));
    history.push(TEAM_MEMBER_LIST_PATH);
  };

  return (
    <div className={styles.table}>
      <TableWrapper>
        <Table>
          <TableHead>
            <StyledTableRow>
              {COLUMNS.map(({ id, label }) => (
                <StyledTableCell key={id}>{label}</StyledTableCell>
              ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {total?.duration1Value > 0 || total?.duration2Value > 0 ? (
              <>
                <StyledTableRow>
                  <StyledTableCell className={clsx(styles.statusCell, styles.extended)} rowSpan={4}>
                    Unavailable
                  </StyledTableCell>
                  <StyledTableCell className={styles.staffCell}>PTO</StyledTableCell>
                  <StyledTableCell className={styles.durationCell}>
                    <span
                      className={clsx(pto.duration1Value > 0 && styles.clickable)}
                      onClick={() =>
                        pto.duration1Value > 0 &&
                        handleCellClick({
                          dashboardDuration: 1,
                          dashboardField: 'PTO'
                        })
                      }>
                      {pto.duration1Value || 0}
                    </span>
                  </StyledTableCell>
                  <StyledTableCell className={styles.durationCell}>
                    <span
                      className={clsx(pto.duration2Value > 0 && styles.clickable)}
                      onClick={() =>
                        pto.duration2Value > 0 &&
                        handleCellClick({
                          dashboardDuration: 2,
                          dashboardField: 'PTO'
                        })
                      }>
                      {pto.duration2Value || 0}
                    </span>
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell className={styles.staffCell}>R&R, RR</StyledTableCell>
                  <StyledTableCell className={styles.durationCell}>
                    <span
                      className={clsx(rr.duration1Value > 0 && styles.clickable)}
                      onClick={() =>
                        rr.duration1Value > 0 &&
                        handleCellClick({
                          dashboardDuration: 1,
                          dashboardField: 'RR'
                        })
                      }>
                      {rr.duration1Value || 0}
                    </span>
                  </StyledTableCell>
                  <StyledTableCell className={styles.durationCell}>
                    <span
                      className={clsx(rr.duration2Value > 0 && styles.clickable)}
                      onClick={() =>
                        rr.duration2Value > 0 &&
                        handleCellClick({
                          dashboardDuration: 2,
                          dashboardField: 'RR'
                        })
                      }>
                      {rr.duration2Value || 0}
                    </span>
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell className={styles.staffCell}>Unavailable</StyledTableCell>
                  <StyledTableCell className={styles.durationCell}>
                    <span
                      className={clsx(unavailable.duration1Value > 0 && styles.clickable)}
                      onClick={() =>
                        unavailable.duration1Value > 0 &&
                        handleCellClick({
                          dashboardDuration: 1,
                          dashboardField: 'Unavailable'
                        })
                      }>
                      {unavailable.duration1Value || 0}
                    </span>
                  </StyledTableCell>
                  <StyledTableCell className={styles.durationCell}>
                    <span
                      className={clsx(unavailable.duration2Value > 0 && styles.clickable)}
                      onClick={() =>
                        unavailable.duration2Value > 0 &&
                        handleCellClick({
                          dashboardDuration: 2,
                          dashboardField: 'Unavailable'
                        })
                      }>
                      {unavailable.duration2Value || 0}
                    </span>
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell className={styles.staffCell}>Undetermined Data</StyledTableCell>
                  <StyledTableCell className={styles.durationCell}>
                    <span
                      className={clsx(undeterminedData.duration1Value > 0 && styles.clickable)}
                      onClick={() =>
                        undeterminedData.duration1Value > 0 &&
                        handleCellClick({
                          dashboardDuration: 1,
                          dashboardField: 'UndeterminedData'
                        })
                      }>
                      {undeterminedData.duration1Value || 0}
                    </span>
                  </StyledTableCell>
                  <StyledTableCell className={styles.durationCell}>
                    <span
                      className={clsx(undeterminedData.duration2Value > 0 && styles.clickable)}
                      onClick={() =>
                        undeterminedData.duration2Value > 0 &&
                        handleCellClick({
                          dashboardDuration: 2,
                          dashboardField: 'UndeterminedData'
                        })
                      }>
                      {undeterminedData.duration2Value || 0}
                    </span>
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow className={styles.unavailable}>
                  <StyledTableCell className={styles.statusCell}>Total Unavailable</StyledTableCell>
                  <StyledTableCell className={styles.staffCell}></StyledTableCell>
                  <StyledTableCell className={clsx(styles.durationCell, styles.bold)}>
                    {totalUnavailable.duration1Value || 0}
                  </StyledTableCell>
                  <StyledTableCell className={clsx(styles.durationCell, styles.bold)}>
                    {totalUnavailable.duration2Value || 0}
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow className={styles.available}>
                  <StyledTableCell className={styles.statusCell}>Available</StyledTableCell>
                  <StyledTableCell className={styles.staffCell}></StyledTableCell>
                  <StyledTableCell className={clsx(styles.durationCell, styles.bold)}>
                    <span
                      className={clsx(available.duration1Value > 0 && styles.clickable)}
                      onClick={() =>
                        available.duration1Value > 0 &&
                        handleCellClick({
                          dashboardDuration: 1,
                          dashboardField: 'Available'
                        })
                      }>
                      {available.duration1Value || 0}
                    </span>
                  </StyledTableCell>
                  <StyledTableCell className={clsx(styles.durationCell, styles.bold)}>
                    <span
                      className={clsx(available.duration2Value > 0 && styles.clickable)}
                      onClick={() =>
                        available.duration2Value > 0 &&
                        handleCellClick({
                          dashboardDuration: 2,
                          dashboardField: 'Available'
                        })
                      }>
                      {available.duration2Value || 0}
                    </span>
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow className={styles.notSubmitted}>
                  <StyledTableCell className={styles.statusCell}>
                    Availability Not Submitted
                  </StyledTableCell>
                  <StyledTableCell className={styles.staffCell}></StyledTableCell>
                  <StyledTableCell className={clsx(styles.durationCell, styles.bold)}>
                    <span
                      className={clsx(availableNotSubmitted.duration1Value > 0 && styles.clickable)}
                      onClick={() =>
                        availableNotSubmitted.duration1Value > 0 &&
                        handleCellClick({
                          dashboardDuration: 1,
                          dashboardField: 'AvailableNotSubmitted'
                        })
                      }>
                      {availableNotSubmitted.duration1Value || 0}
                    </span>
                  </StyledTableCell>
                  <StyledTableCell className={clsx(styles.durationCell, styles.bold)}>
                    <span
                      className={clsx(availableNotSubmitted.duration2Value > 0 && styles.clickable)}
                      onClick={() =>
                        availableNotSubmitted.duration2Value > 0 &&
                        handleCellClick({
                          dashboardDuration: 2,
                          dashboardField: 'AvailableNotSubmitted'
                        })
                      }>
                      {availableNotSubmitted.duration2Value || 0}
                    </span>
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell className={clsx(styles.statusCell, styles.bold)}>
                    Total
                  </StyledTableCell>
                  <StyledTableCell className={styles.staffCell}></StyledTableCell>
                  <StyledTableCell className={styles.durationCell}>
                    {total.duration1Value || 0}
                  </StyledTableCell>
                  <StyledTableCell className={styles.durationCell}>
                    {total.duration2Value || 0}
                  </StyledTableCell>
                </StyledTableRow>
              </>
            ) : (
              <NoDataTableRow isTableFiltered={!!department?.id} />
            )}
          </TableBody>
        </Table>
      </TableWrapper>
    </div>
  );
}
