import { useSelector } from 'react-redux';

import { selectTrainingsFilterData } from 'pages/TeamSchedule/ExternalTrainings/selectors';

export function useTrainingsSelector() {
  const filter = useSelector(selectTrainingsFilterData());

  return {
    filter
  };
}
