import React, { useRef, useEffect, useState } from 'react';
import styles from './ExternalTrainings.module.scss';

import {
  SummaryWrapper,
  SummaryHeaderWrapper,
  SummaryPageHeader,
  SummaryTableControls,
  TableWrapper,
  TableHeadComponent,
  Pagination,
  SearchField,
  NoDataTableRow
} from 'components/SummaryComponents';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { BlueButton } from 'components/Buttons';
import ConfirmationPopup from 'components/ConfirmationPopup/ConfirmationPopup';

import ManageGroupPopup from 'components/ManageGroupPopup';
import EditCertificationPopup from 'components/EditCertificationPopup';

import { IconButton, Table, TableBody } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';

import { useTrainingsActions, useTrainingsSelector } from 'hooks/Trainings';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { useUserConfig } from 'hooks/useUserConfig';

import {
  getChangedByApplyFilter,
  getChangedByLimitFilter,
  getChangedByPageFilter,
  getChangedBySearchFilter,
  getChangedBySortFilter
} from 'helpers/SummaryPagesHelpers';
import { isSuccessfulStatus } from 'helpers/AppHelpers';

import { EXTERNAL_TRAININGS_TABLE_PARAMETER } from 'constants/configTableConstants';
import {
  DELETE_CERTIFICATION,
  CONFIRM_DELETE_CERTIFICATION,
  CONFIRM_DELETE_CERTIFICATION_TYPE
} from 'constants/dialogPopupsData';

import Filter from './Filter';
import { tableConfig } from './tableConfig';
import { useCommonActions, useCommonSelector } from 'hooks/Common';

export default function ExternalTrainings() {
  const isMobile = useMobileViewport();
  const tableRef = useRef(null);

  const [tableState, setTableState] = useState([]);

  const [openManageGroupPopup, setOpenManageGroupPopup] = useState(false);

  const [openEditCertificationPopup, setOpenEditCertificationPopup] = useState(false);
  const [editingCertification, setEditingCertification] = useState({});

  const [modalData, setModalData] = useState({});

  const { isConfigReceived, getTableLimit } = useUserConfig();
  const { getTrainingsAction, setFilterAction, deleteCertificationAction, clearStateAction } =
    useTrainingsActions();
  const { filter } = useTrainingsSelector();

  const { setLoadMoreFlagAction } = useCommonActions();
  const { loadMoreFlag } = useCommonSelector();

  const { filters, pagination, sortRules, responseReceived } = filter;

  const generateInitialQuery = () => ({
    ...filter,
    pagination: { ...pagination, limit: getTableLimit(EXTERNAL_TRAININGS_TABLE_PARAMETER) }
  });

  const getTrainings = (query, isAutoload) => {
    setFilterAction(query);

    getTrainingsAction(query).then((tableData) => {
      setTrainings(tableData, query, isAutoload);
      !loadMoreFlag && setLoadMoreFlagAction(false);
    });
  };

  const setTrainings = (data, query, isAutoload) => {
    if (!isAutoload) {
      setTableState(data?.items || []);
    } else {
      setTableState(tableState.concat(data?.items || []));
    }

    const updatedFilter = {
      ...query,
      pagination: { limit: data.limit, page: data.pageNumber, totalPages: data.totalPages },
      responseReceived: true
    };
    setFilterAction(updatedFilter);
  };

  useEffect(() => {
    if (!isConfigReceived) return;

    getTrainings(generateInitialQuery());
  }, [isConfigReceived]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  const isTableFiltered =
    filters.searchQuery !== '' || !!filters.externalTrainingIds.length || !!filters.groupIds.length;

  const findMatches = (value) => getTrainings(getChangedBySearchFilter(filter, value));
  const sortTable = (rowName) => getTrainings(getChangedBySortFilter(filter, rowName));
  const changePage = (page, param) =>
    getTrainings(getChangedByPageFilter(filter, page), param === 'AUTOLOAD');
  const applyFilter = (values) => getTrainings(getChangedByApplyFilter(filter, values));
  const changeLimit = () =>
    getTrainings(
      getChangedByLimitFilter(filter, getTableLimit(EXTERNAL_TRAININGS_TABLE_PARAMETER))
    );

  const manageGroups = () => setOpenManageGroupPopup(true);

  const isCreatedSource = (source) => source?.name === 'Created';

  const handleEditCertificationClick = (row) => {
    setEditingCertification(row);
    setOpenEditCertificationPopup(true);
  };

  const refreshTable = () => getTrainings(filter);

  const createCertification = () => setOpenEditCertificationPopup(true);

  const deleteCertification = (deletedRow) =>
    setModalData({ isOpened: true, deletedRow, ...DELETE_CERTIFICATION });

  const closeModal = () => setModalData({});
  const agreeModal = () => {
    const data = modalData.deletedRow;
    const allowDelete = modalData.type === CONFIRM_DELETE_CERTIFICATION_TYPE;

    deleteCertificationAction({ id: data.id, allowDelete }).then((res) => {
      if (isSuccessfulStatus(res.status)) {
        setModalData({});
        refreshTable();
        return;
      }

      if (res.status === 400) {
        const errorMessage = res?.errors?.Id[0] || '';
        if (errorMessage.indexOf('This parameter is used') >= 0) {
          setModalData({ ...modalData, ...CONFIRM_DELETE_CERTIFICATION });
        }
      }
    });
  };

  const HeaderControls = () => (
    <div className={styles.header_controls}>
      <BlueButton
        label="Create Certification"
        fullOnMobile
        height="30px"
        onClick={createCertification}
      />
      <BlueButton label="Manage Groups" fullOnMobile height="30px" onClick={manageGroups} />
    </div>
  );

  return (
    <SummaryWrapper>
      <ConfirmationPopup data={modalData} onAgree={agreeModal} onDismiss={closeModal} />
      {openManageGroupPopup && (
        <ManageGroupPopup open={openManageGroupPopup} setOpen={setOpenManageGroupPopup} />
      )}
      {openEditCertificationPopup && (
        <EditCertificationPopup
          open={openEditCertificationPopup}
          setOpen={setOpenEditCertificationPopup}
          data={editingCertification}
          setData={setEditingCertification}
          refreshTable={refreshTable}
        />
      )}
      <SummaryHeaderWrapper>
        <SummaryPageHeader title={isMobile ? 'Certifications' : 'Training & Certifications'}>
          {!isMobile && <HeaderControls />}
        </SummaryPageHeader>
        <SummaryTableControls>
          <Filter onApply={applyFilter} />
          <SearchField filterValue={filters.searchQuery} onSearch={findMatches} />
        </SummaryTableControls>
      </SummaryHeaderWrapper>

      {isMobile && <HeaderControls />}

      <TableWrapper ref={tableRef}>
        <Table stickyHeader={!isMobile}>
          <TableHeadComponent config={tableConfig} sortRules={sortRules} onSortApply={sortTable} />
          <TableBody>
            {tableState?.length
              ? tableState.map((row, index) => (
                  <StyledTableRow key={index} className={styles.row_hover}>
                    <StyledTableCell className={styles.nameCell}>{row?.name || ''}</StyledTableCell>
                    <StyledTableCell className={styles.sourceCell}>
                      {row?.source?.displayName || ''}
                    </StyledTableCell>
                    <StyledTableCell className={styles.availableCell}>
                      {'available' in row && <CustomCheckbox value={row['available']} isDisabled />}
                    </StyledTableCell>
                    <StyledTableCell className={styles.groupCell}>
                      {row?.group?.name || ''}
                    </StyledTableCell>
                    <StyledTableCell className={styles.controlsCell}>
                      <div className={styles.controls}>
                        <IconButton
                          className={styles.icon_button}
                          onClick={() => handleEditCertificationClick(row)}>
                          <CreateIcon />
                        </IconButton>

                        {isCreatedSource(row.source) && (
                          <IconButton
                            className={styles.icon_button}
                            onClick={() => deleteCertification(row)}>
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              : responseReceived && <NoDataTableRow isTableFiltered={isTableFiltered} />}
          </TableBody>
        </Table>
      </TableWrapper>

      <Pagination
        withOptionAll
        rowsNumber={tableState?.length}
        currentPage={pagination?.page}
        totalPages={pagination.totalPages}
        onChangePage={changePage}
        onChangeLimit={changeLimit}
        selectedLimit={pagination.limit}
        tableName={EXTERNAL_TRAININGS_TABLE_PARAMETER}
        tableRef={tableRef}
      />
    </SummaryWrapper>
  );
}
