import React from 'react';
import styles from './FilterChips.module.scss';

import { Chip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

export default function FilterChips({ chips, onDelete }) {
  return (
    !!chips?.length && (
      <div className={styles.container}>
        {chips.map((chip) => (
          <Chip
            key={chip.chipId}
            label={chip.label}
            deleteIcon={<CloseIcon />}
            className={styles.chip}
            onDelete={onDelete ? () => onDelete(chip) : null}
          />
        ))}
      </div>
    )
  );
}
