import React, { useState } from 'react';
import styles from './BodyCells.module.scss';

import { StyledTableCell } from 'components/StyledComponents';
import SetEmploymentPopup from 'components/SetEmploymentPopup/SetEmploymentPopup';

import clsx from 'clsx';
import { emptyValue, formatDate, removeCharacterZ, truncateString } from 'helpers/AppHelpers';

import { useTrackerActions, useTrackerSelector } from 'hooks/TeamMemberTracker';
import { useUserConfig } from 'hooks/useUserConfig';

export default function EmploymentCell({ data, teamMember, isContractSCA }) {
  const [openPopup, setOpenPopup] = useState(false);
  const [popupData, setPopupData] = useState({});

  const {
    isAdminUser,
    isPersonnelManagerUser,
    isTeamMemberUser,
    isTeamMemberAttachedToTheProjects
  } = useUserConfig();

  const isCellClickAvailable =
    isAdminUser ||
    isPersonnelManagerUser ||
    (isTeamMemberUser && isTeamMemberAttachedToTheProjects);

  const { filter, isMasterSelected } = useTrackerSelector();
  const { getTrackerDataAction, getEventAction } = useTrackerActions();

  const onCellClick = () => {
    if (!data?.employmentId && !isAdminUser && !isPersonnelManagerUser) return;

    if (!isCellClickAvailable) return;

    if (data?.employmentId) {
      getEventAction(data.employmentId).then((res) => {
        if (res?.id) {
          setPopupData({
            ...res,
            start: formatDate(removeCharacterZ(res.start)),
            end: formatDate(removeCharacterZ(res.end))
          });
          setOpenPopup(true);
        }
      });
    } else {
      const selectedDay = removeCharacterZ(data.day);
      setPopupData({
        start: formatDate(selectedDay),
        end: formatDate(selectedDay),
        isSendNotification: false,
        teamMembers: [teamMember.id]
      });
      setOpenPopup(true);
    }
  };

  const isProjectsFilterApplied = () => !!filter?.filters?.projectIds?.length;

  const isEmploymentProject = (employment) => employment?.name === 'Project';

  const shouldHighlight = () =>
    isMasterSelected
      ? isProjectsFilterApplied() &&
        isEmploymentProject(data?.employment) &&
        !data?.['isCurrentProject']
      : isEmploymentProject(data?.employment) && !data?.['isCurrentProject'];

  const handleUpdateTable = () => {
    getTrackerDataAction(
      { ...filter, filters: { ...filter.filters, columnPage: 1 } },
      { isMasterSelected }
    );
  };

  return (
    <>
      {openPopup && (
        <SetEmploymentPopup
          open={openPopup}
          setOpen={setOpenPopup}
          data={popupData}
          getEvents={handleUpdateTable}
        />
      )}

      <StyledTableCell className={styles.employmentCell} onClick={onCellClick}>
        <div
          style={{ backgroundColor: data?.employment?.color }}
          className={clsx(
            styles.employmentBlock,
            isContractSCA && isEmploymentProject(data?.employment) && styles.contractSCABlock,
            shouldHighlight() && styles.notCurrentProject,
            !!data.note && styles.described
          )}>
          <span>
            {truncateString(data?.project?.jobNumber || data?.employment?.name, 10) || emptyValue}
          </span>
        </div>
      </StyledTableCell>
    </>
  );
}
