import React, { useEffect, useState } from 'react';
import styles from './AddAssetTickets.module.scss';

import MainButton from 'components/StyledComponents/MainButton';

import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

import { tabs, defaultTicketModel, getUpdatedTabValues, getOtherTabNames } from '../helpers';

import { ADD_ASSET_TICKET } from 'constants/dialogPopupsData';

import clsx from 'clsx';

import { useBatchAssetUpdatesActions, useBatchAssetUpdatesSelector } from 'hooks/BatchAssetUpdates';

import ConfirmationPopup from 'components/ConfirmationPopup/ConfirmationPopup';
import { BlueButton } from 'components/Buttons';
import Separator from 'components/Separator/Separator';

import { useFieldArray, useFormContext } from 'react-hook-form';

import FormImagesUpload from 'components/FormComponents/FormImagesUpload/FormImagesUpload';
import FormVideosUpload from 'components/FormComponents/FormVideosUpload/FormVideosUpload';
import FormFilesUpload from 'components/FormComponents/FormFilesUpload/FormFilesUpload';
import FormTextArea from 'components/FormComponents/FormTextArea/FormTextArea_v2';
import FormCheckbox from 'components/FormComponents/FormCheckbox/FormCheckbox';
import { FormMultipleAssetSelect, FormSelectInput, FormLabel } from 'components/FormComponents';
import FormAutoMultiSelect from 'components/FormComponents/FormAutoMultiSelect/FormAutoMultiSelect';

import { getErrorsProperties } from 'helpers/ErrorValidator';
import { enrichNameToId, isSuccessfulStatus, pluck } from 'helpers/AppHelpers';

export default function AddAssetTickets() {
  const tabName = tabs.addAssetTicket;
  const formArrayName = 'createServiceTickets';
  const getFieldName = (name, index) => `${tabName}.${formArrayName}[${index}].${name}`;

  const { control, setValue, formState, getValues, clearErrors, setError } = useFormContext();
  const { errors } = formState;
  const { fields, append, remove, update, replace } = useFieldArray({
    control,
    name: `${tabName}.${formArrayName}`
  });
  const isFormInvalid = () => !!Object.values(errors).filter(Boolean).length;
  const clearFieldError = (name, index) =>
    errors?.[tabName]?.[formArrayName]?.[index]?.[name]?.message &&
    clearErrors(getFieldName(name, index));

  const { severities, locations } = useBatchAssetUpdatesSelector();
  const { getTicketTypesAction, getRootCausesAction, createTicketsAction } =
    useBatchAssetUpdatesActions();

  const [modalData, setModalData] = useState({});
  const [renderCount, setRenderCount] = useState(0);

  const getFieldOptions = (assets, index) => {
    const query = { assetsId: pluck(assets, 'id') };

    getTicketTypesAction(query).then((ticketTypeList) => {
      getRootCausesAction(query).then((rootCauseList) => {
        setValue(getFieldName('ticketTypeList', index), ticketTypeList);
        setValue(getFieldName('ticketType', index), null);
        setValue(getFieldName('rootCauseList', index), rootCauseList);
        setValue(getFieldName('rootCause', index), null);
        setValue(getFieldName('severity', index), {});
        setValue(getFieldName('severityList', index), severities);
        setRenderCount(renderCount + 1);
      });
    });
  };

  useEffect(() => {
    if (!severities?.length) return;

    if (getValues(getFieldName('assets', 0))?.length) {
      getFieldOptions(getValues(getFieldName('assets', 0)), 0);
    } else {
      setValue(getFieldName('severityList', 0), severities);
    }
  }, [severities]);

  const validateForm = () => {
    const setFieldError = (name, index, errorMessage) =>
      setError(getFieldName(name, index), getErrorsProperties(errorMessage));

    let isValid = true;

    const { createServiceTickets } = getValues(tabName);

    createServiceTickets.forEach((ticket, ticketIndex) => {
      if (!ticket?.assets?.length) {
        setFieldError('assets', ticketIndex, 'Assets field is required');
        isValid = false;
      }
      if (!ticket?.severity?.name) {
        setFieldError('severity', ticketIndex, 'Severity field is required');
        isValid = false;
      }
      if (!ticket?.description) {
        setFieldError('description', ticketIndex, 'Description field is required');
        isValid = false;
      }
    });
    return isValid;
  };

  const clearForm = () => replace([defaultTicketModel]);

  const removeTicket = (index) => {
    if (index === 0) setCommonAssets(getValues(getFieldName('assets', 1)))
    remove(index);
  }

  const addTicket = () => {
    if (!validateForm()) return;
    append({ ...defaultTicketModel, severityList: severities });
  };

  const setCommonAssets = (assets) => {
    getOtherTabNames(tabName).forEach((name) =>
      setValue(name, getUpdatedTabValues(name, assets, getValues()))
    );
  };

  const closeModal = () => setModalData({});
  const agreeModal = () => {
    createTicketsAction(getValues(tabName)).then((res) => {
      if (!isSuccessfulStatus(res.status)) return;

      setCommonAssets([]);
      clearForm();
    });
    closeModal();
  };

  const handleAssetSelect = (assets, { index }) => {
    index === 0 && setCommonAssets(assets);
    clearFieldError('assets', index);

    if (!assets?.length) {
      update(index, {
        ...defaultTicketModel,
        severityList: severities,
        filterLocationIds: getValues(getFieldName('filterLocationIds', index))
      });

      clearFieldError('severity', index);
      clearFieldError('description', index);
      return;
    }

    getFieldOptions(assets, index);
  };

  const selectType = ({ name, value, index }) => {
    setValue(name, value);

    const severityName = getFieldName('severity', index);
    const severityValue =
      value?.severities?.length === 1
        ? { id: value.severities[0].name, ...value.severities[0] }
        : {};
    selectSeverity({ name: severityName, value: severityValue, index });
    setValue(
      getFieldName('severityList', index),
      value?.severities?.length ? value.severities : severities
    );

    const descriptionName = getFieldName('description', index);
    setValue(descriptionName, value?.description || getValues(descriptionName) || '');
    clearFieldError('description', index);

    setRenderCount(renderCount + 1);
  };

  const selectRootCause = ({ name, value }) => setValue(name, value);

  const selectSeverity = ({ name, value, index }) => {
    clearFieldError('severity', index);
    setValue(name, value);
  };

  const handleUpdateClick = () => {
    if (!validateForm()) return;
    setModalData({ ...ADD_ASSET_TICKET, isOpened: true });
  };

  const handleLocationSelect = () => setRenderCount(renderCount + 1);

  return (
    <section className={styles.wrapper}>
      <ConfirmationPopup data={modalData} onAgree={agreeModal} onDismiss={closeModal} />
      <div className={styles.form}>
        {!!fields?.length &&
          fields.map((ticket, index) => (
            <div key={ticket.id} className={styles.ticket}>
              <div className={styles.ticket__filter}>
                <FormLabel>Narrow Assets by location</FormLabel>
                <FormAutoMultiSelect
                  name={getFieldName('filterLocationIds', index)}
                  menuItems={locations || []}
                  options={{
                    label: 'siteCode',
                    disableByObjectTracker: true,
                    disableLabel: true,
                    extraAction: handleLocationSelect
                  }}
                />
              </div>
              <div className={styles.ticket__assets}>
                <FormLabel required>Asset</FormLabel>
                <FormMultipleAssetSelect
                  withQr
                  name={getFieldName('assets', index)}
                  onSelectTriggered={handleAssetSelect}
                  index={index}
                  queryOptions={{
                    locationIds: getValues(getFieldName('filterLocationIds', index))
                  }}
                />
              </div>
              <div className={styles.ticket__row}>
                <FormLabel>Ticket Type</FormLabel>
                <FormSelectInput
                  clearable
                  name={getFieldName('ticketType', index)}
                  options={getValues(getFieldName('ticketTypeList', index)) || []}
                  onSelect={selectType}
                  index={index}
                />
              </div>
              <div className={styles.ticket__row}>
                <FormLabel>Root Cause</FormLabel>
                <FormSelectInput
                  name={getFieldName('rootCause', index)}
                  options={getValues(getFieldName('rootCauseList', index)) || []}
                  onSelect={selectRootCause}
                  index={index}
                />
              </div>
              <div className={styles.ticket__row}>
                <FormLabel required>Severity</FormLabel>
                <FormSelectInput
                  name={getFieldName('severity', index)}
                  options={enrichNameToId(getValues(getFieldName('severityList', index)))}
                  onSelect={selectSeverity}
                  index={index}
                />
              </div>
              <div className={clsx(styles.ticket__row, styles.alignTop)}>
                <FormLabel>Photo</FormLabel>
                <FormImagesUpload name={getFieldName('resources', index)} />
              </div>
              <div className={clsx(styles.ticket__row, styles.alignTop)}>
                <FormLabel>Video</FormLabel>
                <FormVideosUpload name={getFieldName('resources', index)} />
              </div>
              <div className={clsx(styles.ticket__row, styles.alignTop)}>
                <FormLabel>File</FormLabel>
                <FormFilesUpload name={getFieldName('resources', index)} />
              </div>
              <div className={styles.ticket__column}>
                <FormLabel required>Description</FormLabel>
                <FormTextArea name={getFieldName('description', index)} options={{ max: 1000 }} />
              </div>
              <div className={clsx(styles.ticket__row, styles.checkbox)}>
                <FormLabel>Open?</FormLabel>
                <FormCheckbox name={getFieldName('isOpened', index)} />
              </div>
              {fields.length > 1 && (
                <div className={styles.delete_button}>
                  <BlueButton label="Delete" onClick={() => removeTicket(index)} fullOnMobile>
                    <DeleteIcon />
                  </BlueButton>
                </div>
              )}
              {fields.length > 1 && <Separator />}
            </div>
          ))}

        <BlueButton label="Add ticket" onClick={addTicket} fullOnMobile>
          <AddIcon />
        </BlueButton>
      </div>
      <div className={styles.footer}>
        <MainButton
          text="Submit all asset tickets"
          type="primary"
          action={handleUpdateClick}
          isDisabled={isFormInvalid()}
        />
      </div>
    </section>
  );
}
