import React, { useEffect, useState } from 'react';
import styles from './TicketEditForm.module.scss';

import { useTicketSelector } from 'hooks/Ticket';

import Separator from 'components/Separator/Separator';
import { FormLabel } from 'components/FormComponents';
import FormAutoMultiSelect from 'components/FormComponents/FormAutoMultiSelect/FormAutoMultiSelect';
import FormChipsContainer from 'components/FormComponents/FormChipsContainer/FormChipsContainer';
import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';
import { AddNewButton } from 'components/Buttons';

import { useFormContext, Controller } from 'react-hook-form';
import clsx from 'clsx';
import { enqueueErrorSnackbar } from 'helpers/AppHelpers';
import { ERROR_ENTITY_EXIST } from 'constants/infoSnackbarData';

export default function RequiredItems() {
  const [looseItemOptions, setLooseItemOptions] = useState([]);

  const { control, getValues, setValue } = useFormContext();

  const { creationForm } = useTicketSelector();
  const { assetPrefixes, looseItemCategories, looseItems } = creationForm;

  useEffect(() => {
    setLooseItemOptions(looseItems || []);
  }, [looseItems]);

  const handleCategorySelect = (_, value) => {
    if (value?.length) {
      const filteredItems = looseItems.filter(
        ({ looseItemCategories }) =>
          !!looseItemCategories.filter(({ id }) => value.includes(id)).length
      );
      setLooseItemOptions(filteredItems);
    } else {
      setLooseItemOptions(looseItems);
    }
  };

  const addCustomField = (entityName) => {
    const items = getValues('otherItems');
    const names = items.map((el) => el.entityName.toLowerCase());

    if (names.includes(entityName.toLowerCase())) {
      enqueueErrorSnackbar(ERROR_ENTITY_EXIST);
      return;
    }

    items.push({ entityName });
    setValue('otherItems', items);
    setValue('otherItemName', '');
  };

  return (
    <div className={styles.required_items}>
      <h3>Necessary Items</h3>

      <div className={styles.required_items__block}>
        <FormLabel bold>Loose Items</FormLabel>
        <div className={styles.required_items__block_row}>
          <FormLabel>Narrow by Category</FormLabel>
          <FormAutoMultiSelect
            name="narrowCategoryIds"
            menuItems={looseItemCategories || []}
            options={{
              label: 'name',
              disableByObjectTracker: true,
              disableLabel: true,
              extraAction: handleCategorySelect
            }}
          />
        </div>
        <div className={styles.required_items__block_row}>
          <FormLabel>Loose Item name</FormLabel>
          <FormAutoMultiSelect
            name="looseItems"
            menuItems={looseItemOptions || []}
            options={{
              label: 'name',
              disableByObjectTracker: false,
              hideTags: true
            }}
          />
        </div>
        <FormChipsContainer fieldName="looseItems" label="name" deleteParameter="id" />
      </div>

      <Separator />

      <div className={styles.required_items__block}>
        <FormLabel bold>Assets</FormLabel>
        <div className={styles.required_items__block_row}>
          <FormLabel>Prefix</FormLabel>
          <FormAutoMultiSelect
            name="assetPrefixes"
            menuItems={assetPrefixes || []}
            options={{
              label: 'prefix',
              disableByObjectTracker: false,
              disableLabel: true,
              hideTags: true
            }}
          />
        </div>
        <FormChipsContainer fieldName="assetPrefixes" label="prefix" deleteParameter="id" />
      </div>

      <Separator />

      <div className={styles.required_items__block}>
        <FormLabel bold>Others</FormLabel>
        <div className={clsx(styles.required_items__block_row, styles.custom)}>
          <FormLabel>Name</FormLabel>
          <div className={styles.otherField}>
            <FormInputText name="otherItemName" options={{ max: 100 }} />
            <span className={styles.tip}>Write to add a new field</span>
            <Controller
              control={control}
              name="otherItemName"
              render={({ field: { value } }) => (
                <AddNewButton onClick={() => addCustomField(value)} isDisabled={!value?.length} />
              )}
            />
          </div>
        </div>
        <FormChipsContainer
          fieldName="otherItems"
          label="entityName"
          deleteParameter="entityName"
        />
      </div>
    </div>
  );
}
