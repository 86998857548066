import React, { useEffect, useState } from 'react';
import styles from './GenerateQRCodesPopup.module.scss';

import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import { MainButton } from 'components/StyledComponents';
import DialogHeader from 'components/DialogComponents/DialogHeader';
import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';
import { FormMultipleAssetSelect } from 'components/FormComponents';
import FormInputRadio from 'components/FormComponents/FormInputRadio/FormInputRadio';

import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import clsx from 'clsx';

import { getErrorsProperties } from 'helpers/ErrorValidator';
import { fileDownload } from 'helpers/AppHelpers';

import { generateQRCodes, getQRCodesGeneratorCreationForm } from 'actions/assetActions';

export default function GenerateQRCodesPopup({ open, setOpen, title, prefilledAssets }) {
  const dispatch = useDispatch();

  const [options, setOptions] = useState({});
  const { qrCodeSizes, pageSizes } = options;

  const methods = useForm({
    defaultValues: { assets: [], pageSize: null, qrCodeSize: null, qty: 1 },
    mode: 'onChange'
  });
  const { getValues, setError, formState, reset } = methods;
  const { errors } = formState;
  const isFormInvalid = () => !!Object.values(errors).filter(Boolean).length;

  useEffect(() => {
    dispatch(getQRCodesGeneratorCreationForm()).then((res) => setOptions(res || {}));
  }, []);

  useEffect(() => {
    if (prefilledAssets?.length) {
      reset({ ...getValues(), assets: prefilledAssets });
    }
  }, [prefilledAssets]);

  const validateForm = () => {
    const fieldsToValidate = [
      {
        name: 'assets',
        errorMessage: 'Field is required',
        isInvalid: !getValues('assets')?.length
      },
      {
        name: 'qty',
        errorMessage: 'Can’t be less than "1" and more than "100"',
        isInvalid: !getValues('qty') || getValues('qty') < 1 || getValues('qty') > 100
      }
    ];

    let isFormValid = true;
    fieldsToValidate.forEach(({ name, errorMessage, isInvalid }) => {
      if (isInvalid) {
        setError(name, getErrorsProperties(errorMessage), { shouldFocus: true });
        isFormValid = false;
      }
    });
    return isFormValid;
  };

  const closePopup = () => setOpen(false);

  const handleGenerateClick = () => {
    if (!validateForm()) return;

    dispatch(generateQRCodes(getValues())).then((res) => {
      if ([200, 201].includes(res.status)) {
        fileDownload(res.data);
        closePopup();
      }
    });
  };

  return (
    !!qrCodeSizes?.length && (
      <DialogWrapper open={open} onClose={closePopup}>
        <div className={styles.dialog}>
          <DialogHeader onClose={closePopup} title={title} classes={styles.title} />

          <div className={styles.content}>
            <FormProvider {...methods}>
              <div className={styles.form}>
                <div className={clsx(styles.form__item, styles.column)}>
                  <label>Enter Asset DR Codes; codes must be valid DR Codes*</label>
                  <FormMultipleAssetSelect name="assets" />
                </div>

                {!!qrCodeSizes?.length && (
                  <div className={clsx(styles.form__item, styles.column)}>
                    <label>Select QR code size</label>
                    <FormInputRadio name="qrCodeSize" options={qrCodeSizes || []} />
                  </div>
                )}

                {!!pageSizes?.length && (
                  <div className={clsx(styles.form__item, styles.column)}>
                    <label>Select paper size</label>
                    <FormInputRadio name="pageSize" options={pageSizes || []} />
                  </div>
                )}

                <div className={clsx(styles.form__item, styles.row)}>
                  <label>Set qty of QR codes per Asset*</label>
                  <FormInputText
                    name="qty"
                    options={{ focus: true, type: 'quantity' }}
                    classes={styles.inputs__qty}
                  />
                </div>
              </div>
            </FormProvider>
          </div>

          <div className={styles.footer}>
            <MainButton text="Cancel" action={closePopup} type="secondary" />
            <MainButton
              text="Generate"
              action={handleGenerateClick}
              type="primary"
              isDisabled={isFormInvalid()}
            />
          </div>
        </div>
      </DialogWrapper>
    )
  );
}
