import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useHistory } from 'react-router-dom';
import { selectUserConfig } from '../../commonSelectors';
import { setTopPosition, updateUserConfig } from 'actions/commonActions';
import { selectReportTemplateListData, selectReportTemplatesFilter } from '../selectors';
import {
  getReportTemplateList,
  copyReportTemplate,
  deleteReportTemplate,
  clearReportListState,
  setReportTemplatesFilter
} from 'actions/reportListActions';
import { getPagesLimit, emptyValue, dateTimeFormat, formatDate } from 'helpers/AppHelpers';
import DialogPopup from 'components/DialogPopup/DialogPopup';
import ReportTemplateFilter from './ReportTemplateFilter';
import TablePagination from 'components/TablePagination/TablePagination';
import { MainButton, StyledTableCell, StyledTableRow } from 'components/StyledComponents/';
import {
  makeStyles,
  InputAdornment,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
  Paper,
  IconButton
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import ExpandMore from '@material-ui/icons/ExpandMore';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import { REPORT_TEMPLATE_EDITOR, ROOT_PATH } from 'constants/routeConstants';
import { DELETE_REPORT_TEMPLATE } from 'constants/dialogPopupsData';
import styles from './ReportTemplateList.module.scss';
import { useMobileViewport } from 'hooks/useMobileViewport';
import LinkComponent from 'components/LinkComponent/LinkComponent';

const useStyles = makeStyles(() => ({
  searchBarStyle: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        border: '1px solid #999',
        boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.1)'
      }
    }
  }
}));

function ReportTemplateList({
  userConfig,
  updateUserConfigAction,
  filter,
  setFilter,
  reportTemplateListData,
  getReportTemplateListAction,
  copyReportTemplateAction,
  deleteReportTemplateAction,
  setTopPositionAction,
  clearReportTemplateListAction
}) {
  const isMobile = useMobileViewport();
  const classes = useStyles();
  const history = useHistory();
  const tableRef = useRef(null);

  const [tableState, setTableState] = useState([]);

  const [openFilter, setOpenFilter] = useState(false);
  const [dialogModalData, setDialogModalData] = useState({
    isOpened: false
  });

  const { filters, pagination, sortRules } = filter;

  const getReportTemplates = (inputFilter, paginationChanged) => {
    getReportTemplateListAction({
      ...inputFilter,
      pagination: {
        ...pagination,
        page: paginationChanged ? 1 : pagination.page,
        limit: getPagesLimit(userConfig, 'ReportTemplateSummary', isMobile)
      }
    });
  };

  const setReportTemplates = () => {
    if (
      reportTemplateListData.pageNumber === 1 ||
      getPagesLimit(userConfig, 'ReportTemplateSummary', isMobile) !== 100
    ) {
      setTableState(reportTemplateListData?.items);
    } else {
      setTableState(tableState.concat(reportTemplateListData?.items));
    }
    setFilter({
      ...filter,
      pagination: {
        limit: reportTemplateListData.limit,
        page: reportTemplateListData.pageNumber,
        totalPages: reportTemplateListData.totalPages
      }
    });
  };

  useEffect(() => {
    if ('tables' in userConfig) {
      if (!reportTemplateListData.totalPages && !reportTemplateListData?.items?.length) {
        getReportTemplates(filter);
      } else {
        setReportTemplates();
      }
    }
  }, [userConfig, reportTemplateListData]);

  useEffect(() => {
    isMobile && history.push(ROOT_PATH);
  }, [isMobile]);

  useEffect(() => {
    return () => {
      clearReportTemplateListAction();
    };
  }, []);

  const emptyResponse = () => {
    return (
      !reportTemplateListData?.items?.length &&
      pagination?.page === reportTemplateListData?.pageNumber
    );
  };

  const emptyResponseWithFilters = () => {
    return (
      !reportTemplateListData?.items?.length &&
      filters.searchQuery === '' &&
      filters.showPublished === null &&
      !filters.generalReportTypeName.length &&
      pagination?.page === reportTemplateListData?.pageNumber
    );
  };

  const onClearField = () => {
    const currFilter = {
      ...filter,
      filters: {
        ...filters,
        searchQuery: ''
      }
    };
    setFilter(currFilter);
    getReportTemplateListAction({
      ...currFilter,
      pagination: { ...pagination, page: 1 }
    });
  };

  const onApply = (resultFilter) => {
    getReportTemplateListAction({
      ...resultFilter,
      pagination: {
        ...pagination,
        page: 1
      }
    });
  };

  const onInput = (event) => {
    event.target.value = event.target.value.replace(/[А-Яа-я]/g, '').trimStart();
    const currFilter = {
      ...filter,
      filters: {
        ...filters,
        searchQuery: event.target.value
      }
    };
    setFilter(currFilter);
    console.log(currFilter);
    if (event.key === 'Enter' || (filters.searchQuery.length && !event.target.value)) {
      getReportTemplates({
        ...currFilter,
        pagination: {
          ...pagination,
          page: 1
        }
      });
    }
  };

  const onSortApply = (rowName) => {
    let currFilter = {};
    if (sortRules.indexOf(rowName) >= 0) {
      if (sortRules.indexOf('-') >= 0) {
        currFilter = { ...filter, sortRules: rowName };
      } else {
        currFilter = { ...filter, sortRules: `-${rowName}` };
      }
    } else {
      currFilter = { ...filter, sortRules: rowName };
    }
    setFilter(currFilter);
    getReportTemplateListAction({
      ...currFilter,
      pagination: { ...pagination, page: 1 }
    });
  };

  const isSorted = (rowName) => {
    return sortRules.indexOf(rowName) >= 0;
  };

  const isAscend = (rowName) => {
    return sortRules.indexOf(rowName) >= 0 && sortRules.indexOf('-') >= 0;
  };

  const onReportCopyClick = (id) => {
    copyReportTemplateAction(id);
  };

  const onAgree = () => {
    deleteReportTemplateAction(dialogModalData.templateId, () => {
      getReportTemplates(filter);
      setDialogModalData({
        isOpened: false
      });
    });
  };

  const onPageSelect = (page) => {
    const currFilter = { ...filter, pagination: { ...pagination, page: page } };
    setFilter(currFilter);
    getReportTemplateListAction(currFilter);
    if (getPagesLimit(userConfig, 'ReportTemplateSummary', isMobile) !== 100) {
      const elemRectData = tableRef.current?.getBoundingClientRect();
      setTopPositionAction(elemRectData?.y + (isMobile ? -65 : 0));
    }
  };

  const onLimitChange = (event) => {
    const userConfigCopy = { ...userConfig };
    const tableIndex = userConfigCopy.tables.findIndex(
      ({ name }) => name === 'ReportTemplateSummary'
    );
    userConfigCopy.tables[tableIndex].fetchRowsCount = event.target.value;
    updateUserConfigAction(userConfigCopy).then(() => getReportTemplates(filter, true));
    if (getPagesLimit(userConfig, 'ReportTemplateSummary', isMobile) !== 100) {
      const elemRectData = tableRef.current?.getBoundingClientRect();
      setTopPositionAction(elemRectData?.y + (isMobile ? -65 : 0));
    }
  };

  return (
    <>
      <DialogPopup
        data={dialogModalData}
        onAgree={onAgree}
        onDissmiss={() =>
          setDialogModalData({
            isOpened: false
          })
        }
        isMobile={isMobile}
      />
      <section className={styles.pageContainer}>
        <div className={styles.headerWrapper}>
          <div className={styles.pageHeader}>
            <h1>Report Template List</h1>
            <div className={styles.pageHeader__controls}>
              <MainButton
                text="Add new report template"
                action={() => history.push(REPORT_TEMPLATE_EDITOR)}
                type="primary"
              />
            </div>
          </div>
          <div className={styles.tableControls}>
            <ReportTemplateFilter
              filter={filter}
              setFilter={setFilter}
              onApply={onApply}
              openFilter={openFilter}
              setOpenFilter={setOpenFilter}
              isMobile={isMobile}
            />
            <div className={styles.searchPanel}>
              <TextField
                className={`${styles.searchInput} ${classes.searchBarStyle}`}
                type="text"
                placeholder="Search"
                variant="outlined"
                onChange={onInput}
                onKeyDown={onInput}
                value={filters.searchQuery}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: filters.searchQuery && (
                    <IconButton aria-label="toggle password visibility" onClick={onClearField}>
                      <CancelRoundedIcon fontSize="small" className={styles.clearIcon} />
                    </IconButton>
                  )
                }}
              />
            </div>
          </div>
        </div>
        <TableContainer
          ref={tableRef}
          className={`${styles.tableContainer} ${!isMobile && styles.fixedHeader}`}
          component={Paper}>
          <Table stickyHeader={!isMobile}>
            <TableHead>
              <TableRow>
                <StyledTableCell className={styles.firstRow}>
                  <div
                    className={`${styles.sortHeader} ${isSorted('name') ? styles.sorted : ''} ${
                      isAscend('name') ? styles.ascend : ''
                    }`}
                    onClick={() => onSortApply('name')}>
                    Name
                    <ExpandMore />
                  </div>
                </StyledTableCell>
                <StyledTableCell className={styles.secondRow} align="left">
                  <div
                    className={`${styles.sortHeader} ${
                      isSorted('reportType') ? styles.sorted : ''
                    } ${isAscend('reportType') ? styles.ascend : ''}`}
                    onClick={() => onSortApply('reportType')}>
                    Report type
                    <ExpandMore />
                  </div>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <div
                    className={`${styles.sortHeader} ${
                      isSorted('updatedAtUtc') ? styles.sorted : ''
                    } ${isAscend('updatedAtUtc') ? styles.ascend : ''}`}
                    onClick={() => onSortApply('updatedAtUtc')}>
                    Last update <ExpandMore />
                  </div>
                </StyledTableCell>
                <StyledTableCell className={styles.thirdRow} align="left">
                  <div
                    className={`${styles.sortHeader} ${
                      isSorted('published') ? styles.sorted : ''
                    } ${isAscend('published') ? styles.ascend : ''}`}
                    onClick={() => onSortApply('published')}>
                    Status
                    <ExpandMore />
                  </div>
                </StyledTableCell>
                <StyledTableCell className={styles.lastRow} align="center">
                  Action
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableState?.length ? (
                tableState.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell className={styles.rightBorder} scope="row">
                      <LinkComponent
                        name={row.name || ''}
                        path={`${REPORT_TEMPLATE_EDITOR}/${row.id}`}
                        boldText
                      />
                    </StyledTableCell>
                    <StyledTableCell className={styles.rightBorder} align="left">
                      {row.reportType.displayName}
                    </StyledTableCell>
                    <StyledTableCell className={styles.rightBorder} align="left">
                      {row.updatedAtUtc ? formatDate(row.updatedAtUtc, dateTimeFormat) : emptyValue}
                      {row.lastUpdate}
                    </StyledTableCell>
                    <StyledTableCell className={styles.rightBorder} align="left">
                      {row.published ? 'Published' : 'Unpublished'}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <div className={styles.controls}>
                        <IconButton>
                          <FileCopyIcon onClick={() => onReportCopyClick(row.id)} />
                        </IconButton>
                        <IconButton>
                          <DeleteIcon
                            onClick={() =>
                              setDialogModalData({
                                ...DELETE_REPORT_TEMPLATE,
                                templateId: row.id,
                                isOpened: true
                              })
                            }
                          />
                        </IconButton>
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  {!emptyResponse() ? (
                    <StyledTableCell colSpan={5} className={styles.emptyResponseTable}>
                      {emptyResponseWithFilters() && 'No available data in the table.'}
                    </StyledTableCell>
                  ) : (
                    <StyledTableCell colSpan={5} className={styles.emptyResponseTable}>
                      No matches in the table.
                    </StyledTableCell>
                  )}
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!(isMobile && openFilter) && (
          <TablePagination
            isMobile={isMobile}
            rows={tableState?.length}
            currentPage={pagination.page}
            totalPages={pagination.totalPages}
            selectAllOption={true}
            onPageSelect={onPageSelect}
            pageLimit={pagination.limit}
            onLimitChange={onLimitChange}
            hide={emptyResponse()}
          />
        )}
      </section>
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  userConfig: selectUserConfig(),
  filter: selectReportTemplatesFilter(),
  reportTemplateListData: selectReportTemplateListData()
});

const mapDispatchToProps = {
  updateUserConfigAction: updateUserConfig,
  getReportTemplateListAction: getReportTemplateList,
  setFilter: setReportTemplatesFilter,
  copyReportTemplateAction: copyReportTemplate,
  deleteReportTemplateAction: deleteReportTemplate,
  setTopPositionAction: setTopPosition,
  clearReportTemplateListAction: clearReportListState
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ReportTemplateList);
