import { createSelector } from 'reselect';

const batchAssetUpdatesData = (state) => state.batchAssetUpdatesData;

export const selectBatchAssetUpdatesLocationsData = () =>
  createSelector([batchAssetUpdatesData], (state) => state.locations);

export const selectBatchAssetUpdatesProjectsData = () =>
  createSelector([batchAssetUpdatesData], (state) => state.projects);

export const selectBatchAssetUpdatesSeverities = () =>
  createSelector([batchAssetUpdatesData], (state) => state.severities);

export const selectBatchAssetUpdatesSelectedTab = () =>
  createSelector([batchAssetUpdatesData], (state) => state.selectedTab);

export const selectBatchAssetUpdatesRentalStatusesData = () =>
  createSelector([batchAssetUpdatesData], (state) => state.rentalStatuses);

export const selectBatchAssetUpdatesFormState = () =>
  createSelector([batchAssetUpdatesData], (state) => state.formState);
