import { getRandomId } from 'helpers/AppHelpers';

const mapItemIdsToChips = (options, ids, key, name) => {
  return ids.map((id) => {
    const obj = options?.find((option) => option.id === id) || {};
    if (obj?.id || obj?.id === 0) {
      return {
        ...obj,
        key: key,
        displayName: obj[name] || '',
        chipId: getRandomId()
      };
    }
  });
};

export const getChips = (inputFilter, options) => {
  const { externalTrainings, groupTrainings, source } = options;

  let chipsArray = [];

  if (inputFilter?.externalTrainingIds?.length) {
    chipsArray = chipsArray.concat(
      mapItemIdsToChips(externalTrainings, inputFilter.externalTrainingIds, 'training', 'name')
    );
  }

  if (inputFilter?.groupIds?.length) {
    chipsArray = chipsArray.concat(
      mapItemIdsToChips(groupTrainings, inputFilter.groupIds, 'group', 'name')
    );
  }

  if (inputFilter?.sourceIds?.length) {
    chipsArray = chipsArray.concat(
      mapItemIdsToChips(source, inputFilter.sourceIds, 'source', 'displayName')
    );
  }

  if (inputFilter?.showAvailable) {
    chipsArray.push({
      key: 'showAvailable',
      displayName: 'Available only',
      chipId: getRandomId()
    });
  }

  return chipsArray;
};

export const getValuesAfterChipDeleting = (values, chip) => {
  switch (chip.key) {
    case 'training':
      return {
        ...values,
        externalTrainingIds: values.externalTrainingIds.filter((id) => id !== chip.id)
      };
    case 'group':
      return {
        ...values,
        groupIds: values.groupIds.filter((id) => id !== chip.id)
      };
    case 'source':
      return {
        ...values,
        sourceIds: values.sourceIds.filter((id) => id !== chip.id)
      };
    case 'showAvailable':
      return { ...values, showAvailable: false };
    default:
      break;
  }
};
