import React, { useEffect } from 'react';
import styles from './Filter.module.scss';

import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import { FilterActions, FilterHeader } from 'components/FilterComponentsV2';
import { FormDayPicker, FormLabel } from 'components/FormComponents';
import FormAutoMultiSelect from 'components/FormComponents/FormAutoMultiSelect/FormAutoMultiSelect';
import FormMultiSelect from 'components/FormComponents/FormMultiSelect/FormMultiSelect';
import FormCheckbox from 'components/FormComponents/FormCheckbox/FormCheckbox';

import { FormProvider, useForm } from 'react-hook-form';

import { useRequestOrderSelector } from 'hooks/RequestOrder';
import { useMobileViewport } from 'hooks/useMobileViewport';

import { enrichNameToId } from 'helpers/AppHelpers';

import clsx from 'clsx';

export default function Filter({ open, setOpen, onApply }) {
  const isMobile = useMobileViewport();

  const methods = useForm({
    defaultValues: {
      endUserIds: [],
      locationIds: [],
      looseItemIds: [],
      prefixIds: [],
      entityNames: [],
      statusNames: [],
      dateFrom: null,
      dateTo: null,
      isDeleted: false
    },
    mode: 'onChange'
  });
  const { getValues, reset, setValue, watch } = methods;

  const { filter, filterCriteria } = useRequestOrderSelector();

  const { locations, statuses, requestEntities, assetPrefixes, looseItems, endUsers } =
    filterCriteria;

  useEffect(() => {
    if (open) {
      reset(filter.filters);
    }
  }, [open]);

  const closePopup = () => setOpen(false);

  const applyFilter = () => {
    onApply(getValues());
    closePopup();
  };

  const clearFilter = () => {
    const defaultState = {
      ...getValues(),
      endUserIds: [],
      entityNames: [],
      locationIds: [],
      looseItemIds: [],
      prefixIds: [],
      statusNames: [],
      dateFrom: null,
      dateTo: null,
      isDeleted: false
    };
    reset(defaultState);
  };

  const handleMultiSelect = (name, value) => setValue(name, value);

  const dateFromWatcher = watch('dateFrom');
  const dateToWatcher = watch('dateTo');

  return (
    <DialogWrapper open={open} onClose={closePopup}>
      <div className={styles.dialog}>
        {isMobile && <FilterHeader title="Filters" onBack={closePopup} />}
        <FormProvider {...methods}>
          <div className={styles.form}>
            <div className={styles.form__block}>
              <div className={styles.form__block_cell}>
                <FormLabel>Delivery Location</FormLabel>
                <FormAutoMultiSelect
                  name="locationIds"
                  menuItems={locations || []}
                  options={{
                    label: 'siteCode',
                    disableByObjectTracker: true,
                    disableLabel: true
                  }}
                />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Status</FormLabel>
                <FormMultiSelect
                  trackById
                  name="statusNames"
                  options={enrichNameToId(statuses) || []}
                  onSelect={handleMultiSelect}
                />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>From</FormLabel>
                <FormDayPicker name="dateFrom" max={dateToWatcher || new Date()} />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>To</FormLabel>
                <FormDayPicker name="dateTo" min={dateFromWatcher} max={new Date()} />
              </div>
            </div>
            <div className={styles.form__block}>
              <div className={styles.form__block_cell}>
                <FormLabel>Entity</FormLabel>
                <FormMultiSelect
                  trackById
                  name="entityNames"
                  options={enrichNameToId(requestEntities) || []}
                  onSelect={handleMultiSelect}
                />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Prefix</FormLabel>
                <FormAutoMultiSelect
                  name="prefixIds"
                  menuItems={assetPrefixes || []}
                  options={{
                    label: 'prefix',
                    disableByObjectTracker: true,
                    disableLabel: true
                  }}
                />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Loose Item</FormLabel>
                <FormAutoMultiSelect
                  name="looseItemIds"
                  menuItems={looseItems || []}
                  options={{
                    label: 'name',
                    disableByObjectTracker: true,
                    disableLabel: true
                  }}
                />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>End User</FormLabel>
                <FormAutoMultiSelect
                  name="endUserIds"
                  menuItems={endUsers || []}
                  options={{
                    labelType: 'user',
                    disableByObjectTracker: true,
                    disableLabel: true
                  }}
                />
              </div>
              <div className={clsx(styles.form__block_cell, styles.checkbox)}>
                <FormLabel>Show deleted</FormLabel>
                <FormCheckbox name="isDeleted" />
              </div>
            </div>
          </div>
        </FormProvider>
        <FilterActions onClear={clearFilter} onCancel={closePopup} onApply={applyFilter} />
      </div>
    </DialogWrapper>
  );
}
