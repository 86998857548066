import * as dashboard from './dashboard';
import * as asset from './asset';
import * as category from './category';
import * as prefix from './prefix';
import * as locations from './locations';
import * as project from './project';
import * as activeLocations from './activeLocations';
import * as reportList from './reportList';
import * as ticket from './ticket';
import * as user from './user';
import * as batchAssetUpdates from './batchAssetUpdates';
import * as auth from './auth';
import * as common from './common';
import * as looseItemDashboard from './looseItemDashboard';
import * as looseItemConfigurations from './looseItemConfigurations';
import * as assembleBOM from './assembleBOM';
import * as pickList from './pickList';
import * as leaveList from './leaveList';
import * as assetCalendar from './assetCalendar';
import * as teamMember from './teamMember';
import * as setEmploymentPopup from './setEmploymentPopup';
import * as teamMemberTracker from './teamMemberTracker';
import * as importFilesPopup from './importFilesPopup';
import * as looseItemsAndPartsList from './looseItemsAndPartsList';
import * as overviewReport from './overviewReport';
import * as requestOrder from './requestOrder';
import * as employmentStatusEditor from './employmentStatusEditor';
import * as addLooseItemsPopup from './addLooseItemsPopup';
import * as billOfLading from './billOfLading';
import * as teamMemberHistory from './teamMemberHistory';
import * as addBom from './addBom';
import * as batchItemUpdates from './batchItemUpdates';
import * as looseItemCategory from './looseItemCategory';
import * as createBatchOfAssets from './createBatchOfAssets';
import * as search from './search';
import * as notificationCenter from './notificationCenter';
import * as userLogs from './userLogs';
import * as trainings from './trainings';
import * as ticketsDashboard from './ticketsDashboard';
import * as assetsDashboards from './assetsDashboards';
import * as managePicklistItem from './managePicklistItem';
import * as selectLooseItemsPopup from './selectLooseItemsPopup';
import * as ticketTypes from './ticketTypes';
import * as ticketRootCauses from './ticketRootCauses';
import * as teamMemberDashboard from './teamMemberDashboard';
import * as billOfLadingLogs from './billOfLadingLogs';
import * as addAssetsToPicklistPopup from './addAssetsToPicklistPopup';

export default {
  dashboard,
  asset,
  category,
  prefix,
  locations,
  activeLocations,
  project,
  reportList,
  ticket,
  user,
  batchAssetUpdates,
  auth,
  common,
  looseItemDashboard,
  looseItemConfigurations,
  assembleBOM,
  pickList,
  leaveList,
  assetCalendar,
  teamMember,
  setEmploymentPopup,
  teamMemberTracker,
  importFilesPopup,
  looseItemsAndPartsList,
  overviewReport,
  requestOrder,
  employmentStatusEditor,
  addLooseItemsPopup,
  billOfLading,
  teamMemberHistory,
  addBom,
  batchItemUpdates,
  looseItemCategory,
  createBatchOfAssets,
  search,
  notificationCenter,
  userLogs,
  trainings,
  ticketsDashboard,
  assetsDashboards,
  managePicklistItem,
  selectLooseItemsPopup,
  ticketTypes,
  ticketRootCauses,
  teamMemberDashboard,
  billOfLadingLogs,
  addAssetsToPicklistPopup
};
