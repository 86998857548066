import { useSelector } from 'react-redux';
import {
  selectPicklistAssetsCounting,
  selectSinglePicklistData
} from 'pages/PicklistAndLeavelist/PicklistSummary/selectors';

export function usePicklistSelector() {
  const currentPicklist = useSelector(selectSinglePicklistData());

  const assetsCounting = useSelector(selectPicklistAssetsCounting());

  return {
    currentPicklist,
    assetsCounting
  };
}
