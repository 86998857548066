import React, { useEffect } from 'react';
import styles from './AssetReportEdit.module.scss';

import { useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';

import ReportEditWrapper from '../../commonComponents/ReportEditWrapper';
import AssetReportDetails from './components/GeneralDetails';

import { useReportSelector, useReportActions } from 'hooks/ReportList';

import { formatDate, wait } from 'helpers/AppHelpers';

export default function AssetReportEdit() {
  const { id } = useParams();

  const { activeTemplate, usersForAssignList, currentGeneralReport } = useReportSelector();
  const { getUsersForAssignAction, startAutoSaveAction } = useReportActions();

  const startAutoSave = () => {
    wait(1000).then(() => startAutoSaveAction());
  };

  const methods = useForm({
    defaultValues: {
      reportType: {},
      asset: {},
      generalReportTemplate: {},
      generalReportFields: [],
      resources: [],
      assignedAtUtc: null,
      signatures: []
    },
    mode: 'onChange'
  });
  const { reset, getValues } = methods;

  const { asset, templateReport } = activeTemplate;

  useEffect(() => {
    if (!id && asset?.id && templateReport?.id) {
      const reportFields = templateReport?.generalReportFields.sort((a, b) => a.order - b.order);

      const presetValues = {
        ...getValues(),
        createdAtUtc: formatDate(new Date()),
        reportType: { ...templateReport?.reportType },
        asset: { ...asset },
        location: { ...asset.location },
        generalReportTemplate: { id: templateReport?.id, name: templateReport?.name },
        generalReportFields: [...reportFields]
      };
      reset(presetValues);
      startAutoSave();
    }
  }, [activeTemplate]);

  useEffect(() => {
    if (id && currentGeneralReport?.id) {
      reset(currentGeneralReport);
      if (!currentGeneralReport.submitted) {
        startAutoSave();
      }
    }
  }, [currentGeneralReport]);

  useEffect(() => {
    if (!usersForAssignList.length && getValues()?.asset?.location?.id) {
      getUsersForAssignAction({
        locationId: getValues().asset.location.id,
        shouldIncludeCurrentUser: true
      });
    }
  }, [usersForAssignList, getValues()?.asset?.location?.id]);

  return (
    <section className={styles.pageContainer}>
      <FormProvider {...methods}>
        <ReportEditWrapper reportType="asset">
          <AssetReportDetails />
        </ReportEditWrapper>
      </FormProvider>
    </section>
  );
}
