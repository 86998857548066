import { createSelector } from 'reselect';

const pickListData = (state) => state.pickListData;

export const selectPickListData = () => createSelector([pickListData], (state) => state.pickList);

export const selectLocationsData = () =>
  createSelector([pickListData], (state) => state.locationList);

export const selectStatusesData = () => createSelector([pickListData], (state) => state.statusList);

export const selectProjects = () => createSelector([pickListData], (state) => state.projectList);

export const selectPickListFilterData = () =>
  createSelector([pickListData], (state) => state.pickListFilter);

export const selectSinglePicklistData = () =>
  createSelector([pickListData], (state) => state.currentPicklist);

export const selectPicklistAssetStatuses = () =>
  createSelector([pickListData], (state) => state.picklistAssetStatuses);

export const selectTruckOrTrailers = () =>
  createSelector([pickListData], (state) => state.truckOrTrailers);

export const selectDrCodes = () =>
  createSelector([pickListData], (state) => state.drCodes);

export const selectPicklistAssetsCounting = () =>
  createSelector([pickListData], (state) => state.assetsCounting);
