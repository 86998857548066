import React from 'react';
import styles from './Details.module.scss';

import { useTicketSelector } from 'hooks/Ticket';

import { pluck, joinWithComma } from 'helpers/AppHelpers';

import DetailedRow from 'components/DetailsComponents/DetailedRow';
import Subtitle from 'components/DetailsComponents/Subtitle';

export default function TicketRequiredItems() {
  const { currentTicket } = useTicketSelector();

  const { looseItems, assetPrefixes, otherItems } = currentTicket;

  const details = [
    {
      id: 1,
      label: 'Entity',
      value: 'Loose Items',
      isAvailable: !!looseItems?.length
    },
    {
      id: 2,
      label: 'Loose Item',
      value: joinWithComma(pluck(looseItems, 'name')),
      isAvailable: !!looseItems?.length
    },
    {
      id: 3,
      label: 'Entity',
      value: 'Assets',
      isAvailable: !!assetPrefixes?.length
    },
    {
      id: 4,
      label: 'Prefix',
      value: joinWithComma(pluck(assetPrefixes, 'prefix')),
      isAvailable: !!assetPrefixes?.length
    },
    {
      id: 5,
      label: 'Entity',
      value: 'Others',
      isAvailable: !!otherItems?.length
    },
    {
      id: 6,
      label: 'Name',
      value: joinWithComma(pluck(otherItems, 'entityName')),
      isAvailable: !!otherItems?.length
    }
  ];

  return !!looseItems?.length || !!assetPrefixes?.length || !!otherItems?.length ? (
    <div className={styles.requiredItems}>
      <Subtitle>Necessary Items</Subtitle>
      {details.map(({ id, label, value, isAvailable }) =>
        isAvailable ? <DetailedRow key={id} label={label} value={value} disableFlex /> : null
      )}
    </div>
  ) : null;
}
