import React, { useEffect, useRef, useState } from 'react';
import styles from './PicklistSummary.module.scss';
import { MainButton, StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import { Paper, Table, TableBody, TableContainer } from '@material-ui/core';
import { createStructuredSelector } from 'reselect';
import { selectPickListData, selectPickListFilterData } from './selectors';
import { clearPickListState, getPickList, setPicklistFilter } from 'actions/pickListActions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { allKeyboardLatSymbols, formatDate, dateTimeFormat } from 'helpers/AppHelpers';
import PicklistFilter from './components/Filter/PicklistFilter';
import {
  LOCATION_DETAILED_PATH,
  PICKLIST_DETAILED_PATH,
  PICKLIST_EDIT_PATH
} from 'constants/routeConstants';
import { useHistory } from 'react-router-dom';
import { PICKLIST_TABLE_PARAMETER } from 'constants/configTableConstants';
import { tableHeadConfig } from './tableConfig';
import TableHeadComponent from 'components/SummaryComponents/TableHeadComponent/TableHeadComponent';
import {
  getChangedByApplyFilter,
  getChangedByLimitFilter,
  getChangedByPageFilter,
  getChangedBySearchFilter,
  getChangedBySortFilter
} from 'helpers/SummaryPagesHelpers';
import SearchTextFieldRefactored from 'components/SearchTextFieldRefactored/SearchTextFieldRefactored';
import TablePaginationRefactored from 'components/TablePaginationRefactored/TablePaginationRefactored';
import DownloadCSVButton from 'components/DownloadCSVButton/DownloadCSVButton';
import SummaryHeader from 'components/SummaryComponents/SummaryHeader/SummaryHeader';
import LinkComponent from 'components/LinkComponent/LinkComponent';
import EmptyTableView from 'components/SummaryComponents/EmptyTableView/EmptyTableView';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { useUserConfig } from 'hooks/useUserConfig';

function PicklistSummary({ pickList, getPickListAction, filter, setFilter, clearStateAction }) {
  const isMobile = useMobileViewport();
  const history = useHistory();
  const tableRef = useRef(null);
  const [tableState, setTableState] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);

  const { isConfigReceived, getTableLimit } = useUserConfig();

  const query = new URLSearchParams(window.location.search);
  const searchValue = query.get('searchQuery');

  const { filters, pagination, sortRules, responseReceived } = filter;

  const generateInitialQuery = () => {
    const queryFilter = {
      ...filter,
      filters: { ...filter.filters, searchQuery: searchValue || '' },
      pagination: { ...pagination, limit: getTableLimit(PICKLIST_TABLE_PARAMETER) }
    };
    if (searchValue) {
      query.delete('searchQuery');
      history.replace({ search: query.toString() });
    }
    return queryFilter;
  };

  const getPicklists = (query, isAutoload) => {
    setFilter(query);

    getPickListAction(query).then((tableData) => {
      setPicklists(tableData, query, isAutoload);
    });
  };

  const setPicklists = (data, query, isAutoload) => {
    if (!isAutoload) {
      setTableState(data?.items || []);
    } else {
      setTableState(tableState.concat(data?.items || []));
    }

    const updatedFilter = {
      ...query,
      pagination: { limit: data.limit, page: data.pageNumber, totalPages: data.totalPages },
      responseReceived: true
    };
    setFilter(updatedFilter);
  };

  useEffect(() => {
    if (!isConfigReceived) return;

    getPicklists(generateInitialQuery());
  }, [isConfigReceived]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  const emptyResponse = () => 'items' in pickList && !pickList?.items?.length;

  const emptyFilters = () => {
    return (
      filters.searchQuery === '' &&
      !filters.locationIds.length &&
      !filters.locationJobNumberIds.length &&
      !filters.statusNames.length &&
      !filters.projectIds.length &&
      filters.createdFrom === null &&
      filters.createdTo === null
    );
  };

  const findMatches = (value) => getPicklists(getChangedBySearchFilter(filter, value));
  const sortTable = (rowName) => getPicklists(getChangedBySortFilter(filter, rowName));
  const changePage = (page, param) =>
    getPicklists(getChangedByPageFilter(filter, page), param === 'AUTOLOAD');
  const applyFilter = (values) => getPicklists(getChangedByApplyFilter(filter, values));
  const changeLimit = () =>
    getPicklists(getChangedByLimitFilter(filter, getTableLimit(PICKLIST_TABLE_PARAMETER)));

  return (
    <section className={styles.pageWrapper}>
      <div className={styles.pageContainer}>
        <div className={styles.headerWrapper}>
          <SummaryHeader title="Picklist Summary">
            {!isMobile && <DownloadCSVButton filter={filter} endpoint="Picklists/Serialized" />}
            <MainButton
              text="Create picklist"
              type="primary"
              action={() => history.push(PICKLIST_EDIT_PATH)}
            />
          </SummaryHeader>
          <div className={styles.tableControls}>
            <PicklistFilter
              filter={filter}
              openFilter={openFilter}
              setOpenFilter={setOpenFilter}
              onApply={applyFilter}
              isMobile={isMobile}
            />
            <SearchTextFieldRefactored
              inputValue={filters.searchQuery}
              validationRule={allKeyboardLatSymbols}
              updateSearchParam={findMatches}
              rightSpace={isMobile}
            />
          </div>
        </div>
        {!(isMobile && openFilter) && (
          <TableContainer ref={tableRef} className={styles.tableContainer} component={Paper}>
            <Table stickyHeader={!isMobile}>
              <TableHeadComponent
                config={tableHeadConfig}
                sortRules={sortRules}
                onSortApply={sortTable}
              />
              <TableBody>
                {tableState?.length ? (
                  tableState.map((row) => (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell>
                        <LinkComponent
                          path={`${PICKLIST_DETAILED_PATH}/${row.id}`}
                          name={row.name}
                          boldText={true}
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <LinkComponent
                          path={`${LOCATION_DETAILED_PATH}/${row.location?.id}`}
                          name={row.location?.siteCode}
                          isRedirectAvailable={!row.location?.deleted}
                        />
                      </StyledTableCell>
                      <StyledTableCell>{row.sublocation?.name || ''}</StyledTableCell>
                      <StyledTableCell>{row.project?.jobNumber || ''}</StyledTableCell>
                      <StyledTableCell>{row.status?.displayName || ''}</StyledTableCell>
                      <StyledTableCell>{row.pocName || ''}</StyledTableCell>
                      <StyledTableCell>
                        {row?.['createdByUser']?.firstName + ' ' + row?.['createdByUser']?.lastName}
                      </StyledTableCell>
                      <StyledTableCell>
                        {formatDate(row?.createdAtUtc, dateTimeFormat)}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <EmptyTableView responseReceived={responseReceived} emptyFilters={emptyFilters} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
      {!(isMobile && openFilter) && (
        <TablePaginationRefactored
          isMobile={isMobile}
          rows={tableState?.length}
          currentPage={pagination.page}
          totalPages={pagination.totalPages}
          selectAllOption={true}
          onPageSelect={changePage}
          pageLimit={pagination.limit}
          hide={emptyResponse()}
          tableListParameter={PICKLIST_TABLE_PARAMETER}
          getTableData={changeLimit}
          tableRef={tableRef}
        />
      )}
    </section>
  );
}

const mapStateToProps = createStructuredSelector({
  pickList: selectPickListData(),
  filter: selectPickListFilterData()
});

const mapDispatchToProps = {
  getPickListAction: getPickList,
  setFilter: setPicklistFilter,
  clearStateAction: clearPickListState
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(PicklistSummary);
