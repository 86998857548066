import React from 'react';
import styles from './Details.module.scss';

import { useTicketSelector } from 'hooks/Ticket';

import DetailedRow from 'components/DetailsComponents/DetailedRow';
import Subtitle from 'components/DetailsComponents/Subtitle';

import { formatDate } from 'helpers/AppHelpers';

export default function ReopenTicketSettings() {
  const { currentTicket } = useTicketSelector();

  const { reopenTicketFrequency, reopenOnSpecificDate, ticketType } = currentTicket;

  const details = [
    {
      id: 1,
      label: 'Reopen Frequency',
      value: reopenTicketFrequency?.displayName || ''
    },
    {
      id: 2,
      label: 'Reopen on specific date',
      value: reopenOnSpecificDate ? formatDate(reopenOnSpecificDate) : null,
      isAvailable: reopenTicketFrequency?.name === 'OneTime'
    }
  ];

  return (
    !!ticketType?.reopening && (
      <div className={styles.reopenTicketSettings}>
        <Subtitle>Reopen ticket settings</Subtitle>
        {details.map(({ id, label, value, isAvailable = true }) =>
          isAvailable ? <DetailedRow key={id} label={label} value={value} disableFlex /> : null
        )}
      </div>
    )
  );
}
