import React, { useEffect, useState } from 'react';
import styles from './RequestOrderWrapper.module.scss';

import DialogPopup from 'components/DialogPopup/DialogPopup';
import BreadcrumbsNav from 'components/BreadcrumbsNav/BreadcrumbsNav';

import Header from './Header';
import Footer from './Footer';

import { useMobileViewport } from 'hooks/useMobileViewport';
import { useHistory, useLocation } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { useRequestOrderActions, useRequestOrderSelector } from 'hooks/RequestOrder';

import {
  CREATE_REQUEST_ORDER,
  CREATE_REQUEST_ORDER_TYPE,
  EXIT_WITHOUT_SAVING,
  WITHOUT_SAVING_BY_NAV_TYPE,
  WITHOUT_SAVING_TYPE
} from 'constants/dialogPopupsData';
import { REQUEST_ORDER_SUMMARY_PATH } from 'constants/routeConstants';

import { getErrorsProperties, isParameterInvalid } from 'helpers/ErrorValidator';

export default function RequestOrderCreateWrapper({ children }) {
  const isMobile = useMobileViewport();
  const history = useHistory();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const ticketId = searchParams.get('ticketId');

  const { getCreationFormDataAction, setUnsavedFormDataAction, createRequestAction } =
    useRequestOrderActions();
  const { unsavedFormData, creationFormData } = useRequestOrderSelector();

  const { formState, getValues, setError, setValue } = useFormContext();
  const { isDirty } = formState;

  const [modalData, setModalData] = useState({});

  useEffect(() => {
    getCreationFormDataAction({ ticketId: ticketId || null });
  }, []);

  useEffect(() => {
    if (!ticketId || !creationFormData?.locations?.length) return;

    const {
      serviceTicketAssetPrefixes,
      serviceTicketLooseItems,
      serviceTicketOtherItems,
      requestEntities,
      note
    } = creationFormData;

    if (
      !serviceTicketAssetPrefixes?.length &&
      !serviceTicketLooseItems?.length &&
      !serviceTicketOtherItems?.length
    ) {
      return;
    }

    const fieldNames = [
      'requestOrderAssetPrefixes',
      'requestOrderLooseItems',
      'requestOrderCustomEntities'
    ];

    const keys = [
      { creationFormKey: 'serviceTicketAssetPrefixes', entityName: 'Asset' },
      { creationFormKey: 'serviceTicketLooseItems', entityName: 'LooseItem' },
      { creationFormKey: 'serviceTicketOtherItems', entityName: 'Others' }
    ];

    const getEntityData = (fieldName, creationFormKey, entityName) => {
      const entity = requestEntities.find(({ name }) => name === entityName);
      const value = creationFormData[creationFormKey];
      if (!value?.length) return null;
      return {
        requestEntity: { id: entity.name, ...entity },
        [fieldName]: value
      };
    };

    const entityData = fieldNames
      .map((name, index) =>
        getEntityData(name, keys[index].creationFormKey, keys[index].entityName)
      )
      .filter(Boolean);

    setValue('requestEntityData', entityData);
    setValue('notes', note || '');
  }, [creationFormData, ticketId]);

  useEffect(() => {
    if (isDirty && !unsavedFormData) {
      setUnsavedFormDataAction(true);
    }
    return () => {
      setUnsavedFormDataAction(false);
    };
  }, [isDirty]);

  const handleCreate = () => {
    if (!validateForm()) return;
    setModalData(CREATE_REQUEST_ORDER);
  };

  const prepareData = () => {
    const requestEntityData = getValues('requestEntityData');

    requestEntityData.forEach((el) => {
      if (el.requestEntity?.displayName === 'Asset') {
        el.requestOrderAssetPrefixes = el.requestOrderAssetPrefixes.map((item) => ({
          assetPrefix: item,
          quantity: item.quantity || 0,
          resources: item?.resources || []
        }));
      }
      if (el.requestEntity?.displayName === 'Loose Item') {
        el.requestOrderLooseItems = el.requestOrderLooseItems.map((item) => ({
          looseItem: item,
          quantity: item.quantity || 0,
          resources: item?.resources || []
        }));
      }
      if (el.requestEntity?.displayName === 'Others') {
        el.requestOrderCustomEntities = el.requestOrderCustomEntities.map((item) => ({
          customEntity: item,
          quantity: item.quantity || 0,
          resources: item?.resources || []
        }));
      }
    });

    return { ...getValues(), requestEntityData };
  };

  const agreeModal = () => {
    switch (modalData.type) {
      case CREATE_REQUEST_ORDER_TYPE:
        createRequestAction(prepareData());
        break;
      case WITHOUT_SAVING_TYPE:
        history.push(REQUEST_ORDER_SUMMARY_PATH);
        break;
      case WITHOUT_SAVING_BY_NAV_TYPE:
        history.push(modalData.selectedRouteUrl);
        break;
      default:
        break;
    }
  };

  const closeDialog = () => setModalData({});

  const errorProperties = [
    { name: 'urgencyLevel', inputType: 'object', errorMessage: 'Urgency Level field is required' },
    { name: 'location', inputType: 'object', errorMessage: 'Location field is required' },
    { name: 'endEmail', inputType: 'email', errorMessage: 'Invalid email' }
  ];
  const validateForm = () => {
    let isFormValid = true;
    errorProperties.forEach(({ name, inputType, errorMessage }) => {
      if (isParameterInvalid(getValues(name), inputType)) {
        setError(name, getErrorsProperties(errorMessage));
        isFormValid = false;
      }
    });
    getValues('requestEntityData').forEach((el, i) => {
      if (!el['requestEntity']?.displayName) {
        setError(
          `requestEntityData[${i}].requestEntity`,
          getErrorsProperties('Entity field is required')
        );
        isFormValid = false;
      }
      if (el['requestEntity']?.displayName === 'Asset') {
        if (!el['requestOrderAssetPrefixes']?.length) {
          setError(
            `requestEntityData[${i}].requestOrderAssetPrefixes`,
            getErrorsProperties('Prefix field is required')
          );
          isFormValid = false;
        }
      }
      if (el['requestEntity']?.displayName === 'Loose Item') {
        if (!el['requestOrderLooseItems']?.length) {
          setError(
            `requestEntityData[${i}].requestOrderLooseItems`,
            getErrorsProperties('Category field is required')
          );
          isFormValid = false;
        }
      }
      if (el['requestEntity']?.displayName === 'Others') {
        if (!el['requestOrderCustomEntities']?.length) {
          setError(
            `requestEntityData[${i}].customName`,
            getErrorsProperties('Name field is required')
          );
          isFormValid = false;
        }
      }
    });
    return isFormValid;
  };

  const handleCancel = () => {
    if (isDirty) {
      setModalData(EXIT_WITHOUT_SAVING);
    } else {
      history.push(REQUEST_ORDER_SUMMARY_PATH);
    }
  };

  return (
    <div className={styles.wrapper}>
      <DialogPopup data={modalData} onAgree={agreeModal} onDissmiss={closeDialog} />
      {!isMobile && (
        <BreadcrumbsNav
          itemsArray={[{ name: 'Create Request' }]}
          setDialogModalData={setModalData}
          formIsChanged={isDirty}
        />
      )}
      <Header title="Create Request" onCancel={handleCancel} />
      {children}
      <Footer onClick={handleCreate} />
    </div>
  );
}
