export const dashboardTypes = {
  RR: 'RandRDashboard',
  LEVELS: 'StaffingLevelsAndAvailability',
  SNAPSHOT: 'StaffingSnapshot'
};

export const dashboardLabels = {
  RR: 'R&R Dash',
  LEVELS: 'Staffing Levels & Availability',
  SNAPSHOT: 'Staffing Snapshot'
};

export const TOGGLE_BUTTONS = [
  { id: 1, value: dashboardTypes.RR, label: dashboardLabels.RR },
  { id: 2, value: dashboardTypes.LEVELS, label: dashboardLabels.LEVELS },
  { id: 3, value: dashboardTypes.SNAPSHOT, label: dashboardLabels.SNAPSHOT }
];

export const defaultTab = dashboardTypes.RR;

export const LEVELS_BAR_COLORS = ['rgba(122, 165, 186, 1)', 'rgba(145, 144, 144, 1)'];

export const getLevelsFileName = (departmentName) =>
  `Staffing Levels & Availability ${departmentName || ''}`;

export const AVAILABILITY_PIE_LABELS = ['Unavailable', 'Available', 'Availability Not Submitted'];

export const getPieFileName = (columnName) => 'Staffing Availability' + ' ' + columnName;

export const getSnapshotFileName = (departmentName) => 'Staffing Snapshot ' + departmentName || '';

export const SNAPSHOT_BAR_COLORS = [
  'rgba(122, 165, 186, 1)',
  'rgba(145, 144, 144, 1)',
  'rgba(160, 200, 220, 1)',
  'rgba(188, 188, 188, 1)'
];

export const SNAPSHOT_TOTAL_LABEL = 'Total Staff';

const LEGEND_WIDTH = 135;
const COLUMN_WIDTH = 50;
const SPACE_WIDTH = 20;
export const getSnapshotBarWidth = (categoriesLength, durationsLength) =>
  categoriesLength * durationsLength * COLUMN_WIDTH +
  (categoriesLength + durationsLength) * SPACE_WIDTH +
  LEGEND_WIDTH;
