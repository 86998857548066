import React from 'react';
import styles from './Details.module.scss';

import { useTicketSelector } from 'hooks/Ticket';

import DetailedRow from 'components/DetailsComponents/DetailedRow';
import StyledCheckbox from 'components/StyledComponents/StyledCheckbox';

import { formatDate, getUserFullName } from 'helpers/AppHelpers';

export default function TicketAssigneeInfo() {
  const { currentTicket } = useTicketSelector();
  const { assignedToUser, assignedAtUtc, notificationReminder } = currentTicket;

  return (
    <div className={styles.assignee}>
      <DetailedRow
        disableFlex
        label="Assignee"
        value={assignedToUser?.firstName ? getUserFullName(assignedToUser) : ''}
      />
      <DetailedRow
        disableFlex
        label="Due Date"
        value={assignedAtUtc ? formatDate(assignedAtUtc) : ''}
      />
      {assignedToUser?.firstName && assignedAtUtc && (
        <div className={styles.assignee__checkbox}>
          <label>Notification Reminder</label>
          <StyledCheckbox disabled checked={!!notificationReminder} />
        </div>
      )}
    </div>
  );
}
