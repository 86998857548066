import React, { useRef, useState } from 'react';
import {
  ClickAwayListener,
  Fade,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper
} from '@material-ui/core';
import styles from './ScheduleActions.module.scss';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import SetScheduleIcon from 'assets/images/setSchedule.svg';
import PublishScheduleIcon from 'assets/images/publishSchedule.svg';
import MSIcon from 'assets/images/microsoft_icon.svg';
import CancelActionIcon from 'assets/images/cancel.svg';
import SetEmploymentPopup from 'components/SetEmploymentPopup/SetEmploymentPopup';
import { formatDate } from 'helpers/AppHelpers';
import {
  CANCEL_LAST_OPERATION,
  CANCEL_LAST_OPERATION_TYPE,
  SYNCHRONIZE_TEAM_MEMBERS_WITH_MS_CALENDAR
} from 'constants/dialogPopupsData';
import DialogPopup from 'components/DialogPopup/DialogPopup';
import { useTrackerActions, useTrackerSelector } from 'hooks/TeamMemberTracker';
import { useMicrosoftCalendarApi } from 'hooks/MicrosoftCalendar/useMicrosoftCalendarApi';
import EmploymentEditPopup from 'components/EmploymentEditPopup';
import listIcon from 'assets/images/listIcon.svg';
import capacitiesListIcon from 'assets/images/capacitiesList.svg';
import { useUserConfig } from 'hooks/useUserConfig';
import EditCapacitiesPopup from 'components/EditCapacitiesPopup';

export default function ScheduleActions() {
  const elRef = useRef();
  const [open, setOpen] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [openEmploymentEditor, setOpenEmploymentEditor] = useState(false);
  const [openCapacitiesEditor, setOpenCapacitiesEditor] = useState(false);
  const [popupData, setPopupData] = useState({});
  const [dialogModalData, setDialogModalData] = useState({ isOpened: false });

  const { isAdminUser } = useUserConfig();

  const {
    sendGroupNotificationAction,
    getTrackerDataAction,
    getPermissionToCancelLastOperationAction,
    cancelLastOperationAction
  } = useTrackerActions();
  const { isMasterSelected, filter, trackerTableData } = useTrackerSelector();

  const { shareEventsWithMicrosoft } = useMicrosoftCalendarApi(trackerTableData.teamMembers);

  const showPopper = () => setOpen(true);
  const hidePopper = () => setOpen(false);

  const setSchedule = () => {
    setOpenPopup(true);
    hidePopper();
    setPopupData({
      start: formatDate(new Date()),
      end: formatDate(new Date()),
      isSendNotification: false,
      teamMembers: []
    });
  };

  const sendNotifications = () => {
    if (!trackerTableData?.teamMembers?.length) return;
    const members = trackerTableData.teamMembers.map(({ teamMember }) => teamMember);
    sendGroupNotificationAction(members);
  };

  const synchronizeWithMSCalendar = () => {
    setDialogModalData({
      ...SYNCHRONIZE_TEAM_MEMBERS_WITH_MS_CALENDAR(trackerTableData.teamMembers.length),
      isOpened: true
    });
  };

  const closeModal = () => setDialogModalData({});
  const agreeModal = () => {
    if (dialogModalData.type === CANCEL_LAST_OPERATION_TYPE) {
      cancelLastOperationAction().then((status) => status === 200 && handleTableUpdate());
    } else {
      shareEventsWithMicrosoft();
    }
    closeModal();
  };

  const handleTableUpdate = () =>
    getTrackerDataAction(
      { ...filter, filters: { ...filter.filters, columnPage: 1 } },
      { isMasterSelected }
    );

  const cancelAction = () => {
    getPermissionToCancelLastOperationAction().then((res) => {
      if (!res) return;
      setDialogModalData({ ...CANCEL_LAST_OPERATION, isOpened: true });
    });
  };

  const editStatuses = () => {
    setOpenEmploymentEditor(true);
    hidePopper();
  };

  const editCapacities = () => {
    setOpenCapacitiesEditor(true);
    hidePopper();
  };

  return (
    <>
      {openPopup && (
        <SetEmploymentPopup
          open={openPopup}
          setOpen={setOpenPopup}
          data={popupData}
          getEvents={handleTableUpdate}
        />
      )}
      {openEmploymentEditor && (
        <EmploymentEditPopup open={openEmploymentEditor} setOpen={setOpenEmploymentEditor} />
      )}
      {openCapacitiesEditor && (
        <EditCapacitiesPopup
          open={openCapacitiesEditor}
          setOpen={setOpenCapacitiesEditor}
          onApply={handleTableUpdate}
        />
      )}
      <DialogPopup data={dialogModalData} onAgree={agreeModal} onDissmiss={closeModal} />
      <IconButton ref={elRef} onClick={(e) => showPopper(e)} className={styles.dotsButton}>
        <MoreHorizIcon />
      </IconButton>

      <Popper open={open} anchorEl={() => elRef.current} transition placement={'bottom-end'}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <Paper classes={{ root: styles.mobileContainer }}>
              <ClickAwayListener onClickAway={hidePopper}>
                <MenuList autoFocusItem={open}>
                  <MenuItem onClick={setSchedule}>
                    <img src={SetScheduleIcon} alt="set_schedule" />
                    Set schedule
                  </MenuItem>
                  {isAdminUser && (
                    <MenuItem onClick={editStatuses}>
                      <img src={listIcon} alt="list" />
                      Edit Status Values
                    </MenuItem>
                  )}
                  {isAdminUser && !isMasterSelected && (
                    <MenuItem onClick={editCapacities}>
                      <img src={capacitiesListIcon} alt="capacity_list" />
                      Edit capacity displaying
                    </MenuItem>
                  )}
                  {isMasterSelected && (
                    <MenuItem onClick={sendNotifications}>
                      <img src={PublishScheduleIcon} alt="send_notifications" />
                      Send notifications
                    </MenuItem>
                  )}
                  {isMasterSelected && (
                    <MenuItem onClick={synchronizeWithMSCalendar}>
                      <img src={MSIcon} alt="synchronize_with_ms_calendar" />
                      Synchronize with microsoft
                    </MenuItem>
                  )}
                  <MenuItem onClick={cancelAction}>
                    <img src={CancelActionIcon} alt="cancel_action" />
                    Cancel Action
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
}
