import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { getReportTemplateFilterCriteria } from 'actions/reportListActions';
import { selectReportTemplateFilterCriteria } from '../selectors';
import { enrichNameToId } from 'helpers/AppHelpers';
import FilterIcon from 'assets/images/filter.svg';
import Multiselect from 'components/Multiselect/Multiselect';
import { Backdrop, Chip, Fade, IconButton, Paper, Popper } from '@material-ui/core';
import { MainButton } from 'components/StyledComponents';
import CloseIcon from '@material-ui/icons/Close';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import styles from './ReportTemplateFilter.module.scss';
import ClearFilterButton from 'components/ClearFilterButton/ClearFilterButton';

const statusesData = [
  { id: 1, name: 'Published' },
  { id: 2, name: 'Unpublished' }
];

function ReportTemplateFilter({
  isMobile,
  filter,
  setFilter,
  onApply,
  openFilter,
  setOpenFilter,
  reportTemplateFilterCriteria,
  getReportTemplateFilterCriteriaAction
}) {
  const { filters } = filter;
  const [anchorEl, setAnchorEl] = useState(null);
  const [chips, setChips] = useState([]);
  const [values, setValues] = useState({ ...filters });
  const [statusesArray, setStatusesArray] = useState([]);
  const [isFirstOpen, setIsFirstOpen] = useState(true);
  const reportTypesData = enrichNameToId(reportTemplateFilterCriteria);

  const handleFilterOpen = (event) => {
    setOpenFilter(!openFilter);
    setAnchorEl(event.currentTarget);
    setValues(filters);
    isMobile && createChips(filters);
    if (filters.showPublished === true) setStatusesArray([1]);
    else if (filters.showPublished === false) setStatusesArray([2]);
    else setStatusesArray([]);
  };

  useEffect(() => {
    if (!reportTemplateFilterCriteria.length) {
      getReportTemplateFilterCriteriaAction();
    } else {
      createChips(filters);
    }
  }, [reportTemplateFilterCriteria]);

  const onFilterSelect = (e) => {
    let currentFilter;
    switch (e.target.name) {
      case 'reportType':
        currentFilter = {
          ...values,
          generalReportTypeName: [...e.target.value]
        };
        break;
      case 'statusType': {
        const inputValue = e.target.value.length ? [e.target.value[e.target.value.length - 1]] : [];
        setStatusesArray(inputValue);
        currentFilter = {
          ...values,
          showPublished: inputValue.includes(1) ? true : inputValue.includes(2) ? false : null
        };
        break;
      }
      default:
        break;
    }
    setValues(currentFilter);
    isMobile && createChips(currentFilter);
  };

  const createChips = (currentFilter) => {
    const chipsArray = [];
    if (currentFilter.generalReportTypeName.length) {
      currentFilter.generalReportTypeName.forEach((typeName) => {
        chipsArray.push({
          ...reportTypesData.find(({ name }) => name === typeName),
          key: 'generalReportTypeName'
        });
      });
    }
    if (currentFilter.showPublished === true) {
      chipsArray.push({ id: 'Published', key: 'showPublished', name: 'Published' });
    } else if (currentFilter.showPublished === false) {
      chipsArray.push({ id: 'Unpublished', key: 'showPublished', name: 'Unpublished' });
    }
    setChips(chipsArray);
  };

  const applyFilter = () => {
    const commonObj = { ...filter, filters: { ...values } };
    createChips(values);
    setFilter(commonObj);
    setOpenFilter(!openFilter);
    onApply(commonObj);
  };

  const handleClearFilter = () => {
    const defaultState = {
      searchQuery: '',
      generalReportTypeName: [],
      showPublished: null
    };
    setValues(defaultState);
    isMobile && setChips([]);
    setStatusesArray([]);
  };

  const handleChipDelete = (item) => {
    setChips(chips.filter((chip) => chip.id !== item.id));
    const filterObj = {
      ...values,
      generalReportTypeName:
        item.key === 'generalReportTypeName'
          ? values.generalReportTypeName.filter((id) => id !== item.id)
          : values.generalReportTypeName,
      showPublished: item.key === 'showPublished' ? null : values.showPublished
    };
    setValues(filterObj);
    setStatusesArray(item.key === 'showPublished' ? [] : [...statusesArray]);
    if (!isMobile) {
      setFilter({ ...filter, filters: { ...filterObj } });
      onApply({ ...filter, filters: { ...filterObj } });
    }
  };

  const closeFilterHandle = () => {
    setOpenFilter(!openFilter);
    isMobile && createChips(filters);
  };

  useEffect(() => {
    if (isFirstOpen) {
      createChips(filter.filters);
      setIsFirstOpen(false);
      setValues({ ...filter.filters });
    }
  }, [filter]);

  return (
    <div className={styles.filterWrapper}>
      {!isMobile && <Backdrop open={openFilter} />}
      <IconButton
        className={`${styles.filterButton} ${
          openFilter || chips?.length ? styles.filterButton__opened : ''
        } `}
        onClick={handleFilterOpen}
        component="span">
        <img src={FilterIcon} alt="" />
      </IconButton>
      <Popper
        open={openFilter}
        anchorEl={anchorEl}
        placement="right-start"
        transition
        className={styles.popper}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper classes={{ root: styles.filterContainer }}>
              {isMobile && (
                <section className={styles.headerWrapper}>
                  <div className={styles.pageHeader}>
                    <h1>Filters</h1>
                    <button onClick={closeFilterHandle}>
                      <ChevronLeftIcon />
                    </button>
                  </div>
                </section>
              )}
              <section className={styles.popupFormWrapper}>
                <section className={styles.formColumn}>
                  <section className={styles.formCell}>
                    <label>Report type</label>
                    <div className={styles.inputWrapper}>
                      <Multiselect
                        name="reportType"
                        valuesArray={values.generalReportTypeName}
                        options={reportTypesData || []}
                        onFilterSelect={onFilterSelect}
                      />
                    </div>
                  </section>
                </section>
                <section className={styles.formColumn}>
                  <section className={styles.formCell}>
                    <label>Status</label>
                    <div className={styles.inputWrapper}>
                      <Multiselect
                        name="statusType"
                        valuesArray={statusesArray}
                        options={statusesData || []}
                        onFilterSelect={onFilterSelect}
                      />
                    </div>
                  </section>
                </section>
                {isMobile && !!reportTypesData.length && (
                  <section className={styles.chipsBlock}>
                    {!!chips?.length &&
                      chips.map((item) => (
                        <Chip
                          classes={{ icon: styles.chipIcon }}
                          key={item.id}
                          label={item.name}
                          deleteIcon={<CloseIcon />}
                          className={styles.fieldsChip}
                          onDelete={() => handleChipDelete(item)}
                        />
                      ))}
                  </section>
                )}
                <section className={styles.buttonWrapper}>
                  <ClearFilterButton onClick={handleClearFilter} />
                  <div className={styles.primary}>
                    <MainButton
                      text="Cancel"
                      action={closeFilterHandle}
                      type="secondary"
                      size="filter_popup"
                    />
                    <MainButton
                      text="Apply"
                      action={applyFilter}
                      type="primary"
                      size="filter_popup"
                    />
                  </div>
                </section>
              </section>
            </Paper>
          </Fade>
        )}
      </Popper>

      {!isMobile && (
        <section className={styles.chipsBlock}>
          {!!chips?.length &&
            !!reportTypesData.length &&
            chips.map((item) => (
              <Chip
                classes={{ icon: styles.chipIcon }}
                key={item.id}
                label={item.name}
                deleteIcon={<CloseIcon />}
                className={styles.fieldsChip}
                onDelete={() => handleChipDelete(item)}
              />
            ))}
        </section>
      )}
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  reportTemplateFilterCriteria: selectReportTemplateFilterCriteria()
});

const mapDispatchToProps = {
  getReportTemplateFilterCriteriaAction: getReportTemplateFilterCriteria
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ReportTemplateFilter);
