import {
  SET_PICK_LIST,
  SET_LOCATIONS_FOR_PICK_LIST,
  SET_STATUSES_FOR_PICK_LIST,
  SET_PROJECTS_FOR_PICK_LIST,
  CLEAR_PICK_LIST_STATE,
  SET_PICK_LIST_FILTER,
  SET_CURRENT_PICK_LIST,
  SET_PICKLIST_STATUSES,
  SET_PICKLIST_TRUCK_OR_TRAILERS,
  SET_PICKLIST_DR_CODES,
  SET_PICKLIST_ASSETS_COUNTING,
  RESET_PICK_LIST_FILTER
} from 'constants/reduceConstants';
import { getClearedFilterByUnmount } from 'helpers/AppHelpers';

const defaultFilter = {
  filters: {
    searchQuery: '',
    locationIds: [],
    locationJobNumberIds: [],
    statusNames: [],
    projectIds: [],
    createdFrom: null,
    createdTo: null
  },
  pagination: {
    page: 1,
    totalPages: 1,
    limit: 10
  },
  sortRules: [],
  responseReceived: false
};

const initialState = {
  pickList: [],
  locationList: [],
  statusList: [],
  projectList: [],
  pickListFilter: { ...defaultFilter },
  currentPicklist: {},
  assetsFromSearch: [],
  assetsFromArraySearch: {},
  truckOrTrailers: [],
  drCodes: [],
  picklistAssetStatuses: [],
  assetsCounting: {
    slicedPicklistAssets: [],
    total: {}
  }
};

const pickListReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_PICK_LIST:
      return {
        ...state,
        pickList: payload
      };
    case SET_LOCATIONS_FOR_PICK_LIST:
      return {
        ...state,
        locationList: payload
      };
    case SET_STATUSES_FOR_PICK_LIST:
      return {
        ...state,
        statusList: payload
      };
    case SET_PROJECTS_FOR_PICK_LIST:
      return {
        ...state,
        projectList: payload
      };
    case SET_CURRENT_PICK_LIST:
      return {
        ...state,
        currentPicklist: payload
      };
    case SET_PICKLIST_STATUSES:
      return {
        ...state,
        picklistAssetStatuses: payload
      };
    case SET_PICK_LIST_FILTER:
      return {
        ...state,
        pickListFilter: payload
      };
    case RESET_PICK_LIST_FILTER:
      return {
        ...state,
        pickListFilter: {
          ...state.pickListFilter,
          filters: { ...defaultFilter.filters, ...payload }
        }
      };
    case SET_PICKLIST_ASSETS_COUNTING:
      return { ...state, assetsCounting: payload };
    case SET_PICKLIST_TRUCK_OR_TRAILERS:
      return { ...state, truckOrTrailers: payload };
    case SET_PICKLIST_DR_CODES:
      return { ...state, drCodes: payload };
    case CLEAR_PICK_LIST_STATE:
      return { ...initialState, pickListFilter: getClearedFilterByUnmount(state.pickListFilter) };
    default:
      return state;
  }
};

export default pickListReducer;
