import * as routes from 'constants/routeConstants';
import DashboardIcon from 'assets/images/dashboards.svg';
import AssetSummaryIcon from 'assets/images/summaryAsset.svg';
import LooseItemsIcon from 'assets/images/loostItems.svg';
import BatchUpdatesIcon from 'assets/images/batchUpdates.svg';
import AssetTicketsIcon from 'assets/images/ticketsAsset.svg';
import ReportsIcon from 'assets/images/reports.svg';
import LocationManagementIcon from 'assets/images/locationManagement.svg';
import PrefixListIcon from 'assets/images/prefixList.svg';
import UserManagementIcon from 'assets/images/userManagement.svg';
import PicklistIcon from 'assets/images/picklist.svg';
import TeamMemberSummaryIcon from 'assets/images/teamMembers.svg';
import RequestOrderIcon from 'assets/images/requestOrderIcon.svg';

export const listItems = [
  {
    id: 1,
    name: 'Assets Dashboards',
    icon: DashboardIcon,
    alt: 'dashboard',
    activeBasePath: [
      routes.DASHBOARD_PATH,
      routes.ASSETS_VERIFICATION_AND_INSPECTION_DASHBOARDS_PATH
    ],
    isCollapse: true,
    collapseItems: [
      {
        id: 1,
        name: 'General',
        path: routes.DASHBOARD_PATH
      },
      {
        id: 2,
        name: 'Verification & Inspection',
        path: routes.ASSETS_VERIFICATION_AND_INSPECTION_DASHBOARDS_PATH,
        hiddenForMobile: true
      }
    ],
    openFlag: 1
  },
  {
    id: 2,
    name: 'Asset Summary',
    icon: AssetSummaryIcon,
    alt: 'asset-summary',
    path: routes.ASSETS_LIST_PATH,
    activeBasePath: [routes.ASSETS_LIST_PATH, routes.ASSET_DETAILED_PATH, routes.ASSET_EDIT_PATH],
    isCollapse: false
  },
  {
    id: 3,
    name: 'Loose Items',
    icon: LooseItemsIcon,
    alt: 'loose-items',
    isCollapse: true,
    activeBasePath: [
      routes.BOM_CONFIGURATIONS_LIST_PATH,
      routes.BOM_CONFIGURATION_DETAILED_PATH,
      routes.BOM_CONFIGURATION_EDIT_PATH,
      routes.ASSEMBLED_BOM_LIST_PATH,
      routes.ASSEMBLED_BOM_DETAILED_PATH,
      routes.ASSEMBLE_CREATE_PATH,
      routes.LOOSE_ITEM_DASHBOARD,
      routes.LOOSE_ITEMS_LIST_PATH,
      routes.LOOSE_ITEM_DETAILED_PATH,
      routes.LOOSE_ITEM_EDIT_PATH,
      routes.MOVED_LOOSE_ITEMS_PATH
    ],
    collapseItems: [
      {
        id: 1,
        name: 'Dashboard',
        path: routes.LOOSE_ITEM_DASHBOARD
      },
      {
        id: 2,
        name: 'Loose Items List',
        path: routes.LOOSE_ITEMS_LIST_PATH
      },
      {
        id: 3,
        name: 'BOM configuration',
        path: routes.BOM_CONFIGURATIONS_LIST_PATH
      },
      {
        id: 4,
        name: 'Assembled BOM',
        path: routes.ASSEMBLED_BOM_LIST_PATH
      }
    ],
    openFlag: 2
  },
  {
    id: 4,
    name: 'Request Orders',
    icon: RequestOrderIcon,
    alt: 'request-order',
    path: routes.REQUEST_ORDER_SUMMARY_PATH,
    activeBasePath: [
      routes.REQUEST_ORDER_SUMMARY_PATH,
      routes.REQUEST_ORDER_EDIT_PATH,
      routes.REQUEST_ORDER_DETAILED_PATH
    ],
    isCollapse: false
  },
  {
    id: 5,
    name: 'Batch Updates',
    icon: BatchUpdatesIcon,
    alt: 'batch-updates',
    activeBasePath: [routes.BATCH_ASSET_UPDATES_PATH, routes.BATCH_LOOSE_ITEM_UPDATES_PATH],
    isCollapse: true,
    openFlag: 3,
    collapseItems: [
      {
        id: 1,
        name: 'Asset',
        path: routes.BATCH_ASSET_UPDATES_PATH
      },
      {
        id: 2,
        name: 'Loose items',
        path: routes.BATCH_LOOSE_ITEM_UPDATES_PATH
      }
    ]
  },
  {
    id: 6,
    name: 'Asset Tickets',
    icon: AssetTicketsIcon,
    alt: 'asset-tickets',
    isCollapse: true,
    activeBasePath: [
      routes.TICKETS_LIST_PATH,
      routes.TICKET_ROOT_CAUSES_PATH,
      routes.TICKET_TYPES_PATH,
      routes.TICKET_DETAILED_PATH,
      routes.TICKET_EDIT_PATH,
      routes.TICKET_TYPE_EDIT_PATH,
      routes.TICKET_ROOT_CAUSE_EDIT_PATH,
      routes.TICKETS_DASHBOARD_PATH
    ],
    collapseItems: [
      {
        id: 1,
        name: 'Asset Ticket Summary',
        path: routes.TICKETS_LIST_PATH
      },
      {
        id: 2,
        name: 'Add Asset Ticket',
        path: `${routes.BATCH_ASSET_UPDATES_PATH}?tab=addAssetTicket`
      },
      {
        id: 3,
        name: 'Ticket Types',
        path: routes.TICKET_TYPES_PATH,
        hiddenForRoles: [2, 3, 5]
      },
      {
        id: 4,
        name: 'Ticket Root Causes',
        path: routes.TICKET_ROOT_CAUSES_PATH,
        hiddenForRoles: [2, 3, 5]
      },
      {
        id: 5,
        name: 'Dashboard',
        path: routes.TICKETS_DASHBOARD_PATH,
        hiddenForMobile: true
      }
    ],
    openFlag: 4
  },
  {
    id: 7,
    name: 'Reports',
    icon: ReportsIcon,
    alt: 'reports',
    isCollapse: true,
    activeBasePath: [
      routes.VEHICLE_REPORT_EDIT_PATH,
      routes.LOCATION_REPORT_LIST_PATH,
      routes.LOCATION_REPORT_EDIT_PATH,
      routes.ASSET_REPORT_LIST_PATH,
      routes.ASSET_REPORT_EDIT_PATH,
      routes.REPORT_TEMPLATE_LIST_PATH,
      routes.REPORT_TEMPLATE_EDITOR,
      routes.GENERAL_REPORT_LIST_PATH,
      routes.GENERAL_REPORT_EDIT_PATH,
      routes.COMPARE_REPORTS_PATH,
      routes.OVERVIEW_REPORT_PATH
    ],
    collapseItems: [
      {
        id: 3,
        name: 'Location report',
        path: routes.LOCATION_REPORT_LIST_PATH
      },
      {
        id: 4,
        name: 'Asset report',
        path: routes.ASSET_REPORT_LIST_PATH
      },
      {
        id: 5,
        name: 'General report',
        path: routes.GENERAL_REPORT_LIST_PATH
      },
      {
        id: 6,
        name: 'Overview report',
        path: routes.OVERVIEW_REPORT_PATH,
        hiddenForRoles: [3, 5]
      },
      {
        id: 7,
        name: 'Compare reports data',
        path: routes.COMPARE_REPORTS_PATH
      },
      {
        id: 8,
        name: 'Report template',
        path: routes.REPORT_TEMPLATE_LIST_PATH,
        hiddenForRoles: [2, 3, 4, 5],
        hiddenForMobile: true
      }
    ],
    openFlag: 5
  },
  {
    id: 8,
    name: 'Team Schedule',
    icon: TeamMemberSummaryIcon,
    alt: 'team_member',
    activeBasePath: [
      routes.TEAM_MEMBER_LIST_PATH,
      routes.TEAM_MEMBER_TRACKER_PATH,
      routes.TEAM_MEMBER_DETAILED_PATH,
      routes.TEAM_MEMBER_EDIT_PATH,
      routes.TEAM_MEMBER_DASHBOARD_PATH,
      routes.PERSONNEL_PROFILE_PATH,
      routes.TEAM_MEMBER_HISTORY_PATH,
      routes.TRAININGS_AND_CERTIFICATIONS_PATH
    ],
    isCollapse: true,
    collapseItems: [
      {
        id: 1,
        name: 'Personal Profile',
        path: routes.PERSONNEL_PROFILE_PATH,
        hiddenForRoles: [1, 5],
        hiddenForNotTeamMember: true
      },
      {
        id: 2,
        name: 'Team List',
        path: routes.TEAM_MEMBER_LIST_PATH,
        separateCheck: true
      },
      {
        id: 3,
        name: 'Project Schedule',
        path: routes.TEAM_MEMBER_TRACKER_PATH,
        separateCheck: true,
        hiddenForMobile: true
      },
      {
        id: 4,
        name: 'Team Member Dashboard',
        path: routes.TEAM_MEMBER_DASHBOARD_PATH,
        separateCheck: true,
        hiddenForMobile: true
      },
      {
        id: 5,
        name: 'Team Member History',
        path: routes.TEAM_MEMBER_HISTORY_PATH,
        separateCheck: true
      },
      {
        id: 6,
        name: 'Training and Certifications',
        path: routes.TRAININGS_AND_CERTIFICATIONS_PATH,
        hiddenForRoles: [2, 3, 4, 5]
      }
    ],
    openFlag: 6
  },
  {
    id: 9,
    name: 'Picklist and Leavelist',
    icon: PicklistIcon,
    alt: 'picklist',
    activeBasePath: [
      routes.PICKLIST_SUMMARY_PATH,
      routes.PICKLIST_DETAILED_PATH,
      routes.PICKLIST_EDIT_PATH,
      routes.LEAVELIST_SUMMARY_PATH,
      routes.LEAVELIST_EDIT_PATH,
      routes.LEAVELIST_DETAILED_PATH,
      routes.BILL_OF_LADING_SUMMARY_PATH,
      routes.BILL_OF_LADING_EDIT_PATH,
      routes.BILL_OF_LADING_DETAILS_PATH,
      routes.ADD_BOM_TO_PICKLIST_PATH,
      routes.BILL_OF_LADING_LOGS_PATH,
      routes.BILL_OF_LADING_LOG_EDIT_PATH
    ],
    isCollapse: true,
    collapseItems: [
      {
        id: 1,
        name: 'Picklist',
        path: routes.PICKLIST_SUMMARY_PATH
      },
      {
        id: 2,
        name: 'Leavelist',
        path: routes.LEAVELIST_SUMMARY_PATH
      },
      {
        id: 3,
        name: 'Bill of Lading Summary',
        path: routes.BILL_OF_LADING_SUMMARY_PATH
      },
      {
        id: 4,
        name: 'Bill of Lading Logs',
        path: routes.BILL_OF_LADING_LOGS_PATH,
        hiddenForMobile: true
      }
    ],
    openFlag: 7
  },
  {
    id: 10,
    name: 'Location Management',
    icon: LocationManagementIcon,
    alt: 'location-management',
    activeBasePath: [
      routes.LOCATIONS_LIST_PATH,
      routes.LOCATION_DETAILED_PATH,
      routes.LOCATION_EDIT_PATH,
      routes.ACTIVE_LOCATIONS_PATH,
      routes.PROJECT_LIST_PATH,
      routes.PROJECT_DETAILED_PATH,
      routes.PROJECT_EDIT_PATH
    ],
    isCollapse: true,
    collapseItems: [
      {
        id: 1,
        name: 'Map of Active Locations',
        path: routes.ACTIVE_LOCATIONS_PATH
      },
      {
        id: 2,
        name: 'Location Summary',
        path: routes.LOCATIONS_LIST_PATH
      },
      {
        id: 3,
        name: 'Projects',
        path: routes.PROJECT_LIST_PATH
      }
    ],
    openFlag: 8
  },
  {
    id: 11,
    name: 'Prefix List',
    icon: PrefixListIcon,
    alt: 'prefix-list',
    path: routes.CATEGORY_LIST_PATH,
    activeBasePath: [
      routes.CATEGORY_LIST_PATH,
      routes.CATEGORY_DETAILED_PATH,
      routes.CATEGORY_EDIT_PATH,
      routes.PREFIX_EDIT_PATH,
      routes.PREFIX_DETAILED_PATH
    ],
    isCollapse: false
  },
  {
    id: 12,
    name: 'User Management',
    icon: UserManagementIcon,
    alt: 'user-management',
    activeBasePath: [
      routes.USER_LIST_PATH,
      routes.USER_DETAILED_PATH,
      routes.USER_EDIT_PATH,
      routes.USER_LOGS_PATH,
      routes.NOTIFICATION_CENTER_PATH
    ],
    isCollapse: true,
    collapseItems: [
      {
        id: 1,
        name: 'User List',
        path: routes.USER_LIST_PATH,
        hiddenForRoles: [2, 3, 4, 5]
      },
      {
        id: 2,
        name: 'User Logs',
        path: routes.USER_LOGS_PATH,
        hiddenForRoles: [2, 3, 4, 5],
        hiddenForMobile: true
      },
      {
        id: 3,
        name: 'Notification Settings Center',
        path: routes.NOTIFICATION_CENTER_PATH,
        hiddenForRoles: [2, 3, 4, 5]
      }
    ],
    openFlag: 9
  }
];
