import React from 'react';
import styles from './Details.module.scss';

import Subtitle from 'components/DetailsComponents/Subtitle';

import { useTicketSelector } from 'hooks/Ticket';

export default function TicketDetailsDescription() {
  const { currentTicket } = useTicketSelector();
  const { description } = currentTicket;

  return (
    <div className={styles.description}>
      <Subtitle>Description</Subtitle>
      <label className={styles.description__info}>{description || ''}</label>
    </div>
  );
}
