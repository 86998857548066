import React, { useEffect } from 'react';
import styles from './LocationReportEdit.module.scss';

import { useParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';

import { useReportActions, useReportSelector } from 'hooks/ReportList';

import ReportEditWrapper from '../../commonComponents/ReportEditWrapper';
import GeneralDetails from './components/GeneralDetails';

import { getWeatherDetails, getWeatherValue } from './helpers';
import { formatDate, wait } from 'helpers/AppHelpers';

export default function LocationReportEdit() {
  const { id } = useParams();

  const {
    getLocationWeatherAction,
    getUsersForAssignAction,
    startAutoSaveAction
  } = useReportActions();
  const { currentGeneralReport, activeTemplate, usersForAssignList } = useReportSelector();

  const startAutoSave = () => {
    wait(1000).then(() => startAutoSaveAction());
  };

  const { templateLocation, templateReport } = activeTemplate;

  const methods = useForm({
    defaultValues: {
      reportType: {},
      generalReportTemplate: {},
      generalReportFields: [],
      resources: [],
      assignedAtUtc: null,
      signatures: []
    },
    mode: 'onChange'
  });
  const { getValues, reset, setValue } = methods;

  const getWeather = (date) => {
    const lat =
      currentGeneralReport?.location?.latitude ||
      currentGeneralReport?.project?.latitude ||
      templateLocation?.location?.latitude ||
      templateLocation?.project?.latitude ||
      null;
    const lng =
      currentGeneralReport?.location?.longitude ||
      currentGeneralReport?.project?.longitude ||
      templateLocation?.location?.longitude ||
      templateLocation?.project?.longitude ||
      null;
    if (!lat || !lng) return;

    getLocationWeatherAction({ lat, lng, date }).then((res) => {
      const weatherString = getWeatherValue(res);
      const { fieldName, doesWeatherExist } = getWeatherDetails(getValues('generalReportFields'));
      if (doesWeatherExist) {
        setValue(fieldName, weatherString);
      }
    });
  };

  useEffect(() => {
    if (!id && templateReport?.id) {
      const presetValues = {
        ...getValues(),
        createdAtUtc: formatDate(new Date()),
        reportType: { ...templateReport?.reportType },
        generalReportTemplate: { id: templateReport?.id, name: templateReport?.name },
        generalReportFields: templateReport?.generalReportFields
      };

      if (templateLocation?.isProject) {
        presetValues.project = templateLocation.project;
      } else {
        presetValues.location = templateLocation.location;
      }

      reset(presetValues);
      getWeather(new Date());
      startAutoSave();
    }
  }, [activeTemplate]);

  useEffect(() => {
    if (usersForAssignList?.length) return;

    if (getValues('location')?.id || getValues('project')?.id) {
      const query = { shouldIncludeCurrentUser: true };

      if (getValues('location')?.id) {
        query.locationId = getValues('location')?.id || null;
      }

      getUsersForAssignAction(query);
    }
  }, [getValues('location')?.id, getValues('project')?.id, usersForAssignList]);

  useEffect(() => {
    if (id && currentGeneralReport?.id) {
      reset(currentGeneralReport);
      if (!currentGeneralReport.submitted) {
        startAutoSave();
      }
    }
  }, [currentGeneralReport]);

  return (
    <section className={styles.pageContainer}>
      <FormProvider {...methods}>
        <ReportEditWrapper reportType="location">
          <GeneralDetails getWeather={getWeather} />
        </ReportEditWrapper>
      </FormProvider>
    </section>
  );
}
