import React from 'react';
import styles from './FormAutoMultiSelect.module.scss';
import { Controller, useFormContext } from 'react-hook-form';
import AutocompleteWithSelectAll from 'components/AutocompleteWithSelectAll/AutocompleteWithSelectAll';
import clsx from 'clsx';
import { sortByParam } from 'helpers/AppHelpers';

export default function FormAutoMultiSelect({
  name,
  menuItems,
  options,
  classes,
  renderOptionLabel
}) {
  const { control, setValue, clearErrors } = useFormContext();

  const {
    label,
    selectAll,
    disableByObjectTracker,
    extraAction,
    isDisabled,
    inputLabel,
    hideTags,
    labelType,
    disableLabel,
    onSelect,
    sortAfterSelecting
  } = options;

  const handleSelect = (name, value, isInvalid, selectionType, processedOption) => {
    isInvalid && clearErrors(name);
    if (onSelect) {
      onSelect({ name, value, selectionType, processedOption });
    } else {
      const data = sortAfterSelecting ? sortByParam(value, label) : value;
      setValue(name, data, { shouldDirty: true });
      extraAction && extraAction(name, data);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value }, fieldState: { error } }) => (
        <div className={clsx(!!classes && classes, styles.inputWrapper)}>
          <AutocompleteWithSelectAll
            name={name}
            label={label}
            labelType={labelType}
            value={value || []}
            options={menuItems || []}
            onSelect={(name, value, type, option) =>
              handleSelect(name, value, !!error?.message, type, option)
            }
            selectAll={selectAll}
            disableByObjectTracker={disableByObjectTracker}
            isInvalid={!!error?.message}
            isDisabled={isDisabled}
            hideTags={!!hideTags}
            renderOptionLabel={renderOptionLabel}
          />
          {error ? (
            <span className={styles.error}>{error.message}</span>
          ) : (
            !disableLabel && <span>{inputLabel || 'Select from the list'}</span>
          )}
        </div>
      )}
    />
  );
}
