import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import styles from './FormSearchInput.module.scss';
import InputSearchWithMultiselect from 'components/InputSearchWithMultiselect/InputSearchWithMultiselect';
import clsx from 'clsx';

export default function FormSearchInput({
  name,
  options,
  onSelect,
  isDisabled,
  classes,
  disableError,
  clearable,
  index,
  optionType
}) {
  const { control, clearErrors } = useFormContext();

  const handleOptionSelect = (name, value, isInvalid) => {
    isInvalid && clearErrors(name);
    onSelect(name, value, index);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value }, fieldState: { error } }) => (
        <div className={clsx(!!classes && classes, styles.inputWrapper)}>
          <InputSearchWithMultiselect
            name={name}
            defaultValue={value?.id || ''}
            options={options || []}
            onFilterSelect={(name, value) => handleOptionSelect(name, value, !!error?.message)}
            multiselect={false}
            isInvalid={disableError ? false : !!error}
            disabled={isDisabled}
            clearable={clearable}
            index={index}
            optionType={optionType}
          />
          {!!error && <span>{error.message}</span>}
        </div>
      )}
    />
  );
}
