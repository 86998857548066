import api, { API_BASE_ADDRESS, getToken } from '../api';
import axios from 'axios';

import { queryToString } from 'helpers/AppHelpers';

export const getLocationsListApi = (query) =>
  api.get(`/Locations/Shorted?${queryToString(query)}`).then((res) => res.data);

export const getFilterProjectListApi = (query) =>
  api.get(`/ShortedProjects?${queryToString(query)}`).then((res) => res.data);

export const getActiveLocationsListApi = (query) =>
  api.get(`/Locations/Map?${queryToString(query)}`).then((res) => res.data);

export const getLocationsAndProjectsApi = () =>
  api.get('/GeneralReport/LocationAndProjects').then((res) => res.data);

export const getLastSavedGeneralReportApi = (query) =>
  api.get(`/GeneralReport/CreationForm?${queryToString(query)}`).then((res) => res.data);

const axiosInstance = axios.create({
  baseURL: 'https://api.open-meteo.com/v1'
});
export const getLocationWeatherApi = (query) =>
  axiosInstance.get(`/forecast?${queryToString(query)}`).then((res) => res.data);

export const getUserForAssignApi = (query) =>
  api.get(`/UsersForAssign?${queryToString(query)}`).then((res) => res.data);

export const getSeverityListApi = (query) =>
  api.get(`/TicketSeverities?${queryToString(query)}`).then((res) => res.data);

export const getReportTemplateCreationFormApi = () =>
  api.get(`/GeneralReportTemplate/CreationForm`).then((res) => res.data);

export const createGeneralReportApi = (params, notification) =>
  api
    .post(`/GeneralReport${notification ? '?shouldSendNotification=true' : ''}`, params)
    .then((res) => res.data);

export const updateGeneralReportApi = (params, notification) =>
  api
    .put(`/GeneralReport${notification ? '?shouldSendNotification=true' : ''}`, params)
    .then((res) => res.data);

export const updateReportApi = (params) => api.put('/GeneralReports', params).then((res) => res);

export const removeGeneralReportApi = (id) =>
  api.delete(`/GeneralReport?id=${id}`).then((res) => res.data);

export const getReportTemplateFilterCriteriaApi = () =>
  api.get(`/GeneralReportTypes`).then((res) => res.data);

export const getReportTemplateListApi = (query) =>
  api.get(`/GeneralReportTemplates?${queryToString(query)}`).then((res) => res.data);

export const getGeneralReportListApi = (query) =>
  api.get(`/GeneralReports?${queryToString(query)}`).then((res) => res.data);

export const getSingleGeneralReportApi = (id) =>
  api.get(`/GeneralReport?id=${id}`).then((res) => res.data);

export const getGeneralPdfResourceApi = (id) =>
  api.get(`/GeneralReport/PdfResource?id=${id}`).then((res) => res.data);

export const getShortedReportTemplatesApi = (query) =>
  api.get(`/GeneralReportTemplates/Shorted?${queryToString(query)}`).then((res) => res.data);

export const checkReportTemplateNameApi = (query) =>
  api.get(`/GeneralReportTemplateName?${queryToString(query)}`).then((res) => res.data);

export const searchReportTemplateByLocationIdApi = (query) =>
  api
    .get(`/GeneralReportTemplates/SearchByLocation?${queryToString(query)}`)
    .then((res) => res.data);

export const searchReportTemplateByAssetIdApi = (id) =>
  api.get(`/GeneralReportTemplates/SearchByAsset?assetId=${id}`).then((res) => res.data);

export const getReportTemplateApi = (id) =>
  api.get(`/GeneralReportTemplate?id=${id}`).then((res) => res.data);

export const createReportTemplateApi = (data) =>
  api.post(`/GeneralReportTemplate`, data).then((res) => res.data);

export const updateReportTemplateApi = (data) =>
  api.put(`/GeneralReportTemplate`, data).then((res) => res.data);

export const deleteReportTemplateApi = (id) =>
  api.delete(`/GeneralReportTemplate?id=${id}`).then((res) => res.data);

export const getCompareFieldsApi = (id) =>
  api.get(`/GeneralReportTemplate/ComparedFields?reportTemplteId=${id}`).then((res) => res.data);

export const getReportComparedDataApi = (data) =>
  api.post(`/GeneralReports/Compared`, data).then((res) => res.data);

export const getCompareDataSerializedApi = (data) =>
  api.post(`/GeneralReports/SerializedCompared`, data).then((res) => res.data);

export const getArchiveResourceApi = (query) =>
  api.get(`/GeneralReport/PdfArchive?${queryToString(query)}`).then((res) => res.data);

export const updateGeneralReportApiWithoutLoader = (params, notification) => {
  const axiosInstanceForSavingReport = axios.create({
    baseURL: API_BASE_ADDRESS,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      authorization: `Bearer ${getToken() === 'undefined' ? '' : getToken()}`
    }
  });

  return axiosInstanceForSavingReport
    .put(`/GeneralReport${notification ? '?shouldSendNotification=true' : ''}`, params)
    .then((res) => res);
};

export const createGeneralReportApiWithoutLoader = (params, notification) => {
  const axiosInstanceForSavingReport = axios.create({
    baseURL: API_BASE_ADDRESS,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      authorization: `Bearer ${getToken() === 'undefined' ? '' : getToken()}`
    }
  });

  return axiosInstanceForSavingReport
    .post(`/GeneralReport${notification ? '?shouldSendNotification=true' : ''}`, params)
    .then((res) => res);
};

export const getFilterCriteriaApi = (q) =>
  api.get(`/GeneralReports/FilterCriteria?${queryToString(q)}`).then((res) => res.data);
