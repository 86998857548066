import { SET_TRAININGS_FILTER, CLEAR_TRAININGS_STATE } from 'constants/reduceConstants';
import { getClearedFilterByUnmount } from 'helpers/AppHelpers';

const initialState = {
  tableState: [],
  filter: {
    filters: {
      searchQuery: '',
      externalTrainingIds: [],
      groupIds: []
    },
    pagination: {
      page: 1,
      totalPages: 1,
      limit: 10
    },
    sortRules: []
  }
};

const trainingsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_TRAININGS_FILTER:
      return { ...state, filter: payload };
    case CLEAR_TRAININGS_STATE:
      return {
        ...initialState,
        filter: getClearedFilterByUnmount(state.filter)
      };
    default:
      return state;
  }
};

export default trainingsReducer;
