import {
  SET_EMPLOYMENT_POPUP_CREATION_FORM,
  CLEAR_SET_EMPLOYMENT_POPUP_STATE
} from 'constants/reduceConstants';

const initialState = {
  creationFormData: []
};

const setEmploymentPopupReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_EMPLOYMENT_POPUP_CREATION_FORM:
      return {
        ...state,
        creationFormData: payload
      };
    case CLEAR_SET_EMPLOYMENT_POPUP_STATE:
      return initialState;
    default:
      return state;
  }
};

export default setEmploymentPopupReducer;
