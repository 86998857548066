import React from 'react';
import styles from './LeftSidebar.module.scss';
import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import clsx from 'clsx';
import OpenInNewTabButton from './OpenInNewTabButton';

function SidebarListItem({ list, openCollapse, setOpenCollapse, handleNavigateClick }) {
  const isActive = (paths) => {
    const routesArray = window.location.pathname.split('/');
    const mainPath = '/' + routesArray[1];
    return paths?.includes(mainPath);
  };

  const changeFlag = (openFlag) => {
    const newStateCollapse = [...openCollapse];
    newStateCollapse[openFlag] = !newStateCollapse[openFlag];
    setOpenCollapse(newStateCollapse);
  };

  const handleListClick = () => {
    if (list.isCollapse) {
      changeFlag(list.openFlag);
    } else {
      handleNavigateClick(list.path);
    }
  };

  return (
    <>
      <ListItem
        className={clsx(styles.listItem, isActive(list.activeBasePath) && styles.active)}
        onClick={handleListClick}
        button
        tabIndex="-1">
        <ListItemIcon className={styles.listItem__icon}>
          <img src={list.icon} alt={list.alt} />
        </ListItemIcon>
        <ListItemText className={styles.listItem__text} primary={list.name} />
        <div className={styles.listItem__controls}>
          {list.isCollapse && <>{openCollapse[list.openFlag] ? <ExpandLess /> : <ExpandMore />}</>}
          {!list.isCollapse && <OpenInNewTabButton path={list.path} />}
        </div>
      </ListItem>
      {list.isCollapse && (
        <Collapse in={openCollapse[list.openFlag]} timeout="auto" unmountOnExit>
          <List key={list.id} component="div" disablePadding>
            {list.collapseItems.map((item, itemIndex) => (
              <ListItem
                key={itemIndex}
                onClick={() => handleNavigateClick(item.path)}
                button
                className={styles.listItem}>
                <ListItemText className={styles.listItem__subtitle} primary={item.name} />
                <div className={styles.listItem__controls}>
                  <OpenInNewTabButton path={item.path} />
                </div>
              </ListItem>
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
}

export default SidebarListItem;
