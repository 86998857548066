import { useDispatch } from 'react-redux';
import {
  getLocationWeather,
  getSingleGeneralReport,
  getUsersForAssign,
  getGeneralPdfResource,
  clearReportListState,
  removeGeneralReport,
  upgradeGeneralReport,
  toggleAutoSave,
  setLocationReportFilter,
  setAssetReportFilter,
  setGeneralReportFilter,
  getGeneralReportList,
  updateReport,
  getFilterCriteria
} from 'actions/reportListActions';

import { setUnsavedFormData } from 'actions/commonActions';

export function useReportActions() {
  const dispatch = useDispatch();

  const setFilterAction = (data) => dispatch(setLocationReportFilter(data));
  const setAssetReportsFilterAction = (data) => dispatch(setAssetReportFilter(data));
  const setGeneralReportsFilterAction = (data) => dispatch(setGeneralReportFilter(data));

  const getReportsAction = (q) => dispatch(getGeneralReportList(q));

  const updateReportAction = (data) => dispatch(updateReport(data));

  const getFilterCriteriaAction = (q) => dispatch(getFilterCriteria(q));

  const clearStateAction = () => dispatch(clearReportListState());

  const updateGeneralReportAction = (data, options) =>
    dispatch(upgradeGeneralReport(data, options));
  const getSingleGeneralReportAction = (id) => dispatch(getSingleGeneralReport(id));
  const removeGeneralReportAction = (data) => dispatch(removeGeneralReport(data));
  const getLocationWeatherAction = (options) => dispatch(getLocationWeather(options));
  const getUsersForAssignAction = (query) => dispatch(getUsersForAssign(query));
  const getPdfResourceAction = (id) => dispatch(getGeneralPdfResource(id));

  const startAutoSaveAction = () => dispatch(toggleAutoSave(true));
  const stopAutoSaveAction = () => dispatch(toggleAutoSave(false));

  const setUnsavedFormDataAction = () => dispatch(setUnsavedFormData());

  return {
    updateGeneralReportAction,
    setFilterAction,
    setAssetReportsFilterAction,
    setGeneralReportsFilterAction,
    getReportsAction,
    updateReportAction,
    clearStateAction,
    getSingleGeneralReportAction,
    removeGeneralReportAction,
    getLocationWeatherAction,
    getUsersForAssignAction,
    getPdfResourceAction,
    setUnsavedFormDataAction,
    startAutoSaveAction,
    getFilterCriteriaAction,
    stopAutoSaveAction
  };
}
