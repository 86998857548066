import React, { useState } from 'react';
import styles from './Details.module.scss';

import { useTicketSelector } from 'hooks/Ticket';

import FullscreenIcon from '@material-ui/icons/Fullscreen';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';

import VideoPlayer from 'components/VideoPlayer/VideoPlayer';
import DialogPopup from 'components/DialogPopup/DialogPopup';
import GalleryLightbox from 'components/PhotoGallery/GalleryLightbox';

import 'assets/styles/gallery.scss';

import { DOWNLOAD_FILE } from 'constants/dialogPopupsData';
import { fileDownload } from 'helpers/AppHelpers';

export default function TicketFiles() {
  const { currentTicket } = useTicketSelector();
  const { resources } = currentTicket;

  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState([]);

  const [isPlayerOpen, setIsPlayerOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState({});

  const [modalData, setModalData] = useState({});

  const images = resources?.filter((res) => res['resourceType']?.id === 1) || [];
  const videos = resources?.filter((resource) => resource['resourceType']?.id === 3) || [];
  const files = resources?.filter((resource) => resource['resourceType']?.id === 2) || [];

  const handleImageClick = (image) => {
    setSelectedPhoto([image]);
    setIsGalleryOpen(!isGalleryOpen);
  };

  const handleVideoClick = (video) => {
    setSelectedVideo(video);
    setIsPlayerOpen(true);
  };

  const onFileDownload = (file) => setModalData({ ...DOWNLOAD_FILE, isOpened: true, file });

  const agreeModal = () => fileDownload(modalData.file).then(() => closeModal());
  const closeModal = () => setModalData({ isOpened: false });

  return (
    <div className={styles.files}>
      <GalleryLightbox open={isGalleryOpen} photos={selectedPhoto} />
      <DialogPopup data={modalData} onAgree={agreeModal} onDissmiss={closeModal} />
      {isPlayerOpen && (
        <VideoPlayer open={isPlayerOpen} setOpen={setIsPlayerOpen} video={selectedVideo} />
      )}
      <div className={styles.files__row}>
        <label>Photo:</label>
        {!!images.length && (
          <div className={styles.photos}>
            {images.map((img, index) => (
              <div
                key={index}
                className={styles.photos__item}
                style={{ transform: `rotate(${img.rotationAngle}deg)` }}
                onClick={() => handleImageClick(img)}>
                <img src={img.link} alt="" />
                <div className={styles.photos__item_icon}>
                  <FullscreenIcon />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className={styles.files__row}>
        <label>Video:</label>
        {!!videos.length && (
          <div className={styles.photos}>
            {videos.map((video, index) => (
              <div
                key={index}
                className={styles.photos__item}
                onClick={() => handleVideoClick(video)}>
                <video src={video.link} />
                <div className={styles.photos__item_icon}>
                  <PlayCircleOutlineIcon />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className={styles.files__row}>
        <label>File:</label>
        {!!files.length && (
          <div className={styles.data}>
            {files.map((file, index) => (
              <div key={index} className={styles.data__item} onClick={() => onFileDownload(file)}>
                {file.name}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
