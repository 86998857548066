import api from '../api';
import {
  SET_EMPLOYMENT_POPUP_CREATION_FORM,
  CLEAR_SET_EMPLOYMENT_POPUP_STATE
} from '../constants/reduceConstants';
import { enqueueErrorSnackbar } from '../helpers/AppHelpers';

export const getCreationForm = () => (dispatch) => {
  api.setEmploymentPopup
    .getCreationFormApi()
    .then((res) => dispatch({ type: SET_EMPLOYMENT_POPUP_CREATION_FORM, payload: res }))
    .catch((err) => console.log(err));
};

export const getEvent = (id) => () =>
  api.setEmploymentPopup
    .getEventApi(id)
    .then((res) => res)
    .catch((err) => console.log(err));

export const createEvent = (data) => () =>
  api.setEmploymentPopup.createEventApi(data).then(
    (res) => res,
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );

export const createMultipleEvents = (data) => () =>
  api.setEmploymentPopup.createMultipleEventsApi(data).then(
    (res) => res,
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );

export const checkClearance = (data) => () =>
  api.setEmploymentPopup
    .checkClearanceApi(data)
    .then((res) => res)
    .catch((err) => console.log(err));

export const updateEvent = (data) => () =>
  api.setEmploymentPopup.updateEventApi(data).then(
    (res) => res,
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );

export const deleteEvent = (query) => () =>
  api.setEmploymentPopup.deleteEventApi(query).then(
    (res) => res,
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );

export const clearState = () => (dispatch) => {
  dispatch({ type: CLEAR_SET_EMPLOYMENT_POPUP_STATE });
};
