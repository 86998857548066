import React, { forwardRef, useEffect, useState } from 'react';
import styles from './MoveLooseItemsPopup.module.scss';
import { MainButton } from '../StyledComponents';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade
} from '@material-ui/core';
import InputSearchWithMultiselect from '../InputSearchWithMultiselect/InputSearchWithMultiselect';
import { connect } from 'react-redux';
import { getShortedLooseItems, moveLooseItems } from 'actions/looseItemDashboardActions';
import CloseIcon from '@material-ui/icons/Close';
import { getLocations } from 'actions/looseItemsAndPartsListActions';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import AddIcon from '@material-ui/icons/Add';
import clsx from 'clsx';

const Transition = forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} timeout={350} />;
});
let key = new Date().getTime() + Math.random();

function MoveLooseItemsPopup({
  isDashboardScreen,
  movedLooseItems,
  getShortedLooseItemsAction,
  getLocationsAction,
  moveLooseItemsAction,
  shouldAddButtonDisabled
}) {
  const [open, setOpen] = useState(false);

  const [options, setOptions] = useState({});

  const [values, setValues] = useState({});
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    if (!options?.looseItems?.length) {
      getShortedLooseItemsAction().then((res) => setOptions({ ...options, looseItems: res }));
    }
  }, [options]);

  useEffect(() => {
    if (!options?.locations?.length) {
      getLocationsAction({ filters: { active: true, includeUserCheck: true } }, true).then((res) =>
        setOptions({ ...options, locations: res.items })
      );
    }
  }, [options]);

  const { looseItems, locations } = options;

  const prepareData = () => {
    return {
      ...values,
      looseItemsToAdd: values.looseItemsToAdd.map((item) => {
        return { ...looseItems.find((option) => option.id === item) };
      }),
      moveLooseItems: isDashboardScreen ? null : movedLooseItems
    };
  };

  const onClearPopup = () => {
    setOpen(false);
    setValues({});
    setValidationErrors({});
    key = new Date().getTime() + Math.random();
  };

  const disableButton = () => {
    setValidationErrors((state) => ({
      ...state,
      disableButton: true
    }));
  };

  const onStart = () => {
    if (!validateForm()) {
      return;
    }
    moveLooseItemsAction(prepareData()).then((res) =>
      res === 'success' ? onClearPopup() : disableButton()
    );
  };

  const onCancel = () => onClearPopup();

  const onSelect = (name, value) => {
    validationErrors?.disableButton && validateForm('disableButton', false);
    if (name === 'looseItemsToAdd') {
      setValues({ ...values, [name]: [...value.map(({ id }) => id)] });
      validateForm(name, '');
    } else {
      setValues({ ...values, [name]: value });
    }
  };

  const validateForm = (key, newMessage) => {
    if (key) {
      setValidationErrors((state) => ({ ...state, [key]: newMessage }));
      return;
    }
    let isValid = true;
    if (!values?.looseItemsToAdd?.length) {
      setValidationErrors((state) => ({
        ...state,
        looseItemsToAdd: 'Loose Items field is required'
      }));
      isValid = false;
    }
    return isValid;
  };
  const formIsInvalid = () => Object.values(validationErrors).filter(Boolean).length;

  const handleChipDelete = (id) => {
    validationErrors?.disableButton && validateForm('disableButton', false);
    const newLooseItemIds = values.looseItemsToAdd.filter((value) => value !== id);
    setValues({ ...values, looseItemsToAdd: newLooseItemIds });
    key = new Date().getTime() + Math.random();
  };

  return (
    <section className={styles.popupWrapper}>
      {isDashboardScreen ? (
        <MainButton text="Move loose item" type="primary" action={() => setOpen(true)} />
      ) : (
        <Button
          className={clsx(styles.addButton, shouldAddButtonDisabled && styles.disabled)}
          onClick={() => setOpen(true)}
          disabled={shouldAddButtonDisabled}>
          <AddIcon />
        </Button>
      )}
      <Dialog
        open={open}
        classes={{ paper: styles.popupContainer }}
        BackdropProps={{ classes: { root: styles.backDrop } }}
        TransitionComponent={Transition}
        disableBackdropClick
        keepMounted
        onClose={onCancel}>
        <DialogTitle classes={{ root: styles.popupTitle }}>
          <div className={styles.headerTitle}>
            <h2>{isDashboardScreen ? 'Move Loose Items' : 'Add Loose Items'}</h2>
          </div>
        </DialogTitle>

        <DialogContent classes={{ root: styles.popupText }}>
          <div className={styles.formBlock}>
            <label>Loose Items*</label>
            <div className={styles.inputWrapper}>
              <InputSearchWithMultiselect
                name="looseItemsToAdd"
                defaultValue={values?.looseItemsToAdd || []}
                options={looseItems || []}
                onFilterSelect={onSelect}
                multiselect={true}
                refreshKey={key}
                isInvalid={!!validationErrors.looseItemsToAdd}
              />
              {!!validationErrors.looseItemsToAdd && (
                <span>{validationErrors.looseItemsToAdd}</span>
              )}
            </div>
          </div>
          <section className={styles.chipsBlock}>
            {!!values?.looseItemsToAdd?.length &&
              values.looseItemsToAdd.map((item) => (
                <Chip
                  classes={{ icon: styles.chipIcon }}
                  key={item}
                  label={looseItems?.find(({ id }) => id === item).name || ''}
                  deleteIcon={<CloseIcon />}
                  className={styles.fieldsChip}
                  onDelete={() => handleChipDelete(item)}
                />
              ))}
          </section>
          <div className={styles.formBlock}>
            <label>Destination</label>
            <div className={styles.inputWrapper}>
              <InputSearchWithMultiselect
                name="locationToAdd"
                defaultValue={values?.locationToAdd?.id || ''}
                options={locations || []}
                onFilterSelect={onSelect}
                multiselect={false}
                refreshKey={key}
              />
            </div>
          </div>
        </DialogContent>

        <DialogActions classes={{ root: styles.popupControls }}>
          <MainButton text="Cancel" action={onCancel} type="secondary" size="popup" />
          <MainButton
            text={isDashboardScreen ? 'Start moving' : 'Add'}
            action={onStart}
            type="primary"
            size="popup"
            isDisabled={!!formIsInvalid()}
          />
        </DialogActions>
      </Dialog>
    </section>
  );
}

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = {
  getShortedLooseItemsAction: getShortedLooseItems,
  getLocationsAction: getLocations,
  moveLooseItemsAction: moveLooseItems
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(MoveLooseItemsPopup);
