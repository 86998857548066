import React from 'react';
import styles from './RequestEntities.module.scss';

import { useRequestOrderSelector } from 'hooks/RequestOrder';
import { useFieldArray, useFormContext } from 'react-hook-form';

import FormSelectInput from 'components/FormComponents/FormSelectInput/FormSelectInput';

import { enqueueErrorSnackbar, enrichNameToId } from 'helpers/AppHelpers';

import AddEntityButton from '../Buttons/AddEntityButton';
import RemoveEntityButton from '../Buttons/RemoveEntityButton';

import RequestAssetEntities from './Assets';
import RequestLooseItemEntities from './LooseItems';
import RequestCustomEntities from './Custom';

import clsx from 'clsx';

import { ERROR_ENTITY_EXIST } from 'constants/infoSnackbarData';

export default function RequestOrderEntitiesDetails() {
  const { creationFormData } = useRequestOrderSelector();
  const { requestEntities } = creationFormData;

  const { control } = useFormContext();
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'requestEntityData'
  });

  const handleEntitySelect = (name, value, index) => {
    switch (value.displayName) {
      case 'Asset':
        update(index, { [name]: value, requestOrderAssetPrefixes: [] });
        break;
      case 'Loose Item':
        update(index, { [name]: value, requestOrderLooseItems: [] });
        break;
      case 'Others':
        update(index, { [name]: value, requestOrderCustomEntities: [] });
        break;
      default:
        break;
    }
  };

  const addEntity = () => append({ requestEntity: {} });
  const removeEntity = (i) => remove(i);

  const addCustomItem = (custom, index, element) => {
    const names = element.requestOrderCustomEntities.map((el) => el.entityName.toLowerCase());

    if (names.includes(custom.entityName.toLowerCase())) {
      enqueueErrorSnackbar(ERROR_ENTITY_EXIST);
      return;
    }

    update(index, {
      requestEntity: element.requestEntity,
      requestOrderCustomEntities: [...element.requestOrderCustomEntities, custom]
    });
  };

  return (
    <div className={styles.entities}>
      {fields.map((el, index) => (
        <div
          key={el.id}
          className={clsx(styles.entities__block, fields?.length >= 2 && styles.separate)}>
          <div className={styles.entities__block_row}>
            <label>Entity*</label>
            <FormSelectInput
              name={`requestEntityData[${index}].requestEntity`}
              options={enrichNameToId(requestEntities) || []}
              onSelect={(item) => handleEntitySelect('requestEntity', item.value, index)}
            />
          </div>

          {el?.requestEntity?.displayName === 'Asset' && <RequestAssetEntities index={index} />}

          {el?.requestEntity?.displayName === 'Loose Item' && (
            <RequestLooseItemEntities index={index} />
          )}

          {el?.requestEntity?.displayName === 'Others' && (
            <RequestCustomEntities
              onAdd={(custom, index) => addCustomItem(custom, index, el)}
              index={index}
            />
          )}

          {fields.length >= 2 && <RemoveEntityButton onClick={() => removeEntity(index)} />}
        </div>
      ))}
      <AddEntityButton
        onClick={addEntity}
        isDisabled={!fields.at(-1)?.requestEntity?.displayName}
      />
    </div>
  );
}
