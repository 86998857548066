import { combineReducers } from 'redux';
import authReducer from './authReducer';
import commonReducer from './commonReducer';
import dashboardReducer from './dashboardReducer';
import categoryReducer from './categoryReducer';
import prefixReducer from './prefixReducer';
import locationsReducer from './locationsReducer';
import projectReducer from './projectReducer';
import assetReducer from './assetReducer';
import reportListReducer from './reportListReducer';
import ticketsReducer from './ticketsReducer';
import userReducer from './userReducer';
import batchAssetUpdatesReducer from './batchAssetUpdatesReducer';
import looseItemDashboardReducer from './looseItemDashboardReducer';
import tentConfigurationReducer from './tentConfigurationReducer';
import assembleBOMReducer from './assembleBOMReducer';
import pickListReducer from './pickListReducer';
import leaveListReducer from './leaveListReducer';
import assetCalendarReducer from './assetCalendarReducer';
import teamMembersReducer from './teamMembersReducer';
import setEmploymentPopupReducer from './setEmploymentPopupReducer';
import teamMemberTrackerReducer from './teamMemberTrackerReducer';
import looseItemsAndPartsReducer from './looseItemsAndPartsReducer';
import movedLooseItemsReducer from './movedLooseItemsReducer';
import overviewReportReducer from './overviewReportReducer';
import requestOrderReducer from './requestOrderReducer';
import employmentStatusEditorReducer from './employmentStatusEditorReducer';
import addLooseItemsPopupReducer from './addLooseItemsPopupReducer';
import billOfLadingReducer from './billOfLadingReducer';
import teamMemberHistoryReducer from './teamMemberHistoryReducer';
import addBomToPicklistReducer from './addBomToPicklistReducer';
import batchItemUpdatesReducer from './batchItemUpdatesReducer';
import createBatchOfAssetsReducer from './createBatchOfAssetsReducer';
import searchReducer from './searchReducer';
import notificationCenterReducer from './notificationCenterReducer';
import userLogsReducer from './userLogsReducer';
import trainingsReducer from './trainingsReducer';
import ticketsDashboardReducer from './ticketsDashboardReducer';
import assetsDashboardsReducer from './assetsDashboardsReducer';
import managePicklistItemReducer from './managePicklistItemReducer';
import ticketTypesReducer from './ticketTypesReducer';
import ticketRootCausesReducer from './ticketRootCausesReducer';
import tmDashboardReducer from './tmDashboardReducer';
import billOfLadingLogsReducer from './billOfLadingLogsReducer';
import uploadBoLLogsReducer from './uploadBoLLogsReducer';

const rootReducer = combineReducers({
  authData: authReducer,
  commonData: commonReducer,
  dashboardData: dashboardReducer,
  assetData: assetReducer,
  categoryData: categoryReducer,
  prefixData: prefixReducer,
  locationsData: locationsReducer,
  projectData: projectReducer,
  reportListData: reportListReducer,
  ticketsData: ticketsReducer,
  userData: userReducer,
  batchAssetUpdatesData: batchAssetUpdatesReducer,
  looseItemDashboardData: looseItemDashboardReducer,
  tentConfigurationData: tentConfigurationReducer,
  assembleBOMData: assembleBOMReducer,
  pickListData: pickListReducer,
  leaveListData: leaveListReducer,
  assetCalendarData: assetCalendarReducer,
  teamMembersData: teamMembersReducer,
  setEmploymentPopupData: setEmploymentPopupReducer,
  teamMemberTrackerData: teamMemberTrackerReducer,
  looseItemsAndPartsData: looseItemsAndPartsReducer,
  movedLooseItemsData: movedLooseItemsReducer,
  overviewReportData: overviewReportReducer,
  requestOrderData: requestOrderReducer,
  employmentStatusesPopupData: employmentStatusEditorReducer,
  addLooseItemsPopupData: addLooseItemsPopupReducer,
  billOfLadingData: billOfLadingReducer,
  teamMemberHistoryData: teamMemberHistoryReducer,
  addBomToPicklistData: addBomToPicklistReducer,
  batchItemUpdatesData: batchItemUpdatesReducer,
  createBatchOfAssetsData: createBatchOfAssetsReducer,
  searchData: searchReducer,
  notificationCenterData: notificationCenterReducer,
  userLogsData: userLogsReducer,
  trainingsData: trainingsReducer,
  ticketsDashboardData: ticketsDashboardReducer,
  assetsDashboardsData: assetsDashboardsReducer,
  managePicklistItemData: managePicklistItemReducer,
  ticketTypesData: ticketTypesReducer,
  ticketRootCausesData: ticketRootCausesReducer,
  tmDashboardData: tmDashboardReducer,
  billOfLadingLogsData: billOfLadingLogsReducer,
  uploadBoLLogsData: uploadBoLLogsReducer
});

export default rootReducer;
