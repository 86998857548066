import {
  SET_TEAM_MEMBER_LIST,
  SET_TEAM_MEMBER_CREATION_FORM,
  CLEAR_TEAM_MEMBER_STATE,
  SET_CURRENT_TEAM_MEMBER,
  SET_TEAM_MEMBERS_FILTER,
  SET_TEAM_MEMBERS_PREFILLED_FILTER
} from 'constants/reduceConstants';
import { getClearedFilterByUnmount } from 'helpers/AppHelpers';

const defaultFilter = {
  filters: { searchQuery: '' },
  pagination: {
    page: 1,
    totalPages: 1,
    limit: 10
  },
  sortRules: [],
  responseReceived: false
};

const initialState = {
  teamMemberList: [],
  currentTeamMember: {},
  creationFormData: {},
  teamMemberFilter: { ...defaultFilter }
};

const teamMembersReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_TEAM_MEMBER_LIST:
      return { ...state, teamMemberList: payload };
    case SET_CURRENT_TEAM_MEMBER:
      return { ...state, currentTeamMember: payload };
    case SET_TEAM_MEMBER_CREATION_FORM:
      return { ...state, creationFormData: payload };
    case SET_TEAM_MEMBERS_FILTER:
      return { ...state, teamMemberFilter: payload };
    case SET_TEAM_MEMBERS_PREFILLED_FILTER:
      return {
        ...state,
        teamMemberFilter: {
          filters: payload,
          sortRules: [],
          responseReceived: false,
          pagination: defaultFilter.pagination
        }
      };
    case CLEAR_TEAM_MEMBER_STATE:
      return {
        ...initialState,
        teamMemberFilter: getClearedFilterByUnmount(state.teamMemberFilter, !!payload?.isAll)
      };
    default:
      return state;
  }
};

export default teamMembersReducer;
