import React, { useEffect, useRef, useState } from 'react';
import styles from './TeamList.module.scss';
import { MainButton, StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { selectTeamMemberFilter, selectTeamMembersData } from './selectors';
import {
  clearTeamMemberState,
  getTeamMembers,
  setTeamMemberListFilter
} from 'actions/teamMemberActions';
import {
  allKeyboardLatSymbols,
  isAllParameterEnabled,
  getPagesLimit,
  isAdminUser,
  isPersonnelManagerUser
} from 'helpers/AppHelpers';
import { selectUserConfig } from 'pages/commonSelectors';
import { Paper, Table, TableBody, TableContainer } from '@material-ui/core';
import { TEAM_MEMBER_DETAILED_PATH, TEAM_MEMBER_EDIT_PATH } from 'constants/routeConstants';
import { useHistory } from 'react-router-dom';
import { TEAM_MEMBERS_TABLE_PARAMETER } from 'constants/configTableConstants';
import SummaryHeader from 'components/SummaryComponents/SummaryHeader/SummaryHeader';
import SearchTextFieldRefactored from 'components/SearchTextFieldRefactored/SearchTextFieldRefactored';
import {
  getChangedByApplyFilter,
  getChangedByPageFilter,
  getChangedBySearchFilter,
  getChangedBySortFilter
} from 'helpers/SummaryPagesHelpers';
import { tableHeadConfig } from './tableConfig';
import TableHeadComponent from 'components/SummaryComponents/TableHeadComponent/TableHeadComponent';
import TablePaginationRefactored from 'components/TablePaginationRefactored/TablePaginationRefactored';
import LinkComponent from 'components/LinkComponent/LinkComponent';
import EmptyTableView from 'components/SummaryComponents/EmptyTableView/EmptyTableView';
import TeamMemberListFilter from './Filter/TeamMemberListFilter';
import DownloadCSVButton from 'components/DownloadCSVButton/DownloadCSVButton';
import { useMobileViewport } from 'hooks/useMobileViewport';

function TeamList({
  userConfig,
  teamMemberList,
  getTeamMemberListAction,
  filter,
  setFilterAction,
  clearStateAction
}) {
  const isMobile = useMobileViewport();
  const tableRef = useRef(null);
  const history = useHistory();
  const [tableState, setTableState] = useState([]);

  const updateTable = (changedFilter) => {
    setFilterAction(changedFilter);
    getTeamMemberListAction(changedFilter);
  };

  const { filters, pagination, sortRules, responseReceived } = filter;

  useEffect(() => {
    return () => {
      clearStateAction({ isAll: isAllParameterEnabled(userConfig, TEAM_MEMBERS_TABLE_PARAMETER) });
    };
  }, []);

  const getTeamMembers = (paginationChanged) => {
    getTeamMemberListAction({
      ...filter,
      pagination: {
        ...pagination,
        page: paginationChanged ? 1 : pagination.page,
        limit: getPagesLimit(userConfig, TEAM_MEMBERS_TABLE_PARAMETER, isMobile)
      },
      responseReceived: false
    });
  };

  const setTeamMembers = () => {
    if (
      teamMemberList.pageNumber === 1 ||
      !isAllParameterEnabled(userConfig, TEAM_MEMBERS_TABLE_PARAMETER)
    ) {
      setTableState(teamMemberList?.items);
    } else {
      setTableState(tableState.concat(teamMemberList?.items));
    }
    setFilterAction({
      ...filter,
      pagination: {
        limit: teamMemberList.limit,
        page: teamMemberList.pageNumber,
        totalPages: teamMemberList.totalPages
      },
      responseReceived: true
    });
  };

  useEffect(() => {
    if ('tables' in userConfig) {
      if (!teamMemberList.totalPages && !('items' in teamMemberList)) {
        getTeamMembers();
      } else {
        setTeamMembers();
      }
    }
  }, [userConfig, teamMemberList]);

  const emptyResponse = () =>
    !teamMemberList?.items?.length && pagination?.page === teamMemberList?.pageNumber;

  const emptyFilters = () => {
    return (
      !teamMemberList?.items?.length &&
      filters.searchQuery === '' &&
      !filters?.teamMemberIds?.length &&
      !filters?.teamMemberDepartmentIds?.length &&
      !filters?.clearanceTypeIds?.length &&
      !filters?.teamMemberLicenseIds?.length &&
      !filters?.teamMemberCapacityIds?.length &&
      !filters?.teamMemberStatusIds?.length &&
      !filters?.teamMemberSkillsetIds?.length &&
      !filters?.showDeleted &&
      pagination?.page === teamMemberList?.pageNumber
    );
  };

  const updateSearchParam = (value) => updateTable(getChangedBySearchFilter(filter, value));
  const onSortApply = (rowName) => updateTable(getChangedBySortFilter(filter, rowName));
  const onPageSelect = (page) => updateTable(getChangedByPageFilter(filter, page));
  const handleFilterApply = (values) => updateTable(getChangedByApplyFilter(filter, values));

  return (
    <section className={styles.pageContainer}>
      <div className={styles.headerWrapper}>
        <SummaryHeader title="Team List">
          {!isMobile &&
            (isAdminUser(userConfig.userRole) || isPersonnelManagerUser(userConfig.userRole)) && (
              <DownloadCSVButton
                filter={filter}
                endpoint="TeamMembers/Serialized"
                includePagination
                includeUserId
              />
            )}
          {(isAdminUser(userConfig.userRole) || isPersonnelManagerUser(userConfig.userRole)) && (
            <MainButton
              text="Create team member"
              type="primary"
              action={() => history.push(TEAM_MEMBER_EDIT_PATH)}
            />
          )}
        </SummaryHeader>
        <div className={styles.tableControls}>
          <TeamMemberListFilter isMobile={isMobile} onApply={handleFilterApply} />
          <SearchTextFieldRefactored
            inputValue={filters.searchQuery}
            validationRule={allKeyboardLatSymbols}
            updateSearchParam={updateSearchParam}
            rightSpace={isMobile}
          />
        </div>
      </div>
      <TableContainer ref={tableRef} className={styles.tableContainer} component={Paper}>
        <Table stickyHeader={!isMobile}>
          <TableHeadComponent
            config={tableHeadConfig}
            sortRules={sortRules}
            onSortApply={onSortApply}
          />
          <TableBody>
            {tableState?.length ? (
              tableState.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>
                    <LinkComponent
                      path={`${TEAM_MEMBER_DETAILED_PATH}/${row.id}`}
                      name={row.lastName + ', ' + row.firstName}
                      boldText={true}
                      isRedirectAvailable={
                        isAdminUser(userConfig.userRole) ||
                        isPersonnelManagerUser(userConfig.userRole)
                      }
                    />
                  </StyledTableCell>
                  <StyledTableCell>{row?.idNumber || ''}</StyledTableCell>
                  <StyledTableCell>
                    {row.departments.map(({ name }) => name).join(', ') || ''}
                  </StyledTableCell>
                  <StyledTableCell>
                    {row?.isSelectedAllReadOnlyDepartments
                      ? 'All Selected'
                      : row.readOnlyDepartments.map(({ name }) => name).join(', ') || ''}
                  </StyledTableCell>
                  <StyledTableCell>{row?.capacity?.name || ''}</StyledTableCell>
                  <StyledTableCell>{row.email || ''}</StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <EmptyTableView responseReceived={responseReceived} emptyFilters={emptyFilters} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePaginationRefactored
        isMobile={isMobile}
        rows={tableState?.length}
        currentPage={pagination.page}
        totalPages={pagination.totalPages}
        selectAllOption={true}
        onPageSelect={onPageSelect}
        pageLimit={pagination.limit}
        hide={emptyResponse()}
        tableListParameter={TEAM_MEMBERS_TABLE_PARAMETER}
        getTableData={() => getTeamMembers(true)}
        tableRef={tableRef}
        withOptionOneHundred
      />
    </section>
  );
}

const mapStateToProps = createStructuredSelector({
  userConfig: selectUserConfig(),
  teamMemberList: selectTeamMembersData(),
  filter: selectTeamMemberFilter()
});

const mapDispatchToProps = {
  getTeamMemberListAction: getTeamMembers,
  setFilterAction: setTeamMemberListFilter,
  clearStateAction: clearTeamMemberState
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(TeamList);
