import {
  CLEAR_LEAVE_LIST_STATE,
  SET_CURRENT_LEAVE_LIST,
  SET_LEAVE_LIST,
  SET_LEAVE_LIST_FILTER,
  SET_LOCATIONS_FOR_LEAVE_LIST,
  SET_LEAVE_LIST_STATUSES,
  SET_PROJECTS_FOR_LEAVE_LIST,
  SET_STATUSES_FOR_LEAVE_LIST,
  SET_TRUCK_OR_TRAILERS,
  SET_LOAD_MORE_FLAG,
  SET_BILL_OF_LADING_CREATION_FORM,
  RESET_LEAVE_LIST_FILTER
} from '../constants/reduceConstants';
import api from '../api';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from '../helpers/AppHelpers';
import {
  SUCCESS_CREATE_LEAVELIST,
  SUCCESS_DELETE_LEAVELIST,
  SUCCESS_UPDATE_LEAVELIST
} from '../constants/infoSnackbarData';
import { browserHistory } from '../browserHistory';
import {
  BILL_OF_LADING_EDIT_PATH,
  LEAVELIST_DETAILED_PATH,
  LEAVELIST_SUMMARY_PATH
} from '../constants/routeConstants';

export const getLeaveList = (query) => (dispatch) => {
  return api.leaveList
    .getLeaveListApi(query)
    .then((res) => {
      dispatch({ type: SET_LEAVE_LIST, payload: res });
      dispatch({ type: SET_LOAD_MORE_FLAG, payload: false });
      return res;
    })
    .catch((err) => console.log(err));
};

export const getLocations = (query) => (dispatch) => {
  api.leaveList
    .getLocationsApi(query)
    .then((res) => dispatch({ type: SET_LOCATIONS_FOR_LEAVE_LIST, payload: res }))
    .catch((err) => console.log(err));
};

export const getStatuses = (query) => (dispatch) => {
  api.leaveList
    .getStatusesApi(query)
    .then((res) => dispatch({ type: SET_STATUSES_FOR_LEAVE_LIST, payload: res }))
    .catch((err) => console.log(err));
};

export const getProjects = (query) => (dispatch) => {
  api.leaveList
    .getProjectsApi(query)
    .then((res) => dispatch({ type: SET_PROJECTS_FOR_LEAVE_LIST, payload: res }))
    .catch((err) => console.log(err));
};

export const getSingleLeaveList = (id) => (dispatch) => {
  api.leaveList
    .getSingleLeaveListApi(id)
    .then((res) => dispatch({ type: SET_CURRENT_LEAVE_LIST, payload: res }))
    .catch((err) => console.log(err));
};

export const checkBillOfLadingItems = (data) => (dispatch) => {
  return api.leaveList.checkBillOfLadingItemsApi(data).then(
    (res) => {
      if (res.status === 200) {
        dispatch({ type: SET_BILL_OF_LADING_CREATION_FORM, payload: res.data });
        browserHistory.push(BILL_OF_LADING_EDIT_PATH);
      }
      return res;
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );
};

export const createLeaveList = (data) => () => {
  return api.leaveList.createLeaveListApi(data).then(
    (res) => {
      enqueueSuccessSnackbar(SUCCESS_CREATE_LEAVELIST);
      browserHistory.push(`${LEAVELIST_DETAILED_PATH}/${res.id}`);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      return errors;
    }
  );
};

export const updateLeaveList = (data, isDetailsScreen, shouldShowPopup) => (dispatch) => {
  return api.leaveList.updateLeaveListApi(data).then(
    (res) => {
      shouldShowPopup && enqueueSuccessSnackbar(SUCCESS_UPDATE_LEAVELIST);
      !isDetailsScreen && browserHistory.push(`${LEAVELIST_DETAILED_PATH}/${res.id}`);
      dispatch({ type: SET_CURRENT_LEAVE_LIST, payload: res });
      return res;
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );
};

export const checkLeaveList = (data) => () => {
  return api.leaveList.checkLeaveListApi(data).then(
    (res) => res,
    (err) => {
      const errors = JSON.parse(err.request.response);
      return errors;
    }
  );
};

export const getAssets = (query) => () => {
  return api.leaveList
    .getAssetsApi(query)
    .then((res) => res)
    .catch((err) => console.log(err));
};

export const deleteLeaveList = (id) => () => {
  return api.leaveList.deleteLeaveListApi(id).then(
    () => {
      enqueueSuccessSnackbar(SUCCESS_DELETE_LEAVELIST);
      browserHistory.push(LEAVELIST_SUMMARY_PATH);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
    }
  );
};

export const getLeaveListAssetStatuses = (query) => (dispatch) => {
  api.leaveList
    .getAssetStatusesListApi(query)
    .then((res) => dispatch({ type: SET_LEAVE_LIST_STATUSES, payload: res }))
    .catch((err) => console.log(err));
};

export const searchTruckOrTrailer = (query) => (dispatch) => {
  api.asset
    .searchAssetBySrtingApi(query)
    .then((res) => dispatch({ type: SET_TRUCK_OR_TRAILERS, payload: res.data }))
    .catch((err) => console.log(err));
};

export const clearLeaveListState = () => (dispatch) => {
  dispatch({ type: CLEAR_LEAVE_LIST_STATE });
  dispatch({ type: SET_LOAD_MORE_FLAG, payload: false });
};

export const setLeaveListFilter = (payload) => (dispatch) => {
  dispatch({ type: SET_LEAVE_LIST_FILTER, payload });
};

export const resetLeaveListFilter = (payload) => (dispatch) => {
  dispatch({ type: RESET_LEAVE_LIST_FILTER, payload });
};
