import React from 'react';
import styles from './Buttons.module.scss';
import { Button } from '@material-ui/core';
import { LOOSE_ITEM_EDIT_PATH } from 'constants/routeConstants';
import { useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';

export default function AddItemButton() {
  const history = useHistory();

  const handleClick = () => history.push(LOOSE_ITEM_EDIT_PATH);

  return (
    <Button className={styles.add_button} onClick={handleClick}>
      <AddIcon /> Add item
    </Button>
  );
}
