import React, { useEffect } from 'react';
import styles from './BatchAssetUpdates.module.scss';

import LocationUpdate from './LocationUpdate/LocationUpdate';
import AssetsInspection from './AssetsInspection/AssetsInspection';
import LocationVerification from './LocationVerification/LocationVerification';
import AddAssetTickets from './AddAssetTickets/AddAssetTickets';
import AssetsStatusUpdate from './AssetsStatusUpdate/AssetsStatusUpdate';
import FieldsUpdate from './FieldsUpdate/FieldsUpdate';

import { useBatchAssetUpdatesActions, useBatchAssetUpdatesSelector } from 'hooks/BatchAssetUpdates';

import Header from 'components/DetailsComponents/Header';

import Tabs from './Tabs';

import {
  defaultAddAssetTicketsValues,
  defaultAssetInspectionValues,
  defaultFieldsUpdateValues,
  defaultLocationUpdateValues,
  defaultLocationVerificationValues,
  defaultStatusUpdateValues,
  defaultTab,
  isTabValueExist,
  tabs
} from './helpers';
import { useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';

export default function BatchAssetUpdates() {
  const history = useHistory();

  const methods = useForm({
    defaultValues: {
      [tabs.assetsInspection]: defaultAssetInspectionValues,
      [tabs.locationVerification]: defaultLocationVerificationValues,
      [tabs.assetsStatusUpdate]: defaultStatusUpdateValues,
      [tabs.locationUpdate]: defaultLocationUpdateValues,
      [tabs.fieldsUpdate]: defaultFieldsUpdateValues,
      [tabs.addAssetTicket]: defaultAddAssetTicketsValues
    },
    mode: 'onChange'
  });
  const { getValues, reset } = methods;

  const { selectedTab, formState } = useBatchAssetUpdatesSelector();
  const {
    setTabAction,
    getLocationsAction,
    getProjectsAction,
    getRentalStatusesAction,
    getSeveritiesAction,
    clearStateAction
  } = useBatchAssetUpdatesActions();

  const query = new URLSearchParams(window.location.search);
  const tabParam = query.get('tab');

  useEffect(() => {
    if (!isTabValueExist(tabParam)) {
      setTabAction(defaultTab);
      return;
    }

    setTabAction(tabParam);
    query.delete('tab');
    history.replace({
      search: query.toString()
    });
  }, []);

  useEffect(() => {
    getSeveritiesAction();
  }, []);

  useEffect(() => {
    getLocationsAction({ filters: { active: true, includeUserCheck: true } });
  }, []);

  useEffect(() => {
    getProjectsAction();
  }, []);

  useEffect(() => {
    getRentalStatusesAction();
  }, []);

  useEffect(() => {
    if (formState[defaultTab]?.assets?.length) {
      reset(formState);
    }
  }, []);

  useEffect(() => {
    return () => {
      clearStateAction(getValues());
    };
  }, []);

  const {
    locationUpdate,
    assetsInspection,
    addAssetTicket,
    locationVerification,
    assetsStatusUpdate,
    fieldsUpdate
  } = tabs;

  return (
    <FormProvider {...methods}>
      <div className={styles.wrapper}>
        <Header title="Assets Batch Updates" />
        <Tabs />

        {selectedTab === locationUpdate && <LocationUpdate />}

        {selectedTab === assetsInspection && <AssetsInspection />}

        {selectedTab === addAssetTicket && <AddAssetTickets />}

        {selectedTab === locationVerification && <LocationVerification />}

        {selectedTab === assetsStatusUpdate && <AssetsStatusUpdate />}

        {selectedTab === fieldsUpdate && <FieldsUpdate />}
      </div>
    </FormProvider>
  );
}
