import api from '../api';
import { queryToString } from 'helpers/AppHelpers';

export const getCreationFormApi = () =>
  api.get('/TeamMemberEmploymentStatus/CreationForm').then((res) => res.data);

export const checkClearanceApi = (query) =>
  api.get(`/TeamMembers/ClearanceCheck?${queryToString(query)}`).then((res) => res.data);

export const getEventApi = (id) =>
  api.get(`/TeamMemberEmploymentStatus?id=${id}`).then((res) => res.data);

export const createEventApi = (params) =>
  api.post('/TeamMemberEmploymentStatus', params).then((res) => res);

export const createMultipleEventsApi = (params) =>
  api.post('/TeamMemberEmploymentStatuses', params).then((res) => res);

export const updateEventApi = (params) =>
  api.put('/TeamMemberEmploymentStatus', params).then((res) => res);

export const deleteEventApi = (query) =>
  api.delete(`/TeamMemberEmploymentStatus?${queryToString(query)}`).then((res) => res);
