import React from 'react';
import styles from './FormCheckbox.module.scss';
import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox } from '@material-ui/core';

export default function FormCheckbox({ name, onSelectTriggered, reversed }) {
  const { control, setValue } = useFormContext();

  const handleClick = (value) => {
    setValue(name, !value);
    onSelectTriggered && onSelectTriggered(name, !value);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value } }) => (
        <Checkbox
          classes={{ root: styles.checkbox, checked: styles.checked }}
          onChange={() => handleClick(value)}
          checked={reversed ? !value : !!value}
        />
      )}
    />
  );
}
