import api from '../api';

import {
  enqueueErrorSnackbar,
  enqueueSuccessSnackbar,
  isSuccessfulStatus,
  joinWithComma,
  pluck
} from 'helpers/AppHelpers';

import {
  ERROR_UPDATE_ASSET_STATUS,
  SUCCESS_ADD_ASSET_TICKETS,
  SUCCESS_UPDATE_ASSETS_FIELDS,
  SUCCESS_UPDATE_ASSETS_STATUS,
  SUCCESS_UPDATE_ASSET_LOCATION,
  TRY_AGAIN_MESSAGE
} from 'constants/infoSnackbarData';

import {
  CLEAR_BATCH_ASSET_UPDATES_STATE,
  SET_BATCH_ASSET_UPDATES_TAB,
  SET_BATCH_ASSET_UPDATES_LOCATIONS,
  SET_BATCH_ASSET_UPDATES_PROJECTS,
  SET_BATCH_ASSET_UPDATES_RENTAL_STATUSES,
  SET_BATCH_ASSET_UPDATES_SEVERITIES
} from '../constants/reduceConstants';

export const getLocations = (query) => (dispatch) =>
  api.batchAssetUpdates
    .getLocationsApi(query)
    .then((res) => dispatch({ type: SET_BATCH_ASSET_UPDATES_LOCATIONS, payload: res?.items || [] }))
    .catch((err) => console.log(err));

export const getLocationsByAssets = (query) => () =>
  api.batchAssetUpdates
    .getLocationsApi(query)
    .then((res) => res)
    .catch((err) => console.log(err));

export const getProjects = (query) => (dispatch) =>
  api.batchAssetUpdates
    .getProjectsApi(query)
    .then((res) => dispatch({ type: SET_BATCH_ASSET_UPDATES_PROJECTS, payload: res }))
    .catch((err) => console.log(err));

export const getRentalStatuses = (query) => (dispatch) =>
  api.batchAssetUpdates
    .getRentalStatusesApi(query)
    .then((res) => dispatch({ type: SET_BATCH_ASSET_UPDATES_RENTAL_STATUSES, payload: res }))
    .catch((err) => console.log(err));

export const getSeverities = (query) => (dispatch) =>
  api.batchAssetUpdates
    .getSeveritiesApi(query)
    .then((res) => {
      dispatch({ type: SET_BATCH_ASSET_UPDATES_SEVERITIES, payload: res });
      return res;
    })
    .catch((err) => console.log(err));

export const getSublocations = (id) => () =>
  api.batchAssetUpdates
    .getSublocationsApi(id)
    .then((res) => res)
    .catch((err) => console.log(err));

export const getAssetFields = (q) => () =>
  api.batchAssetUpdates
    .getAssetFieldsApi(q)
    .then((res) => res)
    .catch((err) => console.log(err));

export const getTicketTypes = (query) => () =>
  api.batchAssetUpdates
    .getTicketTypesApi(query)
    .then((res) => res)
    .catch((err) => console.log(err));

export const getRootCauses = (query) => () =>
  api.batchAssetUpdates
    .getRootCausesApi(query)
    .then((res) => res)
    .catch((err) => console.log(err));

export const createTickets = (data) => () =>
  api.batchAssetUpdates
    .createTicketsApi(data)
    .then((res) => {
      enqueueSuccessSnackbar(SUCCESS_ADD_ASSET_TICKETS);
      return res;
    })
    .catch((err) => {
      const errors = err?.request?.response && JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    });

export const assignAssetsToLocation = (data) => () =>
  api.batchAssetUpdates
    .assignAssetsToLocationApi(data)
    .then((res) => {
      enqueueSuccessSnackbar(SUCCESS_UPDATE_ASSET_LOCATION + ' ' + data?.location?.siteCode || '');
      return res;
    })
    .catch((err) => {
      const errors = err?.request?.response && JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    });

export const updateAssetsStatus = (data) => () =>
  api.batchAssetUpdates
    .updateAssetsStatusApi(data)
    .then((res) => {
      const generatePicklistString = (assetsInPicklist) =>
        assetsInPicklist
          .map(
            ({ assets, picklist }) =>
              `Picklist ${picklist?.name || ''}: ${joinWithComma(pluck(assets, 'drCode'))}`
          )
          .join('\n');

      const showPicklistPopup = (assetsInPicklist) => {
        enqueueErrorSnackbar(
          `${ERROR_UPDATE_ASSET_STATUS}\n${generatePicklistString(assetsInPicklist)}`
        );
      };

      const showSuccessPopup = () => {
        enqueueSuccessSnackbar(
          SUCCESS_UPDATE_ASSETS_STATUS + ' ' + data?.rental?.rentalStatus?.displayName || ''
        );
      };

      const { assetsInPicklist } = res.data || {};

      if (assetsInPicklist?.length) {
        showPicklistPopup(assetsInPicklist);
      } else {
        showSuccessPopup();
      }

      return res;
    })
    .catch((err) => {
      const errors = err?.request?.response && JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    });

export const updateAssetFields = (data) => () =>
  api.batchAssetUpdates
    .updateFieldsApi(data)
    .then((res) => {
      if (isSuccessfulStatus(res.status)) {
        enqueueSuccessSnackbar(SUCCESS_UPDATE_ASSETS_FIELDS);
        return res;
      }
    })
    .catch((err) => {
      enqueueErrorSnackbar(TRY_AGAIN_MESSAGE);
      return err;
    });

export const setTab = (payload) => (dispatch) =>
  dispatch({ type: SET_BATCH_ASSET_UPDATES_TAB, payload });

export const clearState = (formValues) => (dispatch) =>
  dispatch({ type: CLEAR_BATCH_ASSET_UPDATES_STATE, payload: formValues });
