import { useDispatch } from 'react-redux';

import { setUnsavedFormData, setLoadMoreFlag } from 'actions/commonActions';

export function useCommonActions() {
  const dispatch = useDispatch();

  const setUnsavedFormDataAction = (flag) => dispatch(setUnsavedFormData(flag));

  const setLoadMoreFlagAction = (bool) => dispatch(setLoadMoreFlag(bool));

  return {
    setLoadMoreFlagAction,
    setUnsavedFormDataAction
  };
}
