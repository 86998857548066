import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  searchReportTemplateByLocationId,
  getLastSavedGeneralReport,
  setActiveTemplate,
  getLocationsAndProjects
} from 'actions/reportListActions';
import { selectReportDataFromSearch } from '../../selectors';
import DialogPopup from 'components/DialogPopup/DialogPopup';
import { Dialog, DialogActions, DialogTitle, DialogContent, FormControl } from '@material-ui/core';
import SelectInput from 'components/SelectInput/SelectInput';
import { MainButton } from 'components/StyledComponents/';
import styles from './AddLocationReportPopup.module.scss';
import { LOCATION_REPORT_EDIT_PATH } from 'constants/routeConstants';
import { EXIST_REPORT } from 'constants/dialogPopupsData';
import InputSearchWithMultiselect from 'components/InputSearchWithMultiselect/InputSearchWithMultiselect';
import PRJPlate from '../../commonComponents/PRJPlate';
import BackButton from '../../../../components/BackButton/BackButton';

function AddLocationReportPopup({
  isMobile,
  modalOpen,
  setModalOpen,
  getLocationsAndProjectsAction,
  reportDataFromSearch,
  searchReportTemplateByLocationIdAction,
  getLastSavedReportAction,
  setActiveLocationTemplateAction
}) {
  const history = useHistory();
  const [values, setValues] = useState({ templateLocation: {}, templateReport: {} });
  const [savedReportData, setSavedReportData] = useState({});
  const [dialogModalData, setDialogModalData] = useState({
    isOpened: false
  });

  const [locations, setLocations] = useState([]);

  const enrichIndexToId = (array) => {
    return (
      array?.map((item) => {
        return { ...item, id: item.index };
      }) || []
    );
  };

  useEffect(() => {
    getLocationsAndProjectsAction().then((res) =>
      setLocations(res?.length ? enrichIndexToId(res) : [])
    );
  }, []);

  const onSelect = (selectedItem) => {
    setValues({ ...values, templateReport: selectedItem.value });
  };

  const onInputSelect = (_, value) => {
    const { isProject, project, location } = value;
    const query = isProject ? { projectId: project.id } : { locationId: location.id };
    searchReportTemplateByLocationIdAction(query).then((res) => {
      if (res && res.length) {
        setValues({
          ...values,
          templateLocation: value,
          templateReport: res?.find(({ isDefault }) => isDefault) || {}
        });
      }
    });
  };

  const onAgree = () => {
    const query = { generalReportTemplateId: values.templateReport.id };

    const isProject = values.templateLocation.isProject;
    if (isProject) {
      query.projectId = values.templateLocation.project.id;
    } else {
      query.locationId = values.templateLocation.location.id;
    }

    getLastSavedReportAction(query).then((res) => {
      if (!!res && res?.['lastSavedTodayGeneralReport']?.id) {
        const { lastSavedTodayGeneralReport } = res;
        const userName = `${lastSavedTodayGeneralReport.createdByUser?.firstName || ''} ${
          lastSavedTodayGeneralReport.createdByUser?.lastName || ''
        }`;
        setDialogModalData({ ...EXIST_REPORT(userName), isOpened: true });
        setSavedReportData(res);
      } else {
        setActiveLocationTemplateAction({
          ...values,
          templateReport: res.generalReportTemplate
        });
        history.push(LOCATION_REPORT_EDIT_PATH);
      }
    });
  };

  const onDismiss = () => {
    setValues({ templateLocation: {}, templateReport: {} });
    setModalOpen(false);
  };

  const onModalAgree = () => {
    setDialogModalData({ isOpened: false });
    setModalOpen(false);
    setActiveLocationTemplateAction({
      ...values,
      templateReport: savedReportData.generalReportTemplate
    });
    history.push(LOCATION_REPORT_EDIT_PATH);
  };

  const onModalDismiss = () => {
    setDialogModalData({ isOpened: false });
    setModalOpen(false);
    history.push(`${LOCATION_REPORT_EDIT_PATH}/${savedReportData.lastSavedTodayGeneralReport.id}`);
  };

  return (
    <>
      <DialogPopup data={dialogModalData} onAgree={onModalAgree} onDissmiss={onModalDismiss} />
      <Dialog
        open={modalOpen}
        classes={{
          paper: styles.popupContainer
        }}
        BackdropProps={{
          classes: {
            root: styles.backDrop
          }
        }}
        disableBackdropClick
        keepMounted
        onClose={() => setModalOpen(false)}>
        <DialogTitle
          classes={{
            root: styles.popupTitle
          }}>
          <div className={styles.headerTitle}>
            <h2>Create report</h2>
            {isMobile && <BackButton onCancel={onDismiss} />}
          </div>
        </DialogTitle>

        <DialogContent
          classes={{
            root: styles.popupText
          }}>
          <FormControl classes={{ root: styles.formControl }}>
            <section className={styles.formCell}>
              <label>Location*</label>
              <div className={styles.inputWrapper}>
                <InputSearchWithMultiselect
                  name="templateLocation"
                  defaultValue={values?.templateLocation?.id || ''}
                  options={locations || []}
                  onFilterSelect={onInputSelect}
                  multiselect={false}
                  CustomSingleOptionComponent={(option, label) => (
                    <div className={styles.select_option}>
                      <span>{label}</span>
                      {option?.project?.id && <PRJPlate />}
                    </div>
                  )}
                />
              </div>
            </section>
            <section className={styles.formCell}>
              <label>Report name*</label>
              <div className={styles.inputWrapper}>
                <SelectInput
                  name="templateReport"
                  value={values.templateReport?.id || ''}
                  menuItems={reportDataFromSearch || []}
                  onSelect={onSelect}
                  disabled={
                    !(values.templateLocation?.project?.id || values.templateLocation?.location?.id)
                  }
                />
              </div>
            </section>
          </FormControl>
        </DialogContent>

        <DialogActions
          classes={{
            root: styles.popupControls
          }}>
          <section className={styles.buttonsBlock}>
            <MainButton text="Cancel" action={onDismiss} type="secondary" size="popup" />
            <MainButton
              text="Create"
              action={onAgree}
              type="primary"
              size="popup"
              isDisabled={!values.templateReport?.id}
            />
          </section>
        </DialogActions>
      </Dialog>
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  reportDataFromSearch: selectReportDataFromSearch()
});

const mapDispatchToProps = {
  getLocationsAndProjectsAction: getLocationsAndProjects,
  searchReportTemplateByLocationIdAction: searchReportTemplateByLocationId,
  setActiveLocationTemplateAction: setActiveTemplate,
  getLastSavedReportAction: getLastSavedGeneralReport
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(AddLocationReportPopup);
