import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { searchAssetByString } from 'actions/assetActions';
import {
  getLastSavedGeneralReport,
  searchReportTemplateByAssetId,
  setActiveTemplate
} from 'actions/reportListActions';
import { selectAssetsFromSearch, selectReportDataFromSearch } from '../../selectors';
import { getRandomId, allKeyboardLatSymbols } from 'helpers/AppHelpers';
import SearchInputWithTags from 'components/SearchInputWithTags/SearchInputWithTags';
import { Dialog, DialogActions, DialogTitle, DialogContent, FormControl } from '@material-ui/core';
import SelectInput from 'components/SelectInput/SelectInput';
import { MainButton } from 'components/StyledComponents/';
import styles from './AddAssetReportPopup.module.scss';
import { ASSET_REPORT_EDIT_PATH } from 'constants/routeConstants';
import BackButton from 'components/BackButton/BackButton';

function AddAssetReportPopup({
  isMobile,
  modalOpen,
  setModalOpen,
  assetsFromSearchData,
  searchAssetByStringAction,
  reportDataFromSearch,
  searchReportTemplateByAssetIdAction,
  setActiveAssetTemplateAction,
  getLastSavedReportAction
}) {
  const history = useHistory();
  const [values, setValues] = useState({ asset: {}, templateReport: {} });
  const [assetOptions, setAssetOptions] = useState([]);
  const [searchInputKey, setSearchInputKey] = useState(getRandomId());
  const [assetFilter, setAssetFilter] = useState({
    pagination: {
      limit: 40,
      page: 1
    },
    filters: { isDeleted: false, searchQuery: '' }
  });

  useEffect(() => {
    if (assetsFromSearchData?.items?.length) {
      setAssetOptions(assetsFromSearchData?.items);
    }
  }, [assetsFromSearchData]);

  useEffect(() => {
    if (reportDataFromSearch?.asset?.id) {
      setValues({
        ...values,
        asset: reportDataFromSearch.asset,
        templateReport:
          reportDataFromSearch.generalReportTemplates?.find(({ isDefault }) => isDefault) || {}
      });
      setAssetOptions(assetsFromSearchData?.items);
    }
  }, [reportDataFromSearch]);

  useEffect(() => {
    return () => {
      setAssetOptions([]);
    };
  }, []);

  const onAssetFilterChange = (searchQuery) => {
    const value = searchQuery ? searchQuery.replace(allKeyboardLatSymbols, '') : '';
    if (value !== '') {
      const currFilter = {
        ...assetFilter,
        filters: { ...assetFilter.filters, searchQuery, isDeleted: false, includeUserCheck: false }
      };
      setAssetFilter(currFilter);
      searchAssetByStringAction(currFilter);
    }
  };

  const onAssetsSelect = (value) => {
    setValues({ ...values, asset: value, templateReport: {} });
    if (value?.id) searchReportTemplateByAssetIdAction(value.id);
  };

  const onSelect = (selectedItem) => {
    setValues({ ...values, templateReport: selectedItem.value });
  };

  const onAgree = () => {
    const query = { generalReportTemplateId: values.templateReport.id, assetId: values.asset.id };

    getLastSavedReportAction(query).then((res) => {
      setActiveAssetTemplateAction({
        ...values,
        templateReport: res.generalReportTemplate
      });
      history.push(ASSET_REPORT_EDIT_PATH);
    });
  };

  const onDismiss = () => {
    setValues({ templateAsset: {}, templateReport: {} });
    setModalOpen(false);
    setSearchInputKey(getRandomId());
    setAssetOptions([]);
  };

  return (
    <>
      <Dialog
        open={modalOpen}
        classes={{
          paper: styles.popupContainer
        }}
        BackdropProps={{
          classes: {
            root: styles.backDrop
          }
        }}
        disableBackdropClick
        keepMounted
        onClose={() => setModalOpen(false)}>
        <DialogTitle
          classes={{
            root: styles.popupTitle
          }}>
          <div className={styles.headerTitle}>
            <h2>Create report</h2>
            {isMobile && <BackButton onCancel={onDismiss} />}
          </div>
        </DialogTitle>

        <DialogContent
          classes={{
            root: styles.popupText
          }}>
          <FormControl classes={{ root: styles.formControl }}>
            <section className={styles.formCell}>
              <label>Asset*</label>
              <div className={styles.inputWrapper}>
                <SearchInputWithTags
                  displayName="drCode"
                  defaultInputValue={values?.asset || {}}
                  options={assetOptions}
                  multiple={false}
                  onInputChange={onAssetFilterChange}
                  onSelect={onAssetsSelect}
                  shrinkedSize={true}
                  key={searchInputKey}
                />
              </div>
            </section>
            <section className={styles.formCell}>
              <label>Report name*</label>
              <div className={styles.inputWrapper}>
                <SelectInput
                  name="templateReport"
                  value={values.templateReport?.id || ''}
                  menuItems={reportDataFromSearch?.generalReportTemplates || []}
                  onSelect={onSelect}
                  disabled={!values?.asset?.id}
                />
              </div>
            </section>
          </FormControl>
        </DialogContent>

        <DialogActions
          classes={{
            root: styles.popupControls
          }}>
          <section className={styles.buttonsBlock}>
            <MainButton text="Cancel" action={onDismiss} type="secondary" size="popup" />
            <MainButton
              text="Create"
              action={onAgree}
              type="primary"
              size="popup"
              isDisabled={!values.templateReport?.id}
            />
          </section>
        </DialogActions>
      </Dialog>
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  assetsFromSearchData: selectAssetsFromSearch(),
  reportDataFromSearch: selectReportDataFromSearch()
});

const mapDispatchToProps = {
  searchReportTemplateByAssetIdAction: searchReportTemplateByAssetId,
  setActiveAssetTemplateAction: setActiveTemplate,
  searchAssetByStringAction: searchAssetByString,
  getLastSavedReportAction: getLastSavedGeneralReport
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(AddAssetReportPopup);
