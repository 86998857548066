import { formatDate, getUniqueID, getUserFullName } from 'helpers/AppHelpers';

export const generateChips = (options, filters) => {
  const filterHandlers = {
    endUserIds: handleFilterChips('endUsers', 'user', options),
    locationIds: handleFilterChips('locations', 'siteCode', options),
    looseItemIds: handleFilterChips('looseItems', 'name', options),
    prefixIds: handleFilterChips('assetPrefixes', 'prefix', options),

    entityNames: handleFilterNames('requestEntities', 'displayName', options),
    statusNames: handleFilterNames('statuses', 'displayName', options),

    isDeleted: handleBooleanFilter('Show deleted')
  };

  let newChips = [];

  Object.entries(filterHandlers).forEach(([key, handler]) => {
    const chips = handler(filters[key], key);
    newChips = newChips.concat(chips);
  });

  newChips = newChips.concat(generateDateChips(filters));

  return newChips.filter(Boolean);
};

const handleFilterChips = (dataKey, labelKey, filterCriteriaData) => (filterIds, key) => {
  const data = filterCriteriaData[dataKey];
  if (!data || !filterIds?.length) return [];

  return filterIds.flatMap((id) => {
    const item = data.find((option) => option.id === id);
    if (!item?.id && item?.id !== 0) return [];
    return {
      itemId: item.id,
      label: dataKey === 'endUsers' ? getUserFullName(item) : item[labelKey],
      chipId: getUniqueID(),
      key
    };
  });
};

const handleFilterNames = (dataKey, labelKey, filterCriteriaData) => (filterNames, key) => {
  const data = filterCriteriaData[dataKey];
  if (!data || !filterNames?.length) return [];

  return filterNames.flatMap((name) => {
    const item = data.find((option) => option.name === name);
    if (!item?.name) return [];
    return { itemName: item.name, label: item[labelKey], chipId: getUniqueID(), key };
  });
};

const handleBooleanFilter = (label) => (isActive, key) => {
  if (isActive) return [{ chipId: getUniqueID(), key, label }];
  return [];
};

const generateDateChips = ({ dateFrom, dateTo }) => {
  if (!dateFrom && !dateTo) return [];

  const isTwoDatesSelected = dateFrom && dateTo;

  return [
    {
      key: isTwoDatesSelected ? 'duration' : dateFrom ? 'dateFrom' : 'dateTo',
      label: isTwoDatesSelected
        ? formatDate(dateFrom) + ' - ' + formatDate(dateTo)
        : formatDate(dateFrom || dateTo),
      chipId: getUniqueID()
    }
  ];
};
